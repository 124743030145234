import PropTypes from 'prop-types'

import { clearClassNames } from '../../../helpers/strings'
import './button.scss'

export const colors = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary'
}

export const sizes = {
  regular: 'regular',
  large: 'large'
}

const basicCssClasses = {
  color: {
    primary: 'vf-btn__primary',
    secondary: 'vf-btn__secondary',
    tertiary: 'vf-btn__tertiary'
  }
}

const outlineCssClasses = {
  color: {
    primary: 'vf-btn__dark--outline',
    secondary: 'vf-btn__secondary--outline',
    tertiary: ''
  }
}

const VfButton = ({
  outlined,
  color,
  size,
  onClick,
  disabled,
  className,
  children,
  ...props
}) => {
  const cssClasses = outlined ? outlineCssClasses : basicCssClasses
  const allClassNames = clearClassNames(`vf-btn ${className}
  ${color === colors.primary ? cssClasses.color.primary : ''}
  ${color === colors.secondary ? cssClasses.color.secondary : ''}
  ${color === colors.tertiary ? cssClasses.color.tertiary : ''}
  ${size === sizes.large ? 'vf-btn--lg' : ''}`)

  return (
    <button
      type='button'
      className={allClassNames}
      onClick={onClick}
      disabled={disabled}
      {...props}>
      {children}
    </button>
  )
}

VfButton.propTypes = {
  outlined: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
}

VfButton.defaultProps = {
  outlined: false,
  onClick: () => {},
  disabled: false,
  color: colors.secondary,
  size: sizes.regular,
  className: ''
}

export default VfButton
