// @ts-check
import * as Yup from 'yup'
import { getFormattedOptions } from '../../CommonForm'

export const validationSchema = t =>
  Yup.object({
    name: Yup.string()
      .max(50, t('common.form.validation.maxLength', { count: 50 }))
      .required(t('issueTypes.form.validation.name.required')),
    customerTypeIds: Yup.array()
      .min(1, value =>
        t(
          `issueTypes.form.validation.customerTypeIds.minLength.${
            value.min === 1 ? 'one' : 'more'
          }`,
          { count: 1 }
        )
      )
      .required(t('issueTypes.form.validation.customerTypeIds.required'))
  })

/**
 * @typedef {import('../../../types').IssueTypeDto} IssueTypeDto
 * @typedef {import('./types').IssueTypeUI} IssueTypeUI
 * @typedef {import('./types').CreateIssueTypeDto} CreateIssueTypeDto
 */

/**
 * @param {IssueTypeDto} item - item from api to be formatted
 * @returns {IssueTypeUI} ui item
 */
export const formatItemForForm = item => ({
  id: item?.id,
  name: item?.name,
  customerTypeIds: getFormattedOptions(item?.customerTypes),
  active: item?.active
})

/**
 * @param {*} values
 * @returns {CreateIssueTypeDto} data for api
 */
export const formatValuesForApi = values => {
  const data = { ...values }

  data.customerTypeIds = values.customerTypeIds.map(item => item.value)

  return data
}
