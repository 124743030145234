import * as Yup from 'yup'

export const validationSchema = t =>
  Yup.object({
    name: Yup.string()
      .max(50, t('common.form.validation.maxLength', { count: 50 }))
      .required(t('contractTypes.form.validation.name.required')),
    active: Yup.boolean(),
    defaultEntity: Yup.boolean()
  })

export const formatValuesToInactivate = values => ({
  ...values,
  active: false
})

export const formatItemForForm = item => ({
  id: item?.id,
  name: item?.name,
  active: item?.active,
  defaultEntity: item?.defaultEntity
})
