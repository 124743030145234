import React from 'react'

import './two-columns-template.scss'

export const TwoColumnsTemplate = ({
  children,
  className = '',
  style = {}
}) => (
  <div className={`two-columns-template ${className}`} style={style}>
    {children}
  </div>
)

export const MainColumnTemplate = ({
  children,
  className = '',
  style = {}
}) => (
  <div className={`main-column-template ${className}`} style={style}>
    {children}
  </div>
)

export const MainColumnHeader = ({
  title,
  info,
  ctas,
  className = '',
  style = {}
}) => (
  <div className={`main-column-template__header ${className}`} style={style}>
    <div className='main-column-template__header__title'>
      <h1>{title}</h1>
      <small>{info}</small>
    </div>

    <div className='main-column-template__header__ctas'>{ctas}</div>
  </div>
)

export const MainColumnContent = ({ children, className = '', style = {} }) => (
  <div className={`main-column-template__content ${className}`} style={style}>
    {children}
  </div>
)

export const MainColumnContentItem = ({
  label,
  value,
  className = '',
  style = {}
}) => (
  <div
    className={`main-column-template__content__item ${className}`}
    style={style}>
    <div className='main-column-template__content__item__label'>{label}</div>

    <div className='main-column-template__content__item__value'>{value}</div>
  </div>
)

export const SidebarColumnTemplate = ({
  children,
  className = '',
  style = {}
}) => (
  <div className={`sidebar-column-template ${className}`} style={style}>
    {children}
  </div>
)

export const SidebarColumnSection = ({
  children,
  className = '',
  style = {}
}) => (
  <div
    className={`sidebar-column-template__section ${className}`}
    style={style}>
    {children}
  </div>
)

export const SidebarColumnSectionItem = ({
  label,
  value,
  className = '',
  style = {}
}) => (
  <div
    className={`sidebar-column-template__section__item ${className}`}
    style={style}>
    <div className='sidebar-column-template__section__item__label'>{label}</div>

    <div className='sidebar-column-template__section__item__value'>{value}</div>
  </div>
)
