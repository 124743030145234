// @ts-check

/**
 * @typedef {import("../../types").IssueTypeDto} IssueTypeDto
 * @typedef {import("../../types").CustomerTypeDto} CustomerTypeDto
 * @typedef {import("../../types").ContractTypeDto} ContractTypeDto
 */

/**
 * @param {IssueTypeDto | CustomerTypeDto | ContractTypeDto} item
 * @returns {boolean}
 */
export const isItemActive = ({ active }) => active

/**
 * @template {ContractTypeDto | CustomerTypeDto | IssueTypeDto} T
 * @param {T[]} items
 * @returns {T[]} Only active items
 */
export const getActiveItems = (items = []) => items.filter(isItemActive)

/**
 * @param {IssueTypeDto[]} items
 * @returns {IssueTypeDto[]} Only inactive items
 */
export const getInactiveItems = (items = []) =>
  items.filter(item => !isItemActive(item))

export const compareNames = (a, b) => {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()

  return nameA.localeCompare(nameB)
}
