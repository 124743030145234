import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getUrl } from '../../../apiUrls'
import { useToastContext, usePagination, useData } from '../../../hooks'
import { apiPostWithToken } from '../../../api'
import { callback, handleSubmitFactory } from '../../../helpers/forms'
import ActionButton, { colors } from '../../Common/ActionButton'
import { BasicTextAreaWithLabel as TextArea } from '../../CommonForm/TextArea/TextArea'
import { formatCommentForApi } from '../Form/helpers'
import { getFormattedDateWithTime } from '../../../helpers/dates'
import Pagination, {
  paginationPositions
} from '../../Common/Pagination/PaginationWithNumbers'
import getNotification from '../../../helpers/apiNotifications'
import Loader from '../../Common/Loader/Loader'
import { getCreateDate } from './helpers'

import './comments.scss'

const CommentItem = ({ item }) => {
  const { audit, sourceSystem, content } = item
  const createDate = getCreateDate(item)

  return (
    <div className='comments__item'>
      <div className='comments__item__header'>
        <div className='comments__item__header__author'>
          {audit?.creatorFullName}
        </div>
        <div className='comments__item__header__date'>
          {getFormattedDateWithTime(createDate, 'sv-SE')}
        </div>
        <div className='comments__item__header__source'>{sourceSystem}</div>
      </div>

      <div className='comments__item__content'>{content}</div>
    </div>
  )
}

const Comments = ({
  issue,
  canAddNewComment = false,
  refreshItem = () => {}
}) => {
  const { t } = useTranslation()
  const { addToast } = useToastContext()

  const [comment, setComment] = useState('')
  const [inProgress, setInProgress] = useState(false)

  const url = getUrl.commentsByIssueId(issue.id)
  const { loading, items, error } = useData(url)

  const successCallback = () => {
    setComment('')
    setInProgress(false)
    handlePageNumberClick(1)
    refreshItem()
  }

  const handleSubmit = () => {
    const createUrl = getUrl.commentCreate()

    const values = {
      ...formatCommentForApi(comment, issue.id)
    }

    setInProgress(true)

    handleSubmitFactory(
      createUrl,
      apiPostWithToken,
      callback(addToast, successCallback, () => setInProgress(false))
    )(values)
  }

  const sortedComments = !!items
    ? [...items].sort(
        (a, b) => new Date(b.audit.createdDate) - new Date(a.audit.createdDate)
      )
    : []

  const {
    visibleItems: visibleComments,
    totalPages,
    currentPage,
    handlePrevClick,
    handleNextClick,
    handlePageNumberClick
  } = usePagination(sortedComments, 5)

  const Info = getNotification(loading, error, items, Loader)

  return (
    <div className='comments'>
      {!!Info ? (
        Info
      ) : (
        <div>
          {visibleComments?.map((comment, idx) => (
            <CommentItem key={idx} item={comment} />
          ))}

          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            isFirst={currentPage === 1}
            isLast={currentPage === totalPages}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
            handlePageNumberClick={handlePageNumberClick}
            paginationPosition={paginationPositions.right}
          />
        </div>
      )}

      {canAddNewComment && (
        <div className='comments__form'>
          <TextArea
            value={comment}
            onChange={e => setComment(e.target.value)}
            label={t('common.labels.newComment')}
            rows={3}
            maxLength={250}
          />

          <div className='comments__form__button'>
            <ActionButton
              disabled={!comment.length || inProgress}
              color={colors.blue}
              onClick={handleSubmit}>
              {!inProgress ? t('buttons.send') : t('buttons.sending')}
            </ActionButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default Comments
