import { buttonSizes } from './helpers'
import ActionButton, { ActionButtonWithLink } from '../ActionButton'
import { VfButton, VfLinkButton, sizes } from '../../DesignSystem/Buttons'

import './button-template.scss'

const ICON_WIDTH = 14

const BtnSmActive = ({ icon: Icon, iconWidth = ICON_WIDTH }) => (
  <Icon width={iconWidth} />
)
const BtnSmInactive = ({ icon: Icon, iconWidth = ICON_WIDTH }) => (
  <Icon width={iconWidth} fill='#ccc' />
)

const BtnMdActive = ({ icon: Icon, iconWidth = ICON_WIDTH, text }) => (
  <>
    <Icon width={iconWidth} />
    <span>{text}</span>
  </>
)
const BtnMdInactive = ({ icon: Icon, iconWidth = ICON_WIDTH, text }) => (
  <>
    <Icon width={iconWidth} fill='#ccc' />{' '}
    <span style={{ color: '#ccc' }}>{text}</span>
  </>
)

const BtnLgActive = ({ icon: Icon, iconWidth = ICON_WIDTH, text }) => (
  <>
    <Icon width={iconWidth} /> {text}
  </>
)
const BtnLgInactive = ({ icon: Icon, iconWidth = ICON_WIDTH, text }) => (
  <>
    <Icon width={iconWidth} /> {text}
  </>
)

export const BtnActive = ({ size, icon, iconWidth, text }) => {
  switch (size) {
    case buttonSizes.small:
      return <BtnSmActive icon={icon} iconWidth={iconWidth} />

    case buttonSizes.medium:
      return <BtnMdActive icon={icon} iconWidth={iconWidth} text={text} />

    case buttonSizes.large:
      return <BtnLgActive icon={icon} iconWidth={iconWidth} text={text} />

    default:
      return null
  }
}

export const BtnInactive = ({ size, icon, iconWidth, text }) => {
  switch (size) {
    case buttonSizes.small:
      return <BtnSmInactive icon={icon} iconWidth={iconWidth} />

    case buttonSizes.medium:
      return <BtnMdInactive icon={icon} iconWidth={iconWidth} text={text} />

    case buttonSizes.large:
      return <BtnLgInactive icon={icon} iconWidth={iconWidth} text={text} />
    default:
      return null
  }
}

export const BtnComponent = ({ children, ...props }) => {
  const { size, path } = props

  switch (size) {
    case buttonSizes.small:
    case buttonSizes.medium:
      return !!path ? (
        <ActionButtonWithLink {...props}>{children}</ActionButtonWithLink>
      ) : (
        <ActionButton {...props}>{children}</ActionButton>
      )
    case buttonSizes.large:
      return !!path ? (
        <VfLinkButton
          className='vf-btn--with-icon'
          {...props}
          size={sizes.regular} // after {...props} to overwrite props.size
        >
          {children}
        </VfLinkButton>
      ) : (
        <VfButton className='vf-btn--with-icon' {...props} size={sizes.regular}>
          {children}
        </VfButton>
      )
    default:
      return null
  }
}

const ButtonTemplate = ({
  active = false,
  size = buttonSizes.small,
  handleClick = () => {},
  text = '',
  icon: Icon,
  ...props
}) => {
  const title = size === buttonSizes.small ? text : null

  if (!active) {
    return (
      <BtnComponent size={size} title={title} disabled {...props}>
        <BtnInactive size={size} icon={Icon} text={text} />
      </BtnComponent>
    )
  }

  return (
    <BtnComponent size={size} title={title} onClick={handleClick} {...props}>
      <BtnActive size={size} icon={Icon} text={text} />
    </BtnComponent>
  )
}

export default ButtonTemplate
