import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

import './page-not-found.scss'

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <div className='page-not-found__container'>
      <h2>Page not found</h2>
      <h1>404</h1>
      <p>
        The page you are trying to reach is temporarily unavailable <br /> or
        may no longer exists.
      </p>
      <div>
        <button
          type='button'
          onClick={() => navigate(-1)}
          className='vf-link-with-arrow mr-4'>
          Go back
        </button>
        <Link to='/' className='vf-link-with-arrow'>
          Home
        </Link>
      </div>
    </div>
  )
}

export default PageNotFound
