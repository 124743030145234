import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { loginRequest, msalInstance } from '../auth/authConfig'

/**
 * @returns {Promise<string>} accessToken
 */
export const getToken = async () => {
  const account = msalInstance.getActiveAccount()

  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  // When there is no valid refresh token, acquireTokenSilent throws InteractionRequiredAuthError.
  // So the user must sign in again
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account
    })

    const { accessToken } = response

    return accessToken
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      msalInstance.loginRedirect(account)
    }
  }
}
