import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

export const LNG_STORAGE_KEY = 'appLanguage'
export const supportedLngs = ['en', 'sv']

const lng = window.Cypress
  ? 'en'
  : localStorage.getItem(LNG_STORAGE_KEY) || 'sv'

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng,
    fallbackLng: process.env.NODE_ENV === 'development' ? false : 'sv',
    supportedLngs,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    },
    pluralSeparator: '_'
  })

export default i18n
