import { useTranslation } from 'react-i18next'

import Badge from '../../Common/Badge/Badge'
import { getStatusLabel, getStatusColor } from '../helpers'
import { getPriority } from './helpers'

import './issue-badges.scss'

export const StatusBadge = ({ status }) => {
  const { t } = useTranslation()

  return (
    <Badge
      className='issue-badge issue-badge--status'
      style={{ backgroundColor: getStatusColor(status) }}>
      {getStatusLabel(status, t)}
    </Badge>
  )
}

export const PriorityBadge = ({ priority: priorityFromApi }) => {
  const priority = getPriority(priorityFromApi)

  if (!priority) {
    return (
      <Badge className='issue-badge issue-badge--priority'>
        {priorityFromApi}
      </Badge>
    )
  }

  const Icon = priority.icon

  return (
    <Badge
      className='issue-badge issue-badge--priority'
      style={{
        color: priority.color,
        borderColor: priority.color
      }}>
      <Icon height={14} fill={priority.color} /> {priority.label}
    </Badge>
  )
}
