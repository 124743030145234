import React from 'react'
import { useTranslation } from 'react-i18next'
import { useField, useFormikContext } from 'formik'
import Select, { components } from 'react-select'
import { reactSelectStyles, reactSelectSizes } from './ReactSelectStyles'
import Message, { messageTypes } from '../../Common/Message'

export const ReactSelect = ({
  value: selectedValue = '',
  name,
  isClearable = false,
  options = [],
  label = '',
  disabled = false,
  readOnly = false,
  hasError = false,
  fullWidth = true,
  loading = false,
  size = reactSelectSizes.regular,
  ...props
}) => {
  const { t } = useTranslation()

  const getValue = (options, selectedValue) => {
    if (!!options.length) {
      return options.find(
        item => item.value.toString() === selectedValue.toString()
      )
    } else {
      return ''
    }
  }

  return (
    <div data-test='select'>
      {!!label && <label className='mt-2'>{label}</label>}

      <Select
        key={selectedValue} // AD: do not remove, the key is needed to force rerender of component,
        // in case value changes back to empty string '' the select was holding old value
        {...props}
        options={options}
        name={name}
        id={name} // for tests
        styles={reactSelectStyles}
        value={getValue(options, selectedValue)}
        placeholder={
          !options.length && loading
            ? t('common.labels.loading')
            : readOnly
            ? null
            : t('common.labels.selectOption')
        }
        isOptionDisabled={option => option.disabled}
        menuIsOpen={readOnly ? false : undefined}
        isSearchable={!readOnly}
        className={size}
        readOnly={readOnly}
        hasError={hasError}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement='auto'
        components={{
          DropdownIndicator: props =>
            readOnly ? null : <components.DropdownIndicator {...props} />,
          IndicatorsContainer: props =>
            readOnly ? null : <components.IndicatorsContainer {...props} />
        }}
      />
    </div>
  )
}

const ReactSelectWithFormik = ({ onChange = () => {}, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers
  const { name } = field
  const { error, touched } = meta

  const { validateField } = useFormikContext()

  const validateThisField = () => {
    validateField(name)
  }

  const handleChange = item => {
    const val = item !== null ? item.value : ''
    setValue(val)
    onChange(val)
  }

  const hasError = touched && !!error

  return (
    <div>
      <ReactSelect
        {...field}
        {...props}
        hasError={hasError}
        onChange={handleChange}
        onBlur={validateThisField}
      />

      {hasError && <Message type={messageTypes.error}>{error}</Message>}
    </div>
  )
}

export default ReactSelectWithFormik
