import { useField } from 'formik'

import './switch.scss'

export const Switch = ({
  name = '',
  value = '',
  label = '',
  readOnly = false,
  ...props
}) => (
  <div className='switch-container'>
    <label
      className='switch'
      style={{ cursor: readOnly ? 'default' : 'pointer' }}>
      <input
        id={name}
        type='checkbox'
        checked={value}
        disabled={readOnly}
        {...props}
      />
      <span className='slider'></span>
      <span className='label'>{label}</span>
    </label>
  </div>
)

const SwitchWithFormik = ({ label, ...props }) => {
  const [field] = useField(props)

  return <Switch label={label} {...field} />
}

export default SwitchWithFormik
