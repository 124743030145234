// @ts-check
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { getUrl } from '../../apiUrls'
import { useData, useSortableData } from '../../hooks'
import ActionButton from '../Common/ActionButton'
import Loader from '../Common/Loader'
import List from '../Layout'
import { Error } from '../../helpers/apiNotifications'
import { defaultSortParam, initialSortConfig } from './helpers'
import { getCreateDate } from './Details/helpers'

/**
 * @typedef {import('../../types').DtoPagingWrapperIssueDto} DtoPagingWrapperIssueDto
 * @typedef {import('./types').IssueUI} IssueUI
 */

const formatProperty = (item, sortBy) => {
  const { audit } = item
  switch (sortBy) {
    case 'title':
    case sortBy.match(/.name/)?.input: // eg. priority.name, customer.name
      return get(item, sortBy).toUpperCase()
    case 'createdDate':
      return new Date(get(item, sortBy))
    case 'modifiedDate':
      return new Date(get(audit, sortBy))
    default:
      return get(item, sortBy)
  }
}

const GroupedList = ({
  group: { name, value, translateName = false },
  apiParams = {},
  listConfig,
  displayLoading = false,
  ctas: ItemCtas,
  handleMoreClick = value => {},
  onDataLoaded = (nm, ln) => {}
}) => {
  const { t } = useTranslation()
  const url = getUrl.issues(null, { ...apiParams, page: 0 }) + defaultSortParam
  /** @type {{loading: boolean, items: DtoPagingWrapperIssueDto, error: any }} */
  const { loading, items: data, error } = useData(url)

  useEffect(() => {
    if (!!data?.result) {
      onDataLoaded(name, data.result.length)
    }
  }, [data?.result, onDataLoaded, name])

  /** @type IssueUI[] */
  const uiData = (data?.result || []).map(item => ({
    ...item,
    // BE need this for sort, but is not sending it (related to #49835)
    unifiedCreateDate: getCreateDate(item)
  }))

  // used a separate sorting functionality to avoid reloading all grouped lists
  const {
    items: sortedItems,
    handleSort,
    sortConfig
  } = useSortableData(uiData, initialSortConfig, formatProperty)

  const totalPages = data?.totalPages ?? 0
  const groupLabel = translateName ? t(name) : name

  if (loading && displayLoading) {
    return <Loader />
  }

  if (!!data?.result.length) {
    return (
      <div className='mt-3'>
        <List
          items={sortedItems}
          listTitle={`${groupLabel} (${data.totalItems})`}
          listConfig={listConfig}
          ctas={ItemCtas}
          sortConfig={sortConfig}
          handleSort={handleSort}
        />

        {totalPages > 1 && (
          <ActionButton
            onClick={() => handleMoreClick(value)}
            className='mt-1'
            data-test='view-more-btn'>
            {t('buttons.more')}
          </ActionButton>
        )}
      </div>
    )
  }

  if (error) {
    return (
      <>
        <h4 className='vf-intro-in-caps'>{groupLabel}</h4>
        <Error>{t('messages.error')}</Error>
      </>
    )
  }
}

export default GroupedList
