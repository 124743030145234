const translationKeys = {
  'Please remove illegal file type': 'common.form.validation.fileInvalidType'
}

/**
 * When BE sends a message (e.g. error message in api response), it is in English.
 * Because the response object does not contain unique identifiers, translation key needs to be taken
 * from the message itself.
 * @param {string} message - message from BE
 * @param {Function} t - i18n translation function
 * @returns {string} - translated message
 */
export const getTranslatedMessage = (message, t) => {
  const tKey = translationKeys[message]

  return !!tKey ? t(tKey) : message
}
