import { useTranslation } from 'react-i18next'

import { BlockBtn, buttonSizes } from '../../Common/Ctas'
import {
  useModalContext,
  useSlidePanelContext,
  useToastContext
} from '../../../hooks'
import { getUrl } from '../../../apiUrls'
import { apiPutWithToken } from '../../../api'
import { callback } from '../../../helpers/forms'
import { getUserFullName } from '../../../helpers/strings'

const BlockUserBtn = ({
  item,
  refreshList = () => {},
  size = buttonSizes.small
}) => {
  const { t } = useTranslation()
  const openModal = useModalContext()
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()

  const updateUrl = getUrl.userUpdate()

  const successCallback = () => {
    refreshList()
    closeSlidePanel()
  }

  const handleBlock = () => {
    const updatedItem = {
      ...item,
      accountLocked: !item.accountLocked
    }

    apiPutWithToken(updateUrl, updatedItem, callback(addToast, successCallback))
  }

  const modalTitle = item.accountLocked
    ? t('users.form.titles.unblock', { name: getUserFullName(item) })
    : t('users.form.titles.block', { name: getUserFullName(item) })

  return (
    <BlockBtn
      active
      size={size}
      locked={item.accountLocked}
      onClick={() =>
        openModal({
          title: modalTitle,
          onConfirm: handleBlock
        })
      }
    />
  )
}

export default BlockUserBtn
