// @ts-check
import React from 'react'
import { MultiSelect, getFormattedOptions } from '../CommonForm'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import { getActiveItems } from '../ContractTypes/helpers'
import { sortByParam } from '../../helpers/lists'

/** @typedef {import('../../types').ContractTypeDto} ContractTypeDto */

const ContractTypesPicker = ({
  name = 'contractTypeIds',
  label,
  readOnly = false,
  disabled = false
}) => {
  const contractTypesUrl = getUrl.contractTypes()
  const { items, loading } = useData(contractTypesUrl)

  if (!items) {
    return null
  }

  /** @type {ContractTypeDto[]} */
  const itemsActive = getActiveItems(items)
  const itemsSorted = sortByParam(itemsActive, 'asc', 'name')
  const options = getFormattedOptions(itemsSorted)

  return (
    <MultiSelect
      name={name}
      label={label}
      options={options}
      readOnly={readOnly}
      disabled={disabled}
      loading={loading}
    />
  )
}

export default ContractTypesPicker
