import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import GroupedList from './GroupedList'
import { Warning } from '../../helpers/apiNotifications'
import { groupByConfig, groupSourceTypes } from './helpers'
import { useData } from '../../hooks'

const GroupedLists = ({
  groupBy = 'status',
  apiParams,
  listConfig,
  ctas: ItemCtas,
  ...props
}) => {
  const { t } = useTranslation()

  // need to keep groupBy value in local state, otherwise children lists are making api calls with wrong data
  const [localGroupBy, setLocalGroupBy] = useState(groupBy)

  // to display "no data" if each group is empty,
  // we need to keep track of the total number of items
  const [groupsTotalItems, setGroupsTotalItems] = useState({})

  const [items, setItems] = useState([])
  const [url, setUrl] = useState(null)
  const { items: data } = useData(url)

  useEffect(() => {
    // reset values so that the group lists don't have old data
    setItems([])
    setLocalGroupBy(groupBy)
  }, [groupBy])

  const needsApiCall = groupByConfig[groupBy]?.source === groupSourceTypes.api

  useEffect(() => {
    if (needsApiCall) {
      setUrl(groupByConfig[groupBy].url)
    } else {
      setUrl(null)
    }
  }, [groupBy, needsApiCall])

  useEffect(() => {
    if (needsApiCall) {
      const groups =
        data?.map(item => ({ name: item.name, value: item.id })) ?? []

      setItems(groups)
    } else {
      setItems(groupByConfig[groupBy]?.data || [])
    }
  }, [data, groupBy, needsApiCall])

  useEffect(() => {
    // reset groups object on params change so 'no data' message is removed
    setGroupsTotalItems({})
  }, [localGroupBy, apiParams])

  const onDataLoaded = useCallback(
    // useCallback to prevent an infinite loop
    (status, numOfItems) =>
      setGroupsTotalItems(prev => ({ ...prev, [status]: numOfItems })),
    []
  )

  const totalItems = Object.values(groupsTotalItems).reduce(
    (total, next) => total + next,
    0
  )

  const allGroupsLoaded =
    items?.length > 0 && Object.keys(groupsTotalItems).length === items.length
  const groupsEmpty = allGroupsLoaded && totalItems === 0

  return (
    <>
      {items?.map((item, idx) => (
        <GroupedList
          key={item.value}
          group={item}
          apiParams={{ ...apiParams, [localGroupBy]: item.value }}
          listConfig={listConfig}
          displayLoading={idx === 0} // Loader for the first group only, can't use allGroupsLoaded as it may give an invalid loading state
          ctas={ItemCtas}
          onDataLoaded={onDataLoaded}
          {...props}
        />
      ))}

      {groupsEmpty && <Warning>{t('messages.noDataWithFilters')}</Warning>}
    </>
  )
}

export default GroupedLists
