import { useCallback, useState } from 'react'

const useAPIQueryParams = (
  initialParams = {},
  itemsPerPage = 20,
  formatParamsFn
) => {
  const [params, setParams] = useState({
    page: initialParams?.page ?? 0,
    filters: initialParams?.filters ?? {},
    sortConfig: initialParams?.sortConfig ?? null
  })

  const getParamsForApiCall = useCallback(() => {
    const { page, filters, sortConfig } = params

    let paramsForApiCall = { page, size: itemsPerPage }

    if (!!sortConfig) {
      paramsForApiCall.sort =
        sortConfig.sortBy + ',' + sortConfig.order.toUpperCase()
    }

    Object.keys(filters).forEach(key => {
      paramsForApiCall[key] = filters[key]
    })

    if (typeof formatParamsFn === 'function') {
      Object.keys(paramsForApiCall).forEach(
        key =>
          (paramsForApiCall[key] = formatParamsFn(key, paramsForApiCall[key]))
      )
    }

    return paramsForApiCall
  }, [params, itemsPerPage, formatParamsFn])

  const paramsForApiCall = getParamsForApiCall()

  const handleSort = property => {
    const { sortConfig } = params

    const isAsc =
      !!sortConfig &&
      sortConfig.sortBy === property &&
      sortConfig.order === 'asc'

    const order = isAsc ? 'desc' : 'asc'
    setParams(prev => ({ ...prev, sortConfig: { order, sortBy: property } }))
  }

  const handleFiltersSubmit = values => {
    setParams(prev => ({ ...prev, filters: values, page: 0 }))
  }

  const handlePrevClick = () => {
    setParams(prev => ({ ...prev, page: prev.page - 1 }))
  }

  const handleNextClick = () => {
    setParams(prev => ({ ...prev, page: prev.page + 1 }))
  }

  const { sortConfig } = params

  return [
    paramsForApiCall,
    handlePrevClick,
    handleNextClick,
    handleFiltersSubmit,
    sortConfig,
    handleSort
  ]
}

export default useAPIQueryParams
