import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getISOLocalDate } from '../helpers/dates'

/**
 * @param {Object} initialParams - An object containing the initial search params. Default value is an empty object.
 * @param {number|null} totalPages - The total number of pages in the pagination. Default value is null.
 * @param {function|null} formatFilterQueryFn - A function to format the filter query. Default value is null.
 * @returns {Object} - An object containing the search params, sort configuration, and functions to handle filtering, sorting, and pagination.
 */
const useFiltersSortingAndPagination = (
  initialParams = {},
  totalPages = null,
  formatFilterQueryFn
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = useMemo(
    // must be in useMemo, otherwise it may cause an infinite loop in other components
    () => Object.fromEntries([...searchParams]),
    [searchParams]
  )

  useEffect(() => {
    // setting initial params here instead of using useSearchParams(initialParams)
    // because there were issues with empty params while navigating through the app
    if (Object.keys(params).length === 0) {
      Object.keys(initialParams).forEach(key => {
        searchParams.set(key, initialParams[key])
      })

      setSearchParams(searchParams)
    }
  }, [initialParams, params, searchParams, setSearchParams])

  const { page, sort, order, ...filters } = params
  const pageNum = parseInt(page) || 1

  useEffect(() => {
    if (searchParams.has('page')) {
      if (totalPages === 0 || totalPages === 1 || pageNum <= 1) {
        // delete page from search params if no results or page is 1
        searchParams.delete('page')
      } else if (!!totalPages && pageNum > totalPages) {
        // reset page number if is greater than total number of pages
        searchParams.set('page', totalPages)
      }
      setSearchParams(searchParams, { replace: true })
    }
  }, [pageNum, searchParams, setSearchParams, totalPages])

  const handlePrevClick = () => {
    searchParams.set('page', pageNum - 1)

    setSearchParams(searchParams)
  }

  const handlePageNumberClick = id => {
    searchParams.set('page', id)
    setSearchParams(searchParams)
  }

  const handleNextClick = () => {
    searchParams.set('page', pageNum + 1)

    setSearchParams(searchParams)
  }

  const handleFilters = values => {
    const allowedFalsyFilterValues = [0, false]

    Object.keys(values).forEach(key => {
      const filterValue = values[key]

      if (!!filterValue || allowedFalsyFilterValues.includes(filterValue)) {
        const formattedValue =
          filterValue instanceof Date
            ? getISOLocalDate(filterValue)
            : typeof formatFilterQueryFn === 'function'
            ? formatFilterQueryFn(key, filterValue)
            : filterValue

        searchParams.set(key, formattedValue)
      } else {
        searchParams.delete(key)
      }
    })

    setSearchParams(searchParams)
  }

  const handleSort = property => {
    const isAsc =
      searchParams.get('sort') === property &&
      searchParams.get('order') === 'asc'

    const order = isAsc ? 'desc' : 'asc'

    searchParams.set('sort', property)
    searchParams.set('order', order)

    setSearchParams(searchParams)
  }

  return {
    searchParams: params,
    sortConfig: { sortBy: sort, order },
    filters,
    handleFilters,
    handleSort,
    handlePrevClick,
    handleNextClick,
    handlePageNumberClick
  }
}

export default useFiltersSortingAndPagination
