// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'

import Message, { messageTypes } from '../../Common/Message'

export const TextInput = ({ hasError = false, ...props }) => (
  <div className='vf-input__container w-100'>
    <input
      type='text'
      autoComplete='off'
      className={`vf-input w-100 ${!!hasError ? 'vf-input--error' : ''}`}
      {...props}
    />
  </div>
)

export const BasicTextInputWithLabel = ({ labelText = '', ...props }) => {
  const labelName = props.name

  return (
    <>
      {!!labelText && (
        <label htmlFor={labelName} className='mt-2'>
          {labelText}
        </label>
      )}

      <TextInput {...props} />
    </>
  )
}

const FormikTextInput = ({ label, formatValueFn, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { name } = field
  const { setValue } = helpers
  const { error, touched } = meta

  const { validateField } = useFormikContext()

  const validateThisField = () => {
    validateField(name)
  }

  const handleChange = e => {
    let { value } = e.target

    if (typeof formatValueFn === 'function') {
      value = formatValueFn(value)
    }

    setValue(value)
    validateThisField()
  }

  const hasError = touched && error

  return (
    <div>
      <BasicTextInputWithLabel
        {...field}
        {...props}
        labelText={label}
        hasError={hasError}
        onBlur={validateThisField}
        onChange={handleChange}
      />

      {hasError && <Message type={messageTypes.error}>{error}</Message>}
    </div>
  )
}

FormikTextInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}

export default FormikTextInput
