import { get } from 'lodash'
import { Link } from 'react-router-dom'

import './item-template.scss'

const Item = ({
  item,
  className = '',
  listConfig = {},
  ctas: Ctas,
  isInactive = false,
  ...props
}) => {
  const { gridTemplateColumns = '', attributes = [] } = listConfig

  const getItemValue = attr => {
    if (typeof attr.formatFn === 'function') {
      return attr.formatFn(get(item, attr.propName), item)
    }

    return get(item, attr.propName) || '-'
  }

  const getPath = attr => {
    if (typeof attr.path === 'function') {
      return attr.path(item.id)
    }

    return attr.path
  }

  const handleClick = attr => {
    if (typeof attr.onClick === 'function') {
      attr.onClick(item)
    }
  }

  return (
    <div
      className={`item-template ${className}`}
      style={{ gridTemplateColumns, color: isInactive ? '#ccc' : '#222' }}
      data-test='item-template'>
      {attributes.map(attr => (
        <div
          key={attr.propName}
          className={attr.className}
          style={attr.style}
          data-test={`item-template-${attr.propName}`}
          onClick={() => handleClick(attr)}>
          {!!attr.path ? (
            <Link to={getPath(attr)}>{getItemValue(attr)}</Link>
          ) : (
            getItemValue(attr)
          )}
        </div>
      ))}

      {!!Ctas && (
        <div className='item-template__ctas' data-test='item-template-ctas'>
          <Ctas item={item} {...props} />
        </div>
      )}
    </div>
  )
}

export default Item
