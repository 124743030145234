import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getMainNavItems, filterItemByRole } from '../../routes'
import { NavigationContext } from './NavigationContext.js'
import { useCurrentUserContext } from '../../hooks'
import rightChevronIcon from '../../assets/icons/VF_right_rgb.svg'
import leftChevronIcon from '../../assets/icons/VF_left_rgb.svg'

import './navigation.scss'

const NavigationItem = ({ item, smaller }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const getIsActive = () => {
    const { activePaths } = item.nav || []

    return activePaths?.find(path => {
      const regex = new RegExp(path + '($|(?:/\\w+)+)')

      return regex.test(location.pathname)
    })
  }

  const isActive = getIsActive()

  return (
    <li className={smaller ? 'bottom-nav__link--smaller' : ''}>
      <NavLink
        to={item.path}
        className={isActive ? 'active' : ''}
        data-test='nav-link'>
        {item.nav.iconSrc && <img src={item.nav.iconSrc} alt='' />}

        <span
          className={`bottom-nav__link__title ${
            smaller ? 'bottom-nav__link__title--not-visible' : ''
          }`}>
          {t(item.nav.title)}
        </span>
      </NavLink>
    </li>
  )
}

const Navigation = ({ items = [], smaller = false, resize }) => (
  <nav className={`bottom-nav ${smaller ? 'bottom-nav__only-icons' : ''}`}>
    <ul>
      {items.map(item => (
        <NavigationItem key={item.path} item={item} smaller={smaller} />
      ))}

      <div className='bottom-nav__sizer' onClick={resize}>
        <img src={smaller ? rightChevronIcon : leftChevronIcon} alt='chevron' />
      </div>
    </ul>
  </nav>
)

const NavigationContainer = () => {
  const items = getMainNavItems()
  const { user } = useCurrentUserContext()

  const role = user?.role
  const roleAllowedItems = items.filter(filterItemByRole(role))

  const { smallerNav, resizeNav } = useContext(NavigationContext)

  return (
    <Navigation
      items={roleAllowedItems}
      smaller={smallerNav}
      resize={resizeNav}
    />
  )
}

export default NavigationContainer
