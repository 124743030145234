export const reactSelectSizes = {
  small: 'small',
  regular: 'regular'
}

export const reactSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: 'auto',
    height:
      state.selectProps.className === reactSelectSizes.small ? '30px' : '52px',
    borderColor: state.selectProps.hasError ? '#ffb2a4' : '#e8e8e9',
    minWidth:
      state.selectProps.className === reactSelectSizes.small
        ? '130px'
        : '200px',
    cursor: state.selectProps.readOnly ? 'default' : 'pointer',
    ':hover': {
      borderColor: state.selectProps.hasError ? '#ffb2a4' : '#e8e8e9'
    },
    boxShadow: 'none'
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    height:
      state.selectProps.className === reactSelectSizes.small ? '30px' : '52px',
    padding:
      state.selectProps.className === reactSelectSizes.small
        ? '0 8px'
        : '2px 8px'
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    height:
      state.selectProps.className === reactSelectSizes.small ? '30px' : '52px'
  }),
  option: (styles, state) => ({
    ...styles,
    fontSize:
      state.selectProps.className === reactSelectSizes.small
        ? '0.9rem'
        : '1rem',
    cursor: 'pointer'
  }),
  singleValue: (styles, state) => ({
    ...styles,
    fontSize:
      state.selectProps.className === reactSelectSizes.small ? '0.9rem' : '1rem'
  }),
  menu: styles => ({ ...styles, zIndex: 9999 })
}
