import { useTranslation } from 'react-i18next'

import { DetailsBtn, buttonSizes } from '../../Common/Ctas'
import { useSlidePanelContext } from '../../../hooks'
import Details from '../Details'
import Badge, { badgeColors } from '../../Common/Badge'
import { CtasGroup } from '.'

const PanelTitle = ({ t, item }) => (
  <span
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
    {t('users.form.titles.details')}

    <span>
      {item?.isAdmin && <Badge color={badgeColors.green}>Admin</Badge>}
      {item?.accountLocked && (
        <Badge color={badgeColors.grey} style={{ marginLeft: 10 }}>
          Blocked
        </Badge>
      )}
    </span>
  </span>
)

const UserDetailsBtn = ({ item, refreshList }) => {
  const { t } = useTranslation()
  const { openSlidePanel } = useSlidePanelContext()

  return (
    <DetailsBtn
      active
      size={buttonSizes.small}
      onClick={() =>
        openSlidePanel({
          title: <PanelTitle t={t} item={item} />,
          ctas: (
            <CtasGroup
              item={item}
              refreshList={refreshList}
              size={buttonSizes.medium}
              hideDetailsBtn
            />
          ),
          size: 40,
          content: () => <Details id={item.id} />
        })
      }
    />
  )
}

export default UserDetailsBtn
