import { useMemo, useState } from 'react'

/**
 * @template T
 * @param {T[]} items
 * @param {Object} initialConfig { order: 'ASC' || 'DESC', sortBy: string (property name) } || null
 * @param {Function} formatPropertyFn
 * @returns {{items: T[], handleSort: Function, sortConfig: Object}}
 */

const useSortableData = (
  items = [],
  initialConfig = null,
  formatPropertyFn = (item, key) => item[key]
) => {
  const [sortConfig, setSortConfig] = useState(initialConfig)

  const sortedItems = useMemo(() => {
    const sortableItems = [...items]

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const propertyA = formatPropertyFn(a, sortConfig.sortBy)
        const propertyB = formatPropertyFn(b, sortConfig.sortBy)

        if (propertyA < propertyB) {
          return sortConfig.order === 'asc' ? -1 : 1
        }
        if (propertyA > propertyB) {
          return sortConfig.order === 'asc' ? 1 : -1
        }

        return b.id - a.id
      })
    }

    return sortableItems
  }, [items, sortConfig, formatPropertyFn])

  const handleSort = property => {
    const isAsc =
      !!sortConfig &&
      sortConfig.sortBy === property &&
      sortConfig.order === 'asc'

    const order = isAsc ? 'desc' : 'asc'
    setSortConfig({ order, sortBy: property })

    return { order, sortBy: property }
  }

  return { items: sortedItems, handleSort, sortConfig }
}

export default useSortableData
