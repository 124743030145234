// @ts-check

import jwt_decode from 'jwt-decode'

/**
 * External User Account Type definition
 * @typedef {{given_name: string, family_name: string, unique_name: string, exp: number}} DecodedToken
 */

/**
 * External User Account Type definition
 * @typedef {{family_name: string, given_name: string, unique_name: string, name: string, token: string, exp: number}} AccountDetails
 */

/**
 * Get claims from given token and return as Account
 * @param {string} token JWT token
 * @returns {AccountDetails} account
 */
export const tokenToAccount = (token = '') => {
  // @ts-ignore
  var decoded: DecodedToken = jwt_decode(token)

  const { given_name: firstName, family_name: lastName } = decoded

  return { ...decoded, token, name: `${firstName} ${lastName}` }
}

/**
 * Write Account into Local Storage
 * @param {AccountDetails} account
 * @returns {void}
 */
export const setExtAccount = account => {
  try {
    localStorage.setItem('ext_user', JSON.stringify(account))
  } catch (e) {
    console.error('Error setting external user account in localStorage:', e)
  }
}

/**
 * Read Account from storage
 * @returns {AccountDetails|null} external user account or null
 */
export const getExtAccount = () => {
  const accountRaw = localStorage.getItem('ext_user')

  if (!accountRaw) {
    return null
  }

  try {
    const account = JSON.parse(accountRaw)

    return account
  } catch (e) {
    console.error('Error parsing external user account JSON:', e)

    return null
  }
}

/**
 *
 * @param {AccountDetails} account
 * @returns {boolean} True if the account has expired, false otherwise.
 * @example
 * const account = {
 *  exp: 1590000000
 * }
 * isAccountExpired(account) // false
 */
const isAccountExpired = account => {
  const now = new Date()
  const nowMs = now.getTime()

  const { exp } = account
  const expMs = exp * 1000

  return nowMs > expMs
}

const refreshToken = () => {
  window.location.href = '/login'
}

/**
 * Get the current access token
 * @returns {Promise<string>} accessToken or throw Error
 */
export const getToken = () => {
  const account = getExtAccount()

  if (!account) {
    refreshToken()
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  const { token } = account
  const expired = isAccountExpired(account)

  if (expired) {
    refreshToken()
    throw Error(
      'Expired! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  // returning Promise to align this function with msal version
  return new Promise(resolve => resolve(token))
}

/**
 * Returns a boolean indicating whether the current user is an external user or not.
 * @returns {boolean} True if the user is an external user, false otherwise.
 */
export const getIsExtUser = () => {
  const account = getExtAccount()
  if (!account) {
    return false
  }

  const expired = isAccountExpired(account)
  if (expired) {
    return false
  }

  return true
}
