import { useState } from 'react'
import Modal from '../../DesignSystem/Modal/Modal'
import { useTranslation } from 'react-i18next'
import { BasicTextAreaWithLabel } from '../../CommonForm/TextArea/TextArea'
import ActionButton from '../ActionButton/ActionButton'
import { colors } from '../ActionButton/ActionButton'

/**  Use the component for actions (e.g.: deprecation, deletion) where confirmation with provided reason is needed.
* @param {Object} props
* @param {string} props.title - title shown on the modal top
* @param {boolean} props.modalHidden - show / hide modal
* @param {() => void} props.toggleModal
* @param {boolean} props.commentRequired - show/hide textArea for comment
* @param {string} props.cancelButtonText - text displayed on cancel button
* @param {string } props.confirmButtonText - text displayed on confirm button
* @param {boolean} props.inProgress - disable confirm button if true
* @param {function} props.handleConfirm - function called on Customer confirm button click
* @param {string} props.textAreaLabel - label for comment box

* @returns {JSX.Element}

* @example
*  <ConfirmModal
     title='Delete the Item?'
     modalHidden={modalHidden}
     toggleModal={toggleModal}
     commentRequired={commentRequired}
     cancelButtonText={modalCancelButtonText}
     confirmButtonText={modalConfirmButtonText}
     inProgress={inProgress}     
     handleConfirm={handleConfirm}
     textAreaLabel={textAreaLabel}
   />
*/

const ConfirmModal = ({
  title = '',
  commentRequired = false,
  cancelButtonText,
  confirmButtonText,
  toggleModal = () => {},
  handleConfirm = () => {},
  inProgress = false,
  modalHidden = false,
  textAreaLabel
}) => {
  const { t } = useTranslation()
  const [comment, setComment] = useState('')

  const handleCloseModal = () => {
    toggleModal()
    setComment('')
  }

  return (
    <Modal hidden={modalHidden} closeModal={handleCloseModal}>
      <div className='modal__title'>{title}</div>

      {commentRequired && (
        <BasicTextAreaWithLabel
          label={textAreaLabel ?? t('issues.form.labels.comment') + ' *'}
          value={comment}
          onChange={e => setComment(e.target.value)}
          rows={3}
          maxLength={250}
        />
      )}

      <div className='modal__ctas'>
        <ActionButton onClick={handleCloseModal} data-test='modal-cancel'>
          {cancelButtonText}
        </ActionButton>

        <ActionButton
          color={colors.blue}
          onClick={() => {
            handleConfirm(comment)
          }}
          disabled={inProgress || (commentRequired ? !comment.trim() : false)}
          data-test='modal-confirm'>
          {confirmButtonText}
        </ActionButton>
      </div>
    </Modal>
  )
}

export default ConfirmModal
