import { orderBy } from 'lodash'

/**
 * Returns array of objects sorted by item id
 * @param {Object[]} array
 * @param {string} order sorting order ('asc' || 'desc')
 */

export const sortById = (array, order = 'asc') => {
  return orderBy(array, 'id', order)
}

/**
 * @template T
 * @param {T[]} array array to be sorted
 * @param {string} order 'asc' or 'desc'
 * @param {string} param param by which array will be sorted
 * @returns {T[]} array sorted
 */

export const sortByParam = (array = [], order = 'asc', param = '') => {
  if (
    !array?.length ||
    param === '' ||
    !Object.keys(array[0]).includes(param)
  ) {
    return array
  }

  return orderBy(
    array,
    [
      item => {
        if (typeof item[param] === 'string') {
          return item[param].toLowerCase()
        } else {
          return item[param]
        }
      }
    ],
    [order]
  )
}
