import { DOTS, usePaginationRange } from './usePaginationRange'
import './pagination.scss'

export const paginationPositions = {
  right: 'right',
  left: 'left',
  center: 'center'
}

/**
 *
 * @param {Object} props
 * @returns {JSX.Element}
 * @example
 * <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        isFirst={currentPage === 1}
        isLast={currentPage === totalPages}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
        handlePageNumberClick={handlePageNumberClick}
      />
 */
const PaginationWithNumbers = ({
  totalPages,
  currentPage,
  isLast,
  isFirst,
  handleNextClick,
  handlePrevClick,
  handlePageNumberClick,
  paginationPosition = paginationPositions.center
}) => {
  const paginationRange = usePaginationRange(totalPages, currentPage)

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null
  }

  return (
    totalPages > 1 && (
      <ul
        className={`vf-pagination mt-2 position__${paginationPosition}`}
        data-cy='pagination'>
        <li className={`vf-page-item ${isFirst ? 'disabled' : ''}`}>
          <button
            className='vf-page-link'
            aria-disabled={isFirst}
            data-cy='previous-button'
            onClick={handlePrevClick}>
            &laquo;
          </button>
        </li>

        {paginationRange?.map((item, index) => {
          if (item === DOTS) {
            return (
              <li key={item + index} className='vf-page-item dots'>
                &#8230;
              </li>
            )
          }

          return (
            <li
              key={item + index}
              className={`vf-page-item ${
                item === parseInt(currentPage) ? 'active' : ''
              }`}>
              <button
                id={item}
                data-cy={`page-number-${item}`}
                onClick={e => handlePageNumberClick(parseInt(e.target.id))}
                className='vf-page-link'>
                {item}
              </button>
            </li>
          )
        })}
        <li className={`vf-page-item ${isLast ? 'disabled' : ''}`}>
          <button
            data-cy='next-button'
            className='vf-page-link'
            aria-disabled={isLast}
            onClick={handleNextClick}>
            &raquo;
          </button>
        </li>
      </ul>
    )
  )
}
export default PaginationWithNumbers
