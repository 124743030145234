import { PublicClientApplication } from '@azure/msal-browser'

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
// const ua = window.navigator.userAgent
// const msie = ua.indexOf('MSIE ')
// const msie11 = ua.indexOf('Trident/')
// const msedge = ua.indexOf('Edge/')
// const firefox = ua.indexOf('Firefox')
// const isIE = msie > 0 || msie11 > 0
// const isEdge = msedge > 0
// const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: '74b05431-c1b9-449b-aff4-22a39638a97d',
    authority:
      'https://login.microsoftonline.com/f8be18a6-f648-4a47-be73-86d6c5c6604d',
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11
  }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['api://74b05431-c1b9-449b-aff4-22a39638a97d/Data.All']
}

export const msalInstance = new PublicClientApplication(msalConfig)
