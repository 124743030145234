import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FiltersIcon } from './icons/VF_filter_rgb.svg'
import ButtonTemplate from './ButtonTemplate'

const FiltersBtn = ({ text, ...props }) => {
  const { t } = useTranslation()

  return (
    <ButtonTemplate
      text={text || t('buttons.filters')}
      icon={FiltersIcon}
      {...props}
    />
  )
}

export default FiltersBtn
