import React, { useState } from 'react'

import { getUrl } from '../../../apiUrls'
import callApi from '../../../api'
import { DownloadBtn } from '../../Common/Ctas'

const DownloadFileBtn = ({ id }) => {
  const [isRunning, setIsRunning] = useState(false)
  const downloadUrl = getUrl.attachmentById(id)

  const downloadCallback = res => {
    const { bodyAsBase64, mimeType, filename } = res

    const fileUrl = `data:${mimeType};base64,${bodyAsBase64}`

    const link = document.createElement('a')
    link.href = fileUrl
    link.setAttribute('download', filename)

    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

    setIsRunning(false)
  }

  const handleDownload = async () => {
    setIsRunning(true)

    await callApi(downloadUrl, downloadCallback)
  }

  return <DownloadBtn active={!isRunning} onClick={handleDownload} />
}

export default DownloadFileBtn
