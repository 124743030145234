import { DetailsBtn, EditBtn, BlockBtn, DeleteBtn } from '.'
import { buttonSizes } from '../../Common/Ctas'

const CtasGroup = ({
  item,
  refreshList,
  size = buttonSizes.small,
  hideDetailsBtn = false
}) => (
  <>
    {!hideDetailsBtn && (
      <DetailsBtn item={item} refreshList={refreshList} size={size} />
    )}
    <EditBtn item={item} refreshList={refreshList} size={size} />
    <BlockBtn item={item} refreshList={refreshList} size={size} />
    <DeleteBtn item={item} refreshList={refreshList} size={size} />
  </>
)

export default CtasGroup
