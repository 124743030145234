import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Template, {
  NavigationResizeProvider
  // FiveStar,
  // ReportAnIssue
} from './components/Template'
import { ToastContextProvider } from './contexts/ToastContext'
import { SlidePanelContextProvider } from './contexts/SlidePanelContext'
import { getRoutesByRole } from './routes'
import ProtectedRoute from './ProtectedRoute'
import AccessDeniedPage from './pages/403'
import { useCurrentUserContext } from './hooks'

const RouteWithTemplate = ({ element: Component, withCustomBreadcrumbs }) => (
  <Template withCustomBreadcrumbs={withCustomBreadcrumbs}>
    <ToastContextProvider>
      <SlidePanelContextProvider>
        <Component />
      </SlidePanelContextProvider>
    </ToastContextProvider>
  </Template>
)

const getSecuredRoutesWithTemplate = routes =>
  routes.filter(item => item.withTemplate).filter(({ secure }) => secure)

const getSecuredOtherRoutes = routes =>
  routes.filter(item => !item.withTemplate).filter(({ secure }) => secure)

const getNonSecuredOtherRoutes = routes =>
  routes.filter(item => !item.withTemplate).filter(({ secure }) => !secure)

const App = () => {
  const { user, accessDenied } = useCurrentUserContext()

  // redirect to consent page if no consent given
  const consentPathName = '/consent'
  if (
    !!user &&
    !user.consentGiven &&
    window.location.pathname !== consentPathName
  ) {
    window.location.href = consentPathName
  }

  // check if given access
  if (accessDenied) {
    return <AccessDeniedPage />
  }

  const role = user?.role
  const routesForRole = getRoutesByRole(role)

  return (
    <NavigationResizeProvider>
      <Router>
        <Routes>
          <>
            {getSecuredRoutesWithTemplate(routesForRole).map((item, id) => (
              <Route
                key={id}
                path={item.path}
                element={
                  <ProtectedRoute>
                    {user && <RouteWithTemplate {...item} />}
                  </ProtectedRoute>
                }
              />
            ))}

            {getSecuredOtherRoutes(routesForRole).map((item, id) => {
              const Component = item.element

              return (
                <Route
                  key={id}
                  path={item.path}
                  element={
                    <ProtectedRoute>{user && <Component />}</ProtectedRoute>
                  }
                />
              )
            })}
          </>

          {getNonSecuredOtherRoutes(routesForRole).map((item, id) => {
            const Component = item.element

            return <Route key={id} path={item.path} element={<Component />} />
          })}
        </Routes>
      </Router>
      {/* <FiveStar />
      <ReportAnIssue /> */}
    </NavigationResizeProvider>
  )
}

export default App
