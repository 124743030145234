import { useTranslation } from 'react-i18next'

import { ReactComponent as BlockIcon } from './icons/VF_locked_rgb.svg'
import { ReactComponent as UnblockIcon } from './icons/VF_unlocked_rgb.svg'
import ButtonTemplate from './ButtonTemplate'

const BlockBtn = ({ locked = false, ...props }) => {
  const { t } = useTranslation()

  return (
    <ButtonTemplate
      text={locked ? t('buttons.unblock') : t('buttons.block')}
      icon={locked ? UnblockIcon : BlockIcon}
      {...props}
    />
  )
}

export default BlockBtn
