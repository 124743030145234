import './header-template.scss'

const OrderIcon = ({ property, sortConfig }) => {
  const getOrderIcon = () =>
    sortConfig?.sortBy === property && sortConfig?.order === 'desc' ? '↑' : '↓'

  return (
    <span
      className='order-icon'
      style={{
        visibility: sortConfig?.sortBy !== property ? 'hidden' : 'visible'
      }}
      data-test='sorting-icon'>
      {getOrderIcon()}
    </span>
  )
}

const Header = ({
  className = '',
  listConfig = {},
  handleSort = () => {},
  sortConfig
}) => {
  const { gridTemplateColumns = '', attributes = [] } = listConfig

  return (
    <div
      className={`header-template ${className}`}
      style={{ gridTemplateColumns }}
      data-test='header-template'>
      {attributes.map(attr =>
        attr.sortable ? (
          <div
            key={attr.propName}
            style={attr.headerStyle}
            onClick={() => handleSort(attr.propName)}
            className='header-template--sortable'
            data-test={`header-template-${attr.propName}`}>
            {attr.label}

            <OrderIcon property={attr.propName} sortConfig={sortConfig} />
          </div>
        ) : (
          <div
            key={attr.propName}
            style={attr.headerStyle}
            data-test={`header-template-${attr.propName}`}>
            {attr.label}
          </div>
        )
      )}
    </div>
  )
}

export default Header
