import { useTranslation } from 'react-i18next'

import { DeleteBtn, buttonSizes } from '../../Common/Ctas'
import {
  useModalContext,
  useToastContext,
  useSlidePanelContext
} from '../../../hooks'
import { getUrl } from '../../../apiUrls'
import { apiDeleteWithToken } from '../../../api'
import { callback } from '../../../helpers/forms'

const DeleteCustomerBtn = ({
  item,
  refreshList = () => {},
  size = buttonSizes.small
}) => {
  const { t } = useTranslation()
  const openModal = useModalContext()
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()

  const deleteUrl = getUrl.customerDelete(item.id)

  const successCallback = () => {
    refreshList()
    closeSlidePanel() // the delete button can be accessed in the details panel
  }

  const handleDelete = () => {
    apiDeleteWithToken(deleteUrl, callback(addToast, successCallback))
  }

  return (
    <DeleteBtn
      active
      size={size}
      onClick={() =>
        openModal({
          title: t('customers.form.titles.delete', {
            name: item.name
          }),
          onConfirm: handleDelete
        })
      }
    />
  )
}

export default DeleteCustomerBtn
