import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { getUrl } from '../../../apiUrls'
import { apiPatchWithToken } from '../../../api'
import Form from './Form'
import { formModes } from '../../CommonForm'
import { handleSubmitFactory } from '../../../helpers/forms'
import {
  validationSchema,
  formatFormValuesForApiEdit as formatFormValuesForApi,
  getCanEditIssue,
  getCanEditAttachments
} from './helpers'
import { useToastContext, useData } from '../../../hooks'
import routesUrls, { urlWithParam } from '../../../routesUrls'
import { formatItemForForm, handleIssueAttachments } from './helpers'
import getNotification from '../../../helpers/apiNotifications'
import VfBanner from '../../DesignSystem/Banner/VfBanner'
import Loader from '../../Common/Loader'
import { toastTypes } from '../../../contexts/ToastContext'
import {
  SidebarColumnSection,
  SidebarColumnSectionItem
} from '../../Layout/TwoColumnsTemplate'
import { StatusBadge } from '../Details/IssueBadges'
import { AuditSection } from '../Details/Details'

const SidebarColumnContent = ({ issue, t }) => (
  <>
    <SidebarColumnSection>
      <StatusBadge status={issue.status} />
    </SidebarColumnSection>

    <SidebarColumnSection>
      <AuditSection issue={issue} t={t} />
    </SidebarColumnSection>

    <SidebarColumnSection>
      <SidebarColumnSectionItem
        label={t('issues.form.labels.woId')}
        value={issue.woId}
      />
    </SidebarColumnSection>
  </>
)

const Edit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addToast } = useToastContext()

  const { id } = useParams()
  const url = getUrl.issueById(id)
  const { loading, items: data, error } = useData(url)

  const initialValues = formatItemForForm(data)

  const [apiErrors, setApiErrors] = useState(null)

  const callback = (res, values) => {
    if (!!res.error) {
      addToast(res.message, {
        type: toastTypes.error
      })
      setApiErrors(res.json)
    } else {
      addToast('', { type: toastTypes.success })
      navigate(urlWithParam(routesUrls.issues.details, { id: res.id }))
    }
  }

  const editUrl = getUrl.issueUpdate()

  const handleSubmit = handleSubmitFactory(
    editUrl,
    apiPatchWithToken,
    callback,
    formatFormValuesForApi
  )

  const Info = getNotification(loading, error, data, Loader)

  const canEdit = getCanEditIssue(data)

  return Info ? (
    Info
  ) : (
    <>
      {!canEdit && (
        <div className='mb-2'>
          <VfBanner>{t('issues.form.titles.cannotEdit')}</VfBanner>
        </div>
      )}

      <Form
        apiErrors={apiErrors}
        canEditAttachments={getCanEditAttachments(formModes.edit, data)}
        formTitle={t('titles.issueEdit')}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        issue={data}
        mode={formModes.edit}
        isMainColumnReadOnly // the main column is read-only, but the sidebar is not
        sidebarColumnContent={<SidebarColumnContent issue={data} t={t} />}
        validationSchema={() => validationSchema(t, formModes.edit)}
      />
    </>
  )
}

export default Edit
