// @ts-check
import { acquireAccessToken } from './auth/helpers'
import {
  getIsExtUser,
  getToken as getNonAdToken
} from './AuthNonAdUsers/helpers'
import { getToken as getAdToken } from './AuthAdUsers/helpers'

/**
 *
 * @param {object} data
 * @param {string} method
 * @param {string} token
 * @returns
 */
const genericOptions = (data, method, token) => ({
  method,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  },
  body: JSON.stringify(data)
})

const getOptions = token => ({
  headers: {
    Authorization: 'Bearer ' + token
  }
})

/**
 *
 * @param {object} response
 * @returns
 */
const validateResponse = async response => {
  if (response.status === 204) {
    // No Content
    console.warn('NO CONTENT from', response.url)
    return { json: () => null }
  }

  if (response.status >= 400 && response.status < 500) {
    // No Content
    console.warn(
      `Ok but not ok | ${response.status} | Client Error`,
      response.url
    )

    try {
      const json = await response.json()

      return {
        json: () => ({
          error: 'Bad Request',
          status: response.status,
          message: json.message,
          json
        })
      }
    } catch (err) {
      return { json: () => ({ error: 'Bad request' }) }
    }
  }

  if (response.status >= 500 && response.status < 600) {
    // No Content
    console.warn(
      `Ok but not ok | ${response.status} | Server Error`,
      response.url
    )

    const json = await response.json()

    return {
      json: () => ({
        error: 'Bad Request',
        status: response.status,
        message: json.message
      })
    }
  }

  return response
}

/**
 *
 * @param {string} method
 * @param {object} [data]
 * @returns {function}
 */
const api =
  (method, data) =>
  /**
   *
   * @param {string} url
   * @param {function} callback
   * @param {string} token
   * @returns
   */
  (url, callback = () => {}, token) => {
    const options =
      method === 'GET' ? getOptions(token) : genericOptions(data, method, token)
    return fetch(url, options)
      .then(validateResponse)
      .then(async res => {
        const contentType = res.headers?.get('content-type')

        if (
          !!contentType &&
          (contentType.includes('text/csv') ||
            contentType.includes('text/plain'))
        ) {
          return res.text()
        } else if (
          !!contentType &&
          contentType.includes('application/octet-stream')
        ) {
          const contentDisposition = res.headers?.get('content-disposition')
          if (!!contentDisposition) {
            const fileName = contentDisposition.match(/filename="(.+)"/)[1]

            const blob = await res.blob()
            return [blob, fileName]
          }

          return res.blob()
        }

        return res.json()
      })
      .then(callback)
  }

const callApi = async (url, callback, api = apiGet) => {
  if (window.Cypress) {
    // we are running in Cypress
    // api calls are mocked (acquireTokenSilent doesn't work')
    return api(url, callback, null)
  }
  // to-do
  const isExtUser = getIsExtUser()
  const getToken = isExtUser ? getNonAdToken : getAdToken
  const accessToken = await getToken()

  if (!!accessToken) {
    return api(url, callback, accessToken)
  }

  console.warn('api call: No token object available')
}

export default callApi

export const apiGet = api('GET')

export const apiPost = (data = {}) => api('POST', data)
export const apiPostWithToken = (url, data = {}, callback = () => {}) =>
  callApi(url, callback, apiPost(data))

const apiDelete = api('DELETE')
export const apiDeleteWithToken = (url, callback = () => {}) =>
  callApi(url, callback, apiDelete)

const apiPut = (data = {}) => api('PUT', data)
export const apiPutWithToken = (url, data = {}, callback = () => {}) =>
  callApi(url, callback, apiPut(data))

const apiPatch = (data = {}) => api('PATCH', data)
export const apiPatchWithToken = (url, data = {}, callback = () => {}) =>
  callApi(url, callback, apiPatch(data))

export const fetchAll = async (urls, callback = () => {}) => {
  const token = await acquireAccessToken()

  if (!!token) {
    const options = getOptions(token)
    const promises = urls.map(async url => {
      return fetch(url, options)
        .then(validateResponse)
        .then(res => res.json())
    })

    await Promise.all(promises).then(callback)
  } else {
    console.warn('api call: No token object available')
  }
}

export const apiPostWithProgress = async (
  url,
  data = {},
  callback = _ => {},
  setProgress = _ => {}
) => {
  const token = await acquireAccessToken()

  const response = await new Promise(resolve => {
    const xhr = new XMLHttpRequest()

    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const percentComplete = parseInt((event.loaded / event.total) * 100)

        setProgress(percentComplete)
      }
    }

    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200 || xhr.status === 201) {
          const response = JSON.parse(xhr.responseText)
          resolve(response)
        } else {
          const response = JSON.parse(xhr.responseText)
          // handle errors from API
          resolve(
            !!response.general
              ? { error: true, message: response.general }
              : { error: 'Bad request' }
          )
        }
      }
    })

    xhr.open('POST', url, true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    xhr.send(JSON.stringify(data))
  })

  callback(response)
}
