import * as Yup from 'yup'

import { getFormattedOptions } from '../../CommonForm'
import { getUserFullName } from '../../../helpers/strings'

export const validationSchema = t =>
  Yup.object({
    name: Yup.string()
      .max(50, t('common.form.validation.maxLength', { count: 50 }))
      .required(t('customers.form.validation.name.required')),
    abbreviation: Yup.string()
      .max(15, t('common.form.validation.maxLength', { count: 15 }))
      .required(t('customers.form.validation.abbreviation.required')),
    description: Yup.string().max(
      255,
      t('common.form.validation.maxLength', { count: 255 })
    ),
    bastId: Yup.string()
      .max(20, t('common.form.validation.maxLength', { count: 20 }))
      .required(t('customers.form.validation.bastId.required')),
    customerTypeId: Yup.string().required(
      t('customers.form.validation.customerType.required')
    ),
    contractTypeIds: Yup.array()
      .min(
        1,
        t('customers.form.validation.contractTypeIds.minLength', { count: 1 })
      )
      .required(t('customers.form.validation.contractTypeIds.required')),
    userIds: Yup.array(),
    defaultContractTypeId: Yup.string().required(
      t('customers.form.validation.defaultContractTypeId.required')
    )
  })

export const formatValuesForApi = values => {
  const data = { ...values }

  data.contractTypeIds = values.contractTypeIds.map(item => item.value)
  data.userIds = values.userIds.map(item => item.value)

  return data
}

export const formatItemForForm = item => ({
  id: item?.id,
  name: item?.name,
  abbreviation: item?.abbreviation,
  description: item?.description || '',
  bastId: item?.bastId,
  customerTypeId: item?.customerTypeDto?.id,
  contractTypeIds: getFormattedOptions(item?.contractTypeDtos, {
    additionalPropsFn: item => ({ disabled: !item.active })
  }),
  defaultContractTypeId: item?.defaultContractTypeId || '',
  userIds: getFormattedOptions(item?.userDtos, {
    formatLabelFn: getUserFullName,
    additionalPropsFn: item => ({
      email: item.email
    })
  })
})
