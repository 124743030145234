import { useTranslation } from 'react-i18next'
import { VfButton, colors } from '../../DesignSystem/Buttons'

const ResendInvitationButton = ({ onClick, disabled = false }) => {
  const { t } = useTranslation()
  return (
    <div className='mt-2 form-buttons'>
      <VfButton color={colors.primary} onClick={onClick} disabled={disabled}>
        {t('buttons.resendInvitation')}
      </VfButton>
    </div>
  )
}

export default ResendInvitationButton
