// @ts-check
import React from 'react'
import { useModalContext } from '../../hooks'
import ModalContent from './Consent'
import { getUrl } from '../../apiUrls'
import { apiPutWithToken } from '../../api'

/**
 * @typedef {import('../Users/types').UserDto} UserDto
 */

/**
 * Update consentGiven flag in user object in API
 * @param {UserDto} user
 */
const handleConfirm = user => {
  const updatedUser = {
    ...user,
    consentGiven: true
  }

  // update user in API
  const url = getUrl.userUpdate()

  apiPutWithToken(url, updatedUser, () => {
    window.location.href = '/'
  })
}

/**
 * Logic for handling Consent modal
 * @param {UserDto} user
 * @param {Function} handleConfirmExt
 */
export const useConsent = (user, handleConfirmExt) => {
  const openModal = useModalContext()

  if (!user) {
    return
  }

  const modalProps = {
    title: '',
    content: <ModalContent />,
    onCancel: () => {},
    onConfirm: () => {
      handleConfirm(user)
      handleConfirmExt()
    }
  }

  const { consentGiven } = user

  if (consentGiven) {
    return
  }

  openModal(modalProps)
}
