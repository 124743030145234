import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useFiltersSortingAndPagination } from '../../hooks'
import { formatSearchParamsForApiCall } from '../../helpers/searchParams'
import {
  getlListConfig,
  EMPTY_GROUP_BY_VALUE,
  initialSortConfig
} from './helpers'
import { Toolbar, ToolbarDivider } from '../Common/Toolbar'
import { Search } from '../Common/Search'
import Filters, { GroupByPicker } from './Filters'
import {
  CreateBtn,
  // DetailsBtn,
  // EditBtn,
  FiltersBtn
} from './Ctas'
import GroupedList from './GroupedLists'
import ListWithPagination from './ListWithPagination'
// import { getCanEditIssue } from './Form/helpers'

import './list.scss'

// now in the API you can only change the status of a issue
// so no need for an edit button and page
// (but leaving it commented out for now in case it will change in the future)
// not displaying details button as well, as you can access the issue details by clicking on the issue title in the list
const ItemCtas = ({ item }) => (
  <>
    {/*<DetailsBtn id={item.id} />
    {getCanEditIssue(item) && <EditBtn id={item.id} />}*/}
  </>
)

const ListContainer = () => {
  const { t } = useTranslation()
  // To ensure the useFiltersSortingAndPagination hook works correctly,
  // totalPages should initially be set to null.
  const [totalPages, setTotalPages] = useState(null)
  const [query, setQuery] = useState('')
  const queryRef = useRef(query)

  const {
    searchParams,
    sortConfig,
    filters,
    handleFilters,
    handleSort,
    handlePrevClick,
    handleNextClick,
    handlePageNumberClick
  } = useFiltersSortingAndPagination({ groupBy: 'status' }, totalPages)

  const { page, sort, order, groupBy, ...otherFilters } = searchParams

  const [grouped, setGrouped] = useState(groupBy !== EMPTY_GROUP_BY_VALUE)

  const apiParams = formatSearchParamsForApiCall(
    { page, sort, order, ...otherFilters },
    grouped ? 10 : 20
  )

  useEffect(() => {
    // if groupBy property is also in filters, display one list
    setGrouped(groupBy !== EMPTY_GROUP_BY_VALUE && !filters[groupBy])
  }, [filters, groupBy])

  useEffect(() => {
    if (grouped) {
      setTotalPages(1)
    }
  }, [grouped])

  const handleGroupByChange = groupBy => {
    setGrouped(groupBy !== EMPTY_GROUP_BY_VALUE)
    handleFilters({
      ...otherFilters,
      groupBy,
      // reset sort config as grouped lists have their own sorting functionality
      sort: groupBy === EMPTY_GROUP_BY_VALUE ? initialSortConfig.sortBy : '',
      order: groupBy === EMPTY_GROUP_BY_VALUE ? initialSortConfig.order : ''
    })
  }

  const handleMoreClick = value => {
    setGrouped(false)

    handleFilters({
      ...otherFilters,
      [groupBy]: value, // eg. '?status=OPEN' or '?priority=BLOCKER'
      groupBy: EMPTY_GROUP_BY_VALUE
    })
  }

  const listConfig = getlListConfig(t, groupBy, otherFilters)
  const activeFiltersNum = Object.keys(otherFilters).filter(
    item => item !== 'query'
  ).length

  const handleSearch = () => {
    handleFilters({
      query
    })
  }

  const handleClearSearch = () => {
    setQuery('')
    handleFilters({ query: '' })
  }

  const handleSubmit = values => {
    handleFilters({ ...values, query })
  }

  useEffect(() => {
    const searchParamInQuery = otherFilters.query
    if (searchParamInQuery !== queryRef.current) {
      setQuery(searchParamInQuery || '')
      queryRef.current = searchParamInQuery
    }
  }, [otherFilters])

  return (
    <div className='issues-list'>
      <Toolbar>
        <FiltersBtn
          filtersForm={Filters}
          activeFiltersNum={activeFiltersNum}
          queryFilters={otherFilters}
          handleSubmit={handleSubmit}
        />

        <GroupByPicker
          value={groupBy}
          handleChange={handleGroupByChange}
          filters={otherFilters}
        />

        <Search
          query={query}
          onChange={setQuery}
          clearSearchButton
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
        />
        <ToolbarDivider />

        <CreateBtn t={t} />
      </Toolbar>

      <div className='mt-2'>
        {grouped ? (
          <GroupedList
            groupBy={groupBy}
            apiParams={apiParams}
            listConfig={listConfig}
            ctas={null}
            handleMoreClick={handleMoreClick}
          />
        ) : (
          <ListWithPagination
            apiParams={apiParams}
            listConfig={listConfig}
            ctas={null}
            filtersActive={activeFiltersNum > 0}
            sortConfig={sortConfig}
            handleSort={handleSort}
            handlePrevClick={handlePrevClick}
            handleNextClick={handleNextClick}
            setTotalPages={setTotalPages}
            handlePageNumberClick={handlePageNumberClick}
          />
        )}
      </div>
    </div>
  )
}

export default ListContainer
