import PropTypes from 'prop-types'

import { VfButton, colors } from '../DesignSystem/Buttons'

const DisabledButton = ({ ...props }) => (
  <VfButton color={colors.tertiary} disabled {...props} />
)

const ActiveButton = ({ onClick, ...props }) => (
  <VfButton type='submit' color={colors.primary} onClick={onClick} {...props} />
)

const SubmitButton = ({ disabled, onClick, ...props }) => {
  return disabled ? (
    <DisabledButton {...props} />
  ) : (
    <ActiveButton onClick={onClick} {...props} />
  )
}

SubmitButton.defaultProps = {
  disabled: false,
  onClick: () => {}
}

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
}

export default SubmitButton
