import React from 'react'
import { useTranslation } from 'react-i18next'
import './toasts.scss'

const toastsDefaultTitles = {
  success: 'Yay! Everything worked!',
  error: 'Uh oh, something went wrong...'
}

const Toast = ({ toast, handleClose }) => {
  const { t } = useTranslation()

  return (
    <div
      // className='vf-cookie-banner vf-cookie-banner--success'
      className={`toast toast-${toast.type}`}
      data-test='toast'>
      <div className='toast__title'>
        {toast.title ||
          t(
            `common.toasts.${toast.type}.title`,
            toastsDefaultTitles[toast.type]
          )}
      </div>

      <button className='toast__close-btn' onClick={handleClose}>
        &times;
      </button>
      {toast.text || t(`common.toasts.${toast.type}.message`)}
    </div>
  )
}

Toast.defaultProps = {
  toast: null,
  handleClose: () => {}
}

export default Toast
