import React from 'react'
// import { useSelector } from 'react-redux'

// import { selectUserName } from '../../store/selectors'
import { getEnvVariable } from '../../.env.custom'

const projectName = getEnvVariable('REACT_APP_PROJECT_NAME')

const productKeys = {
  bifrost: 'bifrostCode',
  vass: 'vass'
}

const FiveStar = () => {
  // const userName = useSelector(selectUserName)

  return (
    // !!userName && (
    <five-star
      product-key={productKeys[projectName]}
      // fingerprint={userName}
      mode='badge'
      delay={0}
    />
  )
  // )
}

export default FiveStar
