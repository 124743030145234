// @ts-check
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { cloneDeep, debounce, isArray, isEqual } from 'lodash'

import VfBanner from '../components/DesignSystem/Banner'

/**
 * Custom hook for storing form's data in the local storage.
 * @param {string} storeKey - The key to use when storing the form values in local storage
 * @param {Object} values - The current values of the form
 * @param {Object} initialValues - The initial values of the form
 * @param {Function} setValues - Formik's function to update the form values
 * @param {Function} resetForm - Formik's function to reset the form
 * @param {Object} options - Additional options object with confi configuring the form persistence
 * @param {Function} [options.formatValuesFromStorage] - A function to format the stored values before setting the form values. Needs to be as useCallback
 * @param {string} [options.message] - The message to display when recovered data is found
 * @param {string} [options.clearButtonText] - The text to display on the button to clear the form data
 * @param {number} [options.debounceTime] - The amount of time to debounce saving the form values
 * @param {boolean} [options.disablePersistence] - A flag to disable saving the form values to local storage (e.g. edit form)
 * @param {string[]} [options.omitValues] - An array of values to omit from the persisted form data. Needs to be memoized (useMemo)
 * @returns {Object} An object containing a clear function to clear the stored values and a RecoveredDataMessage component
 */
const useFormPersist = (
  storeKey,
  values,
  initialValues,
  setValues = () => {},
  resetForm = () => {},
  {
    formatValuesFromStorage = undefined,
    message = 'Your unsaved data has been recovered.',
    clearButtonText = 'Clear the form',
    debounceTime = 300,
    disablePersistence = false,
    omitValues = undefined
  }
) => {
  const [recoveredDataMessage, setRecoveredDataMessage] = useState('')

  const debouncedSave = useMemo(
    () =>
      debounce((storeKey, values) => {
        const valuesCopy = cloneDeep(values)

        if (isArray(omitValues)) {
          omitValues.forEach(value => {
            delete valuesCopy[value]
          })
        }

        window.localStorage.setItem(storeKey, JSON.stringify(valuesCopy))
      }, debounceTime),
    [debounceTime, omitValues]
  )

  const saveValues = useCallback(
    (storeKey, values) => debouncedSave(storeKey, values),
    [debouncedSave]
  )

  useEffect(() => {
    if (!disablePersistence && !!storeKey) {
      saveValues(storeKey, values)
    }
  }, [initialValues, disablePersistence, saveValues, storeKey, values])

  const retrieveStoredValues = useCallback(
    storeKey => {
      let storedValues = window.localStorage.getItem(storeKey)

      if (!!storedValues && typeof formatValuesFromStorage === 'function') {
        storedValues = formatValuesFromStorage(JSON.parse(storedValues))
      } else if (!!storedValues) {
        storedValues = JSON.parse(storedValues)
      }

      return storedValues
    },
    [formatValuesFromStorage]
  )

  useEffect(() => {
    if (!disablePersistence && !!storeKey) {
      let storedValues = retrieveStoredValues(storeKey)

      const initialValuesCopy = cloneDeep(initialValues)

      if (isArray(omitValues)) {
        omitValues.forEach(value => {
          delete initialValuesCopy[value]
        })
      }

      if (!!storedValues && !isEqual(storedValues, initialValuesCopy)) {
        setRecoveredDataMessage(message)
        setValues(storedValues)
      }
    }
  }, [
    message,
    disablePersistence,
    setValues,
    storeKey,
    initialValues,
    retrieveStoredValues,
    omitValues
  ])

  const handleClearForm = () => {
    clearStoredValues(storeKey)
    setRecoveredDataMessage('')
    resetForm()
  }

  const RecoveredDataMessage = () =>
    !disablePersistence && !!recoveredDataMessage ? (
      <VfBanner
        data-test='recovered-data-message'
        handleClose={() => setRecoveredDataMessage('')}
        style={{ marginBottom: '1rem' }}>
        {recoveredDataMessage}
        <button onClick={handleClearForm}>{clearButtonText}</button>
      </VfBanner>
    ) : null

  return { clear: clearStoredValues, RecoveredDataMessage }
}

export default useFormPersist

export const clearStoredValues = storeKey => {
  window.localStorage.removeItem(storeKey)
}
