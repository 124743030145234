import React from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-date-picker'
import { useField } from 'formik'

import Message, { messageTypes } from '../../Common/Message'
import './date-picker.scss'

export const DatePicker = ({
  name = '',
  label = '',
  value,
  handleChange = () => {},
  minDate,
  maxDate,
  minDetail = 'year',
  locale,
  readOnly = false,
  disabled = false,
  hasError = false
}) => (
  <>
    {label && (
      <label htmlFor={name} className='mt-2'>
        {label}
      </label>
    )}

    <div className='vf-input-container' data-test='date-picker'>
      <ReactDatePicker
        name={name}
        dayPlaceholder='dd'
        monthPlaceholder='mm'
        yearPlaceholder='yyyy'
        minDetail={minDetail}
        showLeadingZeros
        value={value}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        locale={locale}
        disableCalendar={disabled || readOnly}
        disabled={disabled || readOnly}
        className={
          hasError
            ? 'react-date-picker--error'
            : readOnly
            ? 'react-date-picker--readonly'
            : ''
        }
      />
    </div>
  </>
)

DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handleChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool
}

const DatePickerWithFormik = ({
  label,
  minDate,
  maxDate,
  locale = 'sv-SE',
  onChange = () => {},
  ...props
}) => {
  const [field, meta, helpers] = useField(props)

  const handleChange = value => {
    helpers.setValue(value)
    onChange(value || '')
  }

  const hasError = meta.touched && meta.error

  return (
    <div className='date-picker'>
      <DatePicker
        name={field.name}
        label={label}
        value={field.value}
        handleChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        locale={locale}
        hasError={hasError}
        {...props}
      />

      {hasError ? (
        <Message type={messageTypes.error}>{meta.error}</Message>
      ) : null}
    </div>
  )
}

export default DatePickerWithFormik
