import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getUrl } from '../../../apiUrls'
import { apiPostWithToken } from '../../../api'
import Form from './Form'
import { formModes } from '../../CommonForm'
import {
  handleSubmitFactory,
  callback,
  handleApiErrors
} from '../../../helpers/forms'
import {
  validationSchema,
  formatValuesForApi,
  sendInvitation,
  userRole
} from './helpers'
import { useToastContext, useSlidePanelContext } from '../../../hooks'
import { clearStoredValues } from '../../../hooks/useFormPersist'

const STORE_KEY = 'users_create'

const Create = ({ refreshList = () => {} }) => {
  const { t } = useTranslation()
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()

  const initialValues = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    isAdmin: false,
    customerIds: [],
    role: userRole.regular
  }

  const [apiErrors, setApiErrors] = useState(null)

  const successCallback = res => {
    closeSlidePanel()
    if (res.role === userRole.external) sendInvitation(res.email)
    refreshList()
    clearStoredValues(STORE_KEY)
  }

  const createUrl = getUrl.userCreate()

  const handleSubmit = handleSubmitFactory(
    createUrl,
    apiPostWithToken,
    callback(addToast, successCallback, handleApiErrors(setApiErrors)),
    formatValuesForApi
  )

  return (
    <Form
      initialValues={initialValues}
      validationSchema={() => validationSchema(t)}
      handleSubmit={handleSubmit}
      mode={formModes.create}
      storeKey={STORE_KEY}
      apiErrors={apiErrors}
    />
  )
}

export default Create
