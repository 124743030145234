import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Form from './Form'
import { validationSchema, formatValuesForApi } from './helpers'
import { formModes } from '../../CommonForm'
import {
  handleSubmitFactory,
  callback,
  handleApiErrors
} from '../../../helpers/forms'
import { getUrl } from '../../../apiUrls'
import { apiPostWithToken } from '../../../api'
import { useSlidePanelContext, useToastContext } from '../../../hooks'
import { clearStoredValues } from '../../../hooks/useFormPersist'

const STORE_KEY = 'customer-types_create'

const Create = ({ refreshList = () => {} }) => {
  const { t } = useTranslation()
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()

  const [apiErrors, setApiErrors] = useState(null)

  const initialValues = {
    name: '',
    active: true,
    contractTypeIds: []
  }

  const successCallback = () => {
    refreshList()
    closeSlidePanel()
    clearStoredValues(STORE_KEY)
  }

  const url = getUrl.customerTypeCreate()

  const handleSubmit = handleSubmitFactory(
    url,
    apiPostWithToken,
    callback(addToast, successCallback, handleApiErrors(setApiErrors)),
    formatValuesForApi
  )

  return (
    <Form
      initialValues={initialValues}
      validationSchema={() => validationSchema(t)}
      handleSubmit={handleSubmit}
      mode={formModes.create}
      storeKey={STORE_KEY}
      apiErrors={apiErrors}
    />
  )
}

export default Create
