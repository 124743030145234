// @ts-check

/**
 * Returns the date as a readable string based on user's locale (e.g. 19.02.2021)
 * @param {string} dateStr - The date string to be formatted
 * @param {string} [locales] - The locale to use for formatting the date string
 * @return {string} - The formatted date string with time, or '-' if no date string is provided
 */

export const getFormattedDate = (dateStr, locales = undefined) => {
  if (!!dateStr) {
    return new Date(dateStr).toLocaleDateString(locales)
  }

  return '-'
}

/**
 * Returns the date and time as a readable string based on user's locale (e.g. 19.02.2021 10:45:21)
 * @param {string} dateStr - The date string to be formatted
 * @return {string} - The formatted date string with time, or '-' if no date string is provided
 */

export const getFormattedDateWithTime = (dateStr, locales = '') => {
  if (!!dateStr) {
    if (!!locales) {
      return new Date(dateStr).toLocaleString(locales)
    }

    return new Date(dateStr).toLocaleString()
  }

  return '-'
}

/**
 * Returns the ISO local date of the provided date string.
 * @param {string} dateStr - A date string that can be parsed by the javascript Date object.
 * @return {string} - The ISO local date in the format of YYYY-MM-DD.
 */
export const getISOLocalDate = dateStr => {
  if (!!dateStr) {
    const date = new Date(dateStr)

    const year = String(date.getFullYear()).padStart(4, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  return ''
}

/**
 *
 * @param {Date} startDate - The starting date to calculate from
 * @param {Object} [options={days: 0, months: 0, years: 0}] - The number of days, months, and years to add to the start date
 * @param {number} [options.days=0] - The number of days to add to the start date
 * @param {number} [options.months=0] - The number of months to add to the start date
 * @param {number} [options.years=0] - The number of years to add to the start date
 * @returns {Date} - The new date calculated from the start date
 */
export const addToDate = (
  startDate,
  { days = 0, months = 0, years = 0 } = {}
) => {
  if (startDate instanceof Date) {
    return new Date(
      new Date(startDate).getFullYear() + years,
      new Date(startDate).getMonth() + months,
      new Date(startDate).getDate() + days
    )
  }
}
/**
 * Returns the current date with hours, minutes, seconds, and milliseconds set to 0.
 * @return {Date} The current date with time set to 00:00:00.
 */
export const getTodayDate = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return today
}
