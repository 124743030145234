// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'

import Message, { messageTypes } from '../../Common/Message'
import LengthCounter from '../../Common/LengthCounter/LengthCounter'

import './text-area.scss'

export const TextArea = ({ rows = 8, hasError = false, ...props }) => (
  <div className='vf-input-container'>
    <textarea
      className={`vf-input w-100 ${hasError ? 'vf-input--error' : ''}`}
      rows={rows}
      {...props}
    />
  </div>
)

export const BasicTextAreaWithLabel = ({ label = '', ...props }) => {
  const { name: labelName, maxLength } = props

  return (
    <div className='textarea__container'>
      {!!label && (
        <label htmlFor={labelName} className='mt-2'>
          {label}
        </label>
      )}

      <TextArea {...props} />

      {!!maxLength && (
        <LengthCounter
          maxLength={maxLength}
          currentLength={props.value?.length ?? 0}
          cssClass='textarea__context'
        />
      )}
    </div>
  )
}

BasicTextAreaWithLabel.propTypes = {
  labelText: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number
}

export const FormikTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const { name } = field
  const { error, touched } = meta

  const { validateField } = useFormikContext()

  const validateThisField = () => {
    validateField(name)
  }

  const hasError = touched && !!error

  return (
    <>
      <BasicTextAreaWithLabel
        {...field}
        {...props}
        label={label}
        hasError={hasError}
        onBlur={validateThisField}
      />

      {hasError && <Message type={messageTypes.error}>{error}</Message>}
    </>
  )
}

FormikTextArea.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  form: PropTypes.object,
  rows: PropTypes.number,
  maxLength: PropTypes.number
}

export default FormikTextArea
