import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import rightChevronIcon from '../../assets/icons/VF_right_rgb.svg'
import getCrumbs from '../../helpers/breadcrumbs'
import routes from '../../routes'
import './breadcrumbs.scss'

export const Breadcrumb = ({ text, link, t, ...props }) => (
  <>
    {link ? (
      <>
        <Link to={link} {...props}>
          <span data-test='breadcrumb--link'>{t(text)}</span>
        </Link>

        <span className='breadcrumbs__separator'>
          <img src={rightChevronIcon} alt='' />
        </span>
      </>
    ) : (
      <span data-test='breadcrumb'>{t(text)}</span>
    )}
  </>
)

const Breadcrumbs = () => {
  const location = useLocation()
  const items = getCrumbs(routes, location)
  const { t } = useTranslation()

  if (items.length <= 1) {
    return null
  }

  return (
    <div className='breadcrumbs__container' data-test='breadcrumbs'>
      {items.map(({ path, nav: { title } }, idx) =>
        idx + 1 === items.length ? (
          <Breadcrumb key={idx} text={title} t={t} />
        ) : (
          <Breadcrumb key={idx} text={title} link={path} t={t} />
        )
      )}
    </div>
  )
}

export default Breadcrumbs
