/**
 * test the solution
 * in VCS terminal run:
 * windows: ($env:REACT_APP_CUSTOM_ENV = "acc") -and ($env:RREACT_APP_PROJECT_NAME="vass") -and (npm start)
 * mac: export REACT_APP_CUSTOM_ENV="dev" && export REACT_APP_PROJECT_NAME='vass' && npm start
 *
 * remember
 * to set in azure-pipelines.yml values for:
 * - REACT_APP_CUSTOM_ENV
 * - REACT_APP_PROJECT_NAME
 */

// @ts-check

const envVariables = {
  dev: {
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      '4a387a14-9704-42ca-9aab-e68e8e61883d',
    REACT_APP_API_ROOT_URL:
      'https://bast-kund-web-api-dev.azurewebsites.net/api/v1/'
  },
  tst: {
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      '274281a1-0acc-49f8-a95e-d4412f197142',
    REACT_APP_API_ROOT_URL:
      'https://bast-kund-web-api-tst.azurewebsites.net/api/v1/'
  },
  prd: {
    REACT_APP_INSIGHTS_INSTRUMENTATION_KEY:
      '6db04959-03bb-4fdd-a2f1-22d9ee077295',
    REACT_APP_API_ROOT_URL:
      'https://bast-kund-web-api-prd.azurewebsites.net/api/v1/'
  }
}

export const getEnvVariable = variable => {
  const customEnv = process.env.REACT_APP_CUSTOM_ENV

  try {
    return !!customEnv
      ? envVariables[customEnv][variable]
      : process.env[variable]
  } catch (err) {
    console.error(
      'Cannot get requested ENV VARIABLE, env:',
      customEnv,
      'var:',
      variable
    )

    return ''
  }
}
