import { useTranslation } from 'react-i18next'

import { ReactSelect } from '../../CommonForm'
import { getGroupByOptions } from '../helpers'
import { reactSelectSizes } from '../../CommonForm/ReactSelect/ReactSelectStyles'
import { sortByParam } from '../../../helpers/lists'

const GroupByStatusCheckbox = ({
  value,
  handleChange = () => {},
  filters,
  disabled
}) => {
  const { t } = useTranslation()

  const options = getGroupByOptions(t, filters)

  return (
    <div className='issues-list__group-by'>
      <ReactSelect
        name='groupBy'
        label={t('issues.form.labels.groupBy')}
        value={value}
        options={options}
        disabled={disabled}
        size={reactSelectSizes.small}
        fullWidth={false}
        onChange={item => handleChange(item.value)}
      />
    </div>
  )
}

export default GroupByStatusCheckbox
