import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Header, Navigation, Breadcrumbs } from './'
import { NavigationContext } from './NavigationContext'
import './template.scss'

const Template = ({ children, withCustomBreadcrumbs = false }) => {
  const { t } = useTranslation()
  const { smallerNav } = useContext(NavigationContext)

  document.title = `${t('projectName')} | Vattenfall`

  return (
    <>
      <Header />
      <Navigation />
      <div
        className={`app-container ${
          smallerNav ? 'app-container--less-padding' : ''
        }`}>
        {!withCustomBreadcrumbs && <Breadcrumbs />}

        {children}
      </div>
    </>
  )
}

export default Template
