// @ts-check

/**
 * @param {string} searchParamName The name of the query parameter.
 * @param {string} value The value of the query parameter.
 * @returns {boolean} True if the input value is a valid date string and the query parameter name includes the word "date", false otherwise.
 */
export const isSearchParamADate = (searchParamName, value) => {
  const timestamp = Date.parse(value)

  return /date/i.test(searchParamName) && !isNaN(timestamp)
}

/**
 * @param {string} dateStr The date string to convert.
 * @returns {string} The date string in ISO 8601 format.
 */
export const convertDateForApi = dateStr => new Date(dateStr).toISOString()

/**
 * @param {Object} searchParams - The search parameters.
 * @param {number} searchParams.page - The page number.
 * @param {string} searchParams.sort - The sort column.
 * @param {string} searchParams.order - The sort order.
 * @param {Object} searchParams.filters - The filters.
 * @param {number} [size=10] -  The number of items per page. Default is 10.
 * @returns {Object} The query parameters in a format suitable for an API call.
 */
export const formatSearchParamsForApiCall = (searchParams, size = 10) => {
  const { page, sort, order, ...filters } = searchParams

  let paramsForApiCall = { size }

  if (!!page && page > 1) {
    paramsForApiCall.page = page - 1 // pagination in UI starts with 1
  } else {
    paramsForApiCall.page = 0
  }

  if (!!sort) {
    paramsForApiCall.sort = sort + ',' + (order?.toUpperCase() ?? 'ASC')
  }

  Object.keys(filters).forEach(key => {
    if (isSearchParamADate(key, filters[key])) {
      paramsForApiCall[key] = convertDateForApi(filters[key])
    } else {
      paramsForApiCall[key] = filters[key]
    }
  })

  return paramsForApiCall
}

/**
 * Updates form filter fields with values from query parameters. If there is no matching query parameter, sets the field value to the initial value.
 * @param {Object} initialValues - The initial values of the form fields.
 * @param {Object} queryFilters - The query parameters containing the new values for the form fields.
 * @param {Function} setFieldValue - The Formik's function.
 */
export const updateFilterFieldsWithQueryParams = (
  initialValues,
  queryFilters,
  setFieldValue
) => {
  for (const [key, value] of Object.entries(initialValues)) {
    const queryValue = queryFilters[key]

    if (queryValue !== undefined) {
      const fieldValue = isSearchParamADate(key, queryValue)
        ? new Date(queryValue)
        : queryValue
      setFieldValue(key, fieldValue)
    } else {
      setFieldValue(key, value)
    }
  }
}
