import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChartPie } from '../../Common/Charts'
import getNotification from '../../../helpers/apiNotifications'
import Loader from '../../Common/Loader/Loader'
import { sizes } from '../../Common/Charts/Pie/helpers'
import { useData } from '../../../hooks'
import { getUrl } from '../../../apiUrls'
import { apiIssuesByTypeToUI } from './helpers'

const Chart = () => {
  const { t } = useTranslation()
  const title = t('issues.list.headers.issueType')
  const url = getUrl.chartType()
  const { items, loading, error } = useData(url)
  const Info = getNotification(loading, error, items, Loader)

  if (!!Info) {
    return Info
  }

  const data = apiIssuesByTypeToUI(items)

  return <ChartPie data={data} title={title} size={sizes.large} />
}

export default Chart
