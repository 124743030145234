import { useTranslation } from 'react-i18next'
import { Dashboard } from '../../components/Dashboard'
import { PageTitle } from '../../components/Template'

const Page = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t('titles.dashboard')}</PageTitle>

      <Dashboard />
    </>
  )
}

export default Page
