import React from 'react'

import { MultiSelect, getFormattedOptions } from '../CommonForm'
import { getUserFullName } from '../../helpers/strings'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import { sortByParam } from '../../helpers/lists'

const OptionWrapper = ({ option }) => (
  <>
    <div>{option.label}</div>
    <div
      style={{
        fontSize: '12px',
        lineHeight: 1.2
      }}>
      {option.email}
    </div>
  </>
)

const UsersPicker = ({
  name = 'userIds',
  label,
  readOnly = false,
  disabled = false
}) => {
  const usersUrl = getUrl.users()
  const { items: users, loading } = useData(usersUrl)
  const usersSorted = sortByParam(users, 'asc', 'lastName')
  const usersOptions = getFormattedOptions(usersSorted || [], {
    formatLabelFn: getUserFullName,
    additionalPropsFn: item => ({
      email: item.email
    })
  })

  return (
    <MultiSelect
      name={name}
      label={label}
      options={usersOptions}
      readOnly={readOnly}
      isMulti
      disabled={disabled}
      loading={loading}
      optionWrapper={OptionWrapper}
    />
  )
}

export default UsersPicker
