import React from 'react'

import { Select, getFormattedOptions } from '../CommonForm'
import { sortByParam } from '../../helpers/lists'
import { priorities } from '../Issues/Details/helpers'

const IssuePriorityPicker = ({
  name = 'priority',
  label,
  isClearable = false,
  readOnly = false,
  disabled = false,
  ...props
}) => {
  const sortedPriorities = sortByParam(priorities, 'asc', 'order')
  const priorityOptions = getFormattedOptions(sortedPriorities, {
    labelProp: 'label',
    valueProp: 'value'
  })

  return (
    <Select
      name={name}
      label={label}
      options={priorityOptions}
      readOnly={readOnly}
      disabled={disabled}
      isClearable={isClearable}
      {...props}
    />
  )
}

export default IssuePriorityPicker
