// @ts-check
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useData } from '../../../../hooks'
import getNotification from '../../../../helpers/apiNotifications'
import { getUrl } from '../../../../apiUrls'

/** @typedef {import('../../../../types').DtoPagingWrapperIssueDto} DtoPagingWrapperIssueDto */

const DataSection = ({ data, label }) => {
  return (
    <p>
      <span className='dashboard-container__label'>{label}</span>
      <br />
      <span className='dashboard-container__value'>{data}</span>
    </p>
  )
}

export default DataSection

export const TotalItems = () => {
  const { t } = useTranslation()

  const url = getUrl.issues(null, { size: 1 })
  /** @type {{loading: boolean, items: DtoPagingWrapperIssueDto, error: boolean}  } */
  const { loading, items: data, error } = useData(url)
  const Info = getNotification(loading, error, data)

  if (!!Info) {
    return Info
  }

  const { totalItems } = data

  return (
    <DataSection data={totalItems} label={t('dashboard.labels.totalIssues')} />
  )
}
