import React from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from '../CommonForm'
import { issueStatuses, getStatusLabel } from '../Issues/helpers'
import { sortByParam } from '../../helpers/lists'

const IssueStatusPicker = ({
  name = 'status',
  label,
  readOnly = false,
  disabled = false,
  isClearable = false,
  ...props
}) => {
  const { t } = useTranslation()

  const issuePrioritiesOptions = Object.keys(issueStatuses).map(key => ({
    label: getStatusLabel(issueStatuses[key], t),
    value: issueStatuses[key]
  }))

  const issuePrioritiesOptionsSorted = sortByParam(
    issuePrioritiesOptions,
    'asc',
    'label'
  )

  return (
    <Select
      name={name}
      label={label}
      options={issuePrioritiesOptionsSorted}
      readOnly={readOnly}
      disabled={disabled}
      isClearable={isClearable}
      {...props}
    />
  )
}

export default IssueStatusPicker
