// @ts-check
import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CloseIcon } from '../../../assets/icons/VF_close_rgb.svg'

import './modal.scss'

const Modal = ({
  hidden = true,
  fullScreen = false,
  closeModal = () => {},
  children = <></>
}) =>
  !hidden ? (
    <div className='modal' onClick={e => e.stopPropagation()}>
      <div
        className='modal__background'
        style={{
          display: hidden ? 'none' : 'block'
        }}
      />

      <div
        className={`modal__container ${
          fullScreen ? 'modal__container--full-screen' : ''
        }`}
        style={{
          display: hidden ? 'none' : 'block'
        }}
        data-test='modal'>
        <button onClick={closeModal} className='modal__close-button'>
          <CloseIcon width={16} />
        </button>

        <div>{children}</div>
      </div>
    </div>
  ) : null

Modal.propTypes = {
  hidden: PropTypes.bool,
  fullScreen: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.node,
  closeBtn: PropTypes.bool
}

export default Modal
