// @ts-check
export const fileTypes = {
  jpg: 'jpg',
  png: 'png',
  txt: 'txt',
  pdf: 'pdf',
  doc: 'doc',
  xls: 'xls',
  ppt: 'ppt',
  bmp: 'bmp',
  one: 'one',
  pub: 'pub',
  vsdx: 'vsdx'
}

const fileExtensions = {
  [fileTypes.jpg]: { 'image/jpeg': ['.jpeg', '.jpg'] },
  [fileTypes.png]: { 'image/png': ['.png'] },
  [fileTypes.txt]: { 'text/plain': ['.txt'] },
  [fileTypes.pdf]: { 'application/pdf': ['.pdf'] },
  [fileTypes.doc]: {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx'
    ]
  },
  [fileTypes.xls]: {
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx'
    ]
  },
  [fileTypes.ppt]: {
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      ['.pptx']
  },
  [fileTypes.bmp]: { 'image/bmp': ['.bmp'] },
  [fileTypes.one]: { 'application/onenote': ['.one'] },
  [fileTypes.pub]: { 'application/x-mspublisher': ['.pub'] },
  [fileTypes.vsdx]: { 'application/vnd.ms-visio.drawing': ['.vsdx'] }
}

/**
 * Formats a list of file types into a map of accepted file types with their corresponding file extensions.
 * @param {string[]} fileTypes - The list of file types to format. Eg. ['jpg', 'png']
 * @returns {{}} The map of accepted file types with their corresponding file extensions. Eg. { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] }
 */
export const formatAcceptedFileTypes = fileTypes =>
  fileTypes.reduce((acc, fileType) => {
    if (fileExtensions[fileType]) {
      Object.entries(fileExtensions[fileType]).forEach(
        ([mimeType, extensions]) => {
          acc[mimeType] = [...(acc[mimeType] || []), ...extensions]
        }
      )
    }

    return acc
  }, {})

/**
 * @param {string[]} fileTypes - The list of file types to format. Eg. ['jpg', 'png']
 * @returns {string[]} All file extensions, e.g.: [xls, xlsx].
 */
export const getAllAcceptedExtensions = fileTypes => {
  const acceptedFileTypes = formatAcceptedFileTypes(fileTypes)

  return Object.values(acceptedFileTypes).flat()
}

/**
 * Converts a given file size in bytes to a human-readable format.
 * @param {number} sizeInBytes - The file size to format, in bytes.
 * @returns {string} A human-readable string representing the file size, in either kilobytes or megabytes.
 */
export const formatFileSize = sizeInBytes => {
  if (sizeInBytes < 1000000) {
    return (sizeInBytes / 1000).toFixed(2) + ' kB'
  } else {
    return (sizeInBytes / 1000000).toFixed(2) + ' MB'
  }
}

/**
 * Extracts the file extension from the given file object's `fileName` property.
 * @param {{fileName: string}} file - The file object containing the `fileName` property to extract the file extension from.
 * @returns {string} The file extension of the given file, including the period character.
 */
export const getFileType = file => file.fileName.split('.').pop() ?? ''

/**
 * Returns the CSS class name for the given file object.
 * @param {{fileName: string}} file - The file object to return the CSS class name for.
 * @returns {string} The CSS class name for the given file object.
 */
export const getFileItemClassName = file => {
  const fileType = getFileType(file).toLowerCase()

  const unSupportedFileTypes = ['png', 'jpg', 'jpeg']

  if (unSupportedFileTypes.includes(fileType)) {
    return 'vf-file-list__link--type-download'
  }

  return `vf-file-list__link--type-${fileType.substring(0, 3)}` // eg. there is class for doc type, but not for docx
}
