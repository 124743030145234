import React from 'react'

import { Select, getFormattedOptions } from '../CommonForm'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import { sortByParam } from '../../helpers/lists'

const CustomerTypePicker = ({
  name = 'customerTypeId',
  label,
  readOnly = false,
  disabled = false,
  selectedValue,
  ...props
}) => {
  const customerTypesUrl = getUrl.customerTypes()
  const { items: customerTypes, loading } = useData(customerTypesUrl)
  const customerTypesSorted = sortByParam(customerTypes, 'asc', 'name')
  const filteredCustomerTypes = customerTypesSorted?.filter(
    item => item.active || item.id === selectedValue // for edit/details when customer type is inactive but is already assigned to the item
  )

  const customerTypesOptions = getFormattedOptions(
    filteredCustomerTypes || [],
    {
      additionalPropsFn: item => ({
        disabled: !item.active
      })
    }
  )

  return (
    <Select
      name={name}
      label={label}
      options={customerTypesOptions}
      readOnly={readOnly}
      disabled={disabled}
      loading={loading}
      {...props}
    />
  )
}

export default CustomerTypePicker
