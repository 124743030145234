import { createContext, useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { SlidePanel, directions } from '../components/Common/SlidePanel'

const SlidePanelContext = createContext()

export default SlidePanelContext

const defaultSlidePanelProps = {
  title: '',
  intro: '',
  ctas: <></>,
  direction: directions.left,
  size: 25,
  className: '',
  content: () => <></>,
  onClose: () => {}
}

export const SlidePanelContextProvider = ({ children }) => {
  const location = useLocation()

  const [initialLocation, setInitialLocation] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [slidePanelProps, setSlidePanelProps] = useState(defaultSlidePanelProps)

  const openSlidePanel = useCallback(
    options => {
      setSlidePanelProps({
        ...defaultSlidePanelProps,
        ...options
      })

      setIsVisible(true)
      setInitialLocation(location.pathname)
    },
    [location.pathname]
  )

  const handleClose = useCallback(() => {
    slidePanelProps.onClose()
    setIsVisible(false)
    setSlidePanelProps(defaultSlidePanelProps)
    setInitialLocation(null)
  }, [slidePanelProps])

  // close slide panel on route change
  useEffect(() => {
    if (location.pathname !== initialLocation) {
      handleClose()
    }
  }, [location, handleClose, initialLocation])

  const {
    title,
    intro,
    ctas,
    direction,
    size,
    className,
    content: Content
  } = slidePanelProps

  return (
    <SlidePanelContext.Provider
      value={{ openSlidePanel, closeSlidePanel: handleClose }}>
      {children}

      <SlidePanel
        show={isVisible}
        title={title}
        intro={intro}
        ctas={ctas}
        direction={direction}
        size={size}
        className={className}
        handleClose={handleClose}>
        <Content />
      </SlidePanel>
    </SlidePanelContext.Provider>
  )
}
