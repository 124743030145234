import React, { createContext, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../components/DesignSystem/Modal'
import ActionButton, { colors } from '../components/Common/ActionButton'

const ModalContext = createContext()

export default ModalContext

export const ConfirmModalContextProvider = ({ children }) => {
  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState(false)
  const [modalProps, setModalProps] = useState({
    title: 'Are you sure?',
    content: <></>,
    onCancel: () => {},
    onConfirm: () => {},
    cancelButtonText: t('buttons.cancel'),
    confirmButtonText: t('buttons.confirm')
  })

  const toggleModal = () => setIsVisible(!isVisible)

  const showModal = useCallback(options => {
    setModalProps(prev => ({
      ...prev,
      ...options
    }))
    setIsVisible(true)
  }, [])

  return (
    <ModalContext.Provider value={showModal}>
      {children}

      <Modal hidden={!isVisible} closeModal={toggleModal}>
        {!!modalProps.title && (
          <div className='modal__title'>{modalProps.title}</div>
        )}

        {modalProps.content}

        <div className='modal__ctas'>
          <ActionButton
            onClick={() => {
              modalProps.onCancel()
              toggleModal()
            }}
            data-test='modal-cancel'>
            {modalProps.cancelButtonText}
          </ActionButton>

          <ActionButton
            color={colors.blue}
            onClick={() => {
              modalProps.onConfirm()
              toggleModal()
            }}
            data-test='modal-confirm'>
            {modalProps.confirmButtonText}
          </ActionButton>
        </div>
      </Modal>
    </ModalContext.Provider>
  )
}
