import { ReactComponent as DownIcon } from '../../../assets/icons/VF_down_rgb.svg'
import { ReactComponent as UpIcon } from '../../../assets/icons/VF_up_rgb.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'

export const priorityMap = {
  blocker: {
    order: 1,
    label: 'FARA FÖR LIV',
    text: 'Fara för Liv',
    value: 'BLOCKER',
    color: '#f93b18',
    icon: WarningIcon
  },
  high: {
    order: 2,
    label: 'HÖG',
    text: 'Hög',
    value: 'HOG',
    color: '#f5a623',
    icon: UpIcon
  },
  low: {
    order: 3,
    label: 'LÅG',
    text: 'Låg',
    value: 'LAG',
    color: '#3dc07c',
    icon: DownIcon
  }
}

export const priorities = Object.values(priorityMap)

export const getPriority = priorityFromApi =>
  Object.values(priorityMap).find(item => item.value === priorityFromApi)

export const priorityEnums = Object.values(priorityMap).map(item => item.value)

/**
 * @param {{audit: {createdDate: string}, importedCreateDate: string}} param
 * @returns {string} Return importedCreateDate otherwise createdDate
 */
export const getCreateDate = item =>
  item?.importedCreateDate || item?.audit?.createdDate || ''
