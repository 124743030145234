import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BlockBtn, buttonSizes } from '../../Common/Ctas'
import { useModalContext, useToastContext } from '../../../hooks'
import { getUrl } from '../../../apiUrls'
import { apiPutWithToken } from '../../../api'
import { callback } from '../../../helpers/forms'

const InactivateIssueTypeBtn = ({
  item,
  refreshList = () => {},
  size = buttonSizes.small
}) => {
  const { t } = useTranslation()
  const openModal = useModalContext()
  const { addToast } = useToastContext()

  const [inProgress, setInProgress] = useState(false)

  const updateUrl = getUrl.issueTypeUpdate()

  const successCallback = () => {
    refreshList()
    setInProgress(false)
  }

  const handleBlock = () => {
    setInProgress(true)

    const updatedItem = {
      ...item,
      customerTypeIds: item.customerTypes.map(item => item.id),
      active: false
    }

    apiPutWithToken(updateUrl, updatedItem, callback(addToast, successCallback))
  }

  const modalTitle = t('issueTypes.form.titles.inactivate', {
    name: item.name
  })

  return (
    <BlockBtn
      active={!inProgress}
      size={size}
      text={t('buttons.inactivate')}
      onClick={() =>
        openModal({
          title: modalTitle,
          onConfirm: handleBlock
        })
      }
    />
  )
}

export default InactivateIssueTypeBtn
