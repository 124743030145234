import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FiltersBtn, buttonSizes } from '../../Common/Ctas'
import { colors } from '../../Common/ActionButton'
import { SlidePanel } from '../../Common/SlidePanel'

const FilterIssuesBtn = ({
  filtersForm: FiltersForm,
  activeFiltersNum = 0,
  ...props
}) => {
  const { t } = useTranslation()

  const [isPanelVisible, setIsPanelVisible] = useState()

  let buttonText = t('buttons.filters')

  if (activeFiltersNum > 0) {
    buttonText += ` [${activeFiltersNum}]`
  }

  return (
    <>
      <FiltersBtn
        active
        text={buttonText}
        size={buttonSizes.medium}
        color={activeFiltersNum > 0 && colors.gray}
        onClick={() => setIsPanelVisible(true)}
        data-test='issues-filters-btn'
      />

      {/* using a SlidePanel component instead of the useSlidePanelContext 
      because filters in the context are not updated when the location changes */}
      <SlidePanel
        show={isPanelVisible}
        title={t('titles.filters')}
        size={25}
        handleClose={() => setIsPanelVisible(false)}>
        <FiltersForm {...props} />
      </SlidePanel>
    </>
  )
}

export default FilterIssuesBtn
