import React from 'react'
import { useField } from 'formik'

import Message, { messageTypes } from '../../Common/Message'
import './checkbox.scss'

export const Checkbox = ({ name = '', value, hasError = false, ...props }) => (
  <input
    id={name}
    type='checkbox'
    checked={value}
    {...props}
    style={{ pointerEvents: props.readOnly ? 'none' : 'auto' }}
    className={hasError ? 'checkbox__error' : ''}
  />
)

export const CheckboxWithLabel = ({
  label,
  noMargin = false,
  name,
  ...props
}) => (
  <label
    htmlFor={name}
    className={`checkbox__container ${noMargin ? '' : 'mt-2'}`}
    style={{ pointerEvents: props.readOnly ? 'none' : 'auto' }}>
    <Checkbox name={name} {...props} />
    {label}
  </label>
)

const CheckboxWithFormik = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  const hasError = meta.touched && meta.error

  return (
    <>
      {!!label ? (
        <CheckboxWithLabel
          label={label}
          {...field}
          {...props}
          hasError={hasError}
        />
      ) : (
        <Checkbox {...field} {...props} hasError={hasError} />
      )}

      {hasError && <Message type={messageTypes.error}>{meta.error}</Message>}
    </>
  )
}

export default CheckboxWithFormik
