import React from 'react'

import { getFormattedOptions, MultiSelect, Select } from '../CommonForm'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import { sortByParam } from '../../helpers/lists'

export const CustomerPicker = ({
  name = 'customerId',
  label,
  options = [],
  isClearable = false,
  withReactSelect = false,
  readOnly = false,
  disabled = false,
  isMultiSelect = false,
  loading = false,
  ...props
}) => {
  return (
    <>
      {isMultiSelect ? (
        <MultiSelect
          name={name}
          label={label}
          options={options}
          readOnly={readOnly}
          disabled={disabled}
          loading={loading}
          {...props}
        />
      ) : (
        <Select
          name={name}
          label={label}
          options={options}
          readOnly={readOnly}
          disabled={disabled}
          isClearable={isClearable}
          loading={loading}
          {...props}
        />
      )}
    </>
  )
}

const CustomerPickerContainer = ({ ...props }) => {
  const customersUrl = getUrl.customers()
  const { items: customers, loading } = useData(customersUrl)
  const customersSorted = sortByParam(customers, 'asc', 'name')
  const customersOptions = getFormattedOptions(customersSorted || [])

  return (
    <CustomerPicker options={customersOptions} loading={loading} {...props} />
  )
}

export default CustomerPickerContainer
