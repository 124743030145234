// @ts-check
import { useEffect, useState } from 'react'
import callApi, { fetchAll } from '../api'

/**
 * When using url as Array of strings, the array needs to be momoized:
 * urls = React.useMemo(() => ['url1', 'url2'], [])
 * otherwise infinite loop occurs.
 * @param {string | string[]} url
 * @param {boolean} refresh
 * @returns {{ loading: boolean, items: any, error: boolean, errorMessage: string }}
 * @example
 * const { loading, items, error, errorMessage } = useData('url')
 */
const useData = (url = '', refresh = false) => {
  const [state, setState] = useState({
    loading: false,
    items: null,
    error: false,
    errorMessage: null
  })

  const callback = res => {
    if (Array.isArray(res) && res.find(item => !!item.error)) {
      setState({
        error: true
      })
      return
    }

    if (!!res.error) {
      console.error('Error: ', res.error)

      setState({
        error: true,
        errorMessage: res.message,
        errorStatus: res.status
      })
      return
    }

    setState({
      loading: false,
      items: res
    })
  }

  useEffect(() => {
    if (!!url) {
      setState({ loading: true })

      if (Array.isArray(url)) {
        fetchAll(url, callback)
      } else {
        callApi(url, callback)
      }
    }
  }, [setState, refresh, url])

  return state
}

export default useData
