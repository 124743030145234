import { useState } from 'react'

import { useData, useToastContext, useSlidePanelContext } from '../../hooks'
import { getUrl } from '../../apiUrls'
import Form from './Form/Form'
import { formModes, getFormattedOptions } from '../CommonForm'
import getNotification from '../../helpers/apiNotifications'
import Loader from '../Common/Loader'
import { formatItemForForm, sendInvitation, userRole } from './Form/helpers'
import ResendInvitationButton from './Form/ResendInvitationButton'
import { callback } from '../../helpers/forms'

const Details = ({ id }) => {
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()
  const url = getUrl.userById(id)
  const { loading, items: data, error } = useData(url)

  const [inProgress, setInProgess] = useState(false)

  const customersUrl = getUrl.customers()
  const { items: customers } = useData(customersUrl)
  const customersOptions = getFormattedOptions(customers || [])

  const initialValues = formatItemForForm(data, customersOptions)

  const Info = getNotification(loading, error, data, Loader)

  const resendInvitation = () => {
    setInProgess(true)
    sendInvitation(
      initialValues.email,
      callback(
        addToast,
        () => {
          closeSlidePanel()
          setInProgess(false)
        },
        () => setInProgess(false)
      )
    )
  }

  return Info ? (
    Info
  ) : (
    <>
      <Form initialValues={initialValues} mode={formModes.details} readOnly />

      {initialValues.role === userRole.external && (
        <ResendInvitationButton
          disabled={inProgress}
          onClick={resendInvitation}
        />
      )}
    </>
  )
}

export default Details
