import { CreateBtn, buttonSizes } from '../../Common/Ctas'
import routesUrls from '../../../routesUrls'

const CreateIssueBtn = ({ t }) => (
  <CreateBtn
    active
    size={buttonSizes.medium}
    path={routesUrls.issues.create}
    text={t('issues.buttons.create')}
  />
)

export default CreateIssueBtn
