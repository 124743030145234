import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getUrl } from '../apiUrls'
import { tokenToAccount, setExtAccount } from '../AuthNonAdUsers/helpers'
import { Error } from '../helpers/apiNotifications'

/**
 * This is landing page from external user email with magic link.
 * Main task is to replace magic link hash for token and save it into local storage.
 * @returns null
 */
const Page = () => {
  const [res, setRes] = useState(null)
  const [content, setContent] = useState(null)
  let { magicLink } = useParams()

  // get response with Access Token
  useEffect(() => {
    // no need to fetch data if already taken
    if (!!res) {
      return
    }

    if (!!magicLink) {
      const url = getUrl.authAuthentication(magicLink)

      fetch(url)
        .then(response => response.json())
        .then(json => {
          if (!!json.accessToken) {
            setRes(json)
          } else {
            setContent(json.message)
          }
        })
    }
  }, [magicLink, res])

  // place token within Local Storage and navigate away
  useEffect(() => {
    if (!!res) {
      const account = tokenToAccount(res.accessToken)

      setExtAccount(account)
      window.location.href = '/'
    }
  }, [res])

  if (!content) {
    return null
  }

  return (
    <Error
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
      }}>
      {content}
    </Error>
  )
}

export default Page
