// @ts-check
import { getUrl } from '../../../apiUrls'
import {
  issueStatuses,
  getStatusColor,
  getStatusLabel
} from '../../Issues/helpers'
import { priorityMap } from '../../../components/Issues/Details/helpers'
import routesUrls from '../../../routesUrls'
import { getTypeColorById } from './typesConfig'

/**
 * @typedef {import("../../Common/Charts/types").ChartPieProps} ChartPieProps
 * @typedef {import("../../../types").DtoPagingWrapperIssueDto} DtoPagingWrapperIssueDto
 * @typedef {import("../../../types").IssueTypeDto} IssueTypeDto
 */

const listPageUrl = routesUrls.issues.list
export const emptyItem = {
  name: 'no data',
  value: 1,
  fill: '#ccc',
  listUrl: ''
}

/**
 * Get a random color from a predefined array of colors, ensuring that it is different from the previous call
 * @returns {string} color
 */
const getRandomColor = () => {
  const colors = [
    '#2DA55D',
    '#4FCC51',
    '#81E0A8',
    '#375E4E',
    '#E88A74',
    '#D85067',
    '#A376CC',
    '#3DC07C',
    '#E88A74'
  ]
  let lastColor = getRandomColor.lastColor
  let randomIndex = Math.floor(Math.random() * colors.length)
  while (colors[randomIndex] === lastColor) {
    randomIndex = Math.floor(Math.random() * colors.length)
  }
  getRandomColor.lastColor = colors[randomIndex]
  return colors[randomIndex]
}

// #region Status

/**
 * @param {string} status
 * @returns {string} url
 */
const getStatusUrl = status =>
  getUrl.issues(null, { status: status.toUpperCase() })

/**
 * @returns {string[]} urls
 */
export const getStatusesUrls = () =>
  Object.values(issueStatuses).map(getStatusUrl)

/**
 * @param {{status: string, totalItems: number}} apiDataItem
 * @param {Function} t
 * @returns {ChartPieProps}
 */
const apiIssueByStatusToUI = (apiDataItem, t) => {
  const { status, totalItems: value } = apiDataItem

  return {
    name: getStatusLabel(status, t).toLowerCase(),
    value,
    fill: getStatusColor(status) || getRandomColor(),
    listUrl: `${listPageUrl}?groupBy=false&status=${status}`
  }
}

/**
 * @param {{status: string, totalItems: number}[]} apiData
 * @param {Function} t
 * @returns {ChartPieProps[]}
 */
export const apiIssuesByStatusToUI = (apiData, t) => {
  const data = apiData.map(item => apiIssueByStatusToUI(item, t))
  const hasData = !!data.find(({ value }) => !!value)

  return hasData ? data : [emptyItem]
}

// #endregion

// #region Priority

/**
 * @param {{priority: string, totalItems: number}} apiDataItem
 * @returns {ChartPieProps}
 */
const apiIssueByPriorityToUI = apiDataItem => {
  // align prioritiesMap keys with values (priorities) from API
  const prioritiesConfig = Object.values(priorityMap)
    .map(item => ({
      [item.value]: item
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})

  const { priority, totalItems: value } = apiDataItem
  const uiItem = prioritiesConfig[priority]
  const name = uiItem?.text || priority
  const fill = uiItem?.color || getRandomColor()

  return {
    name,
    value,
    fill,
    listUrl: `${listPageUrl}?groupBy=false&priority=${priority}`
  }
}

/**
 * @param {{priority: string, totalItems: number}[]} apiData
 * @returns {ChartPieProps[]}
 */
export const apiIssuesByPriorityToUI = apiData => {
  const data = apiData.map(apiIssueByPriorityToUI)
  const hasData = !!data.find(({ value }) => !!value)

  return hasData ? data : [emptyItem]
}

// #endregion

// #region Types

/**
 * @param {{issueType: string, totalItems: number, id: number}} apiDataItem
 * @returns {ChartPieProps}
 */
const apiIssueByTypeToUI = apiDataItem => {
  const { issueType: name, totalItems: value, id } = apiDataItem
  return {
    name,
    value,
    fill: getTypeColorById(id) || getRandomColor(),
    listUrl: `${listPageUrl}?groupBy=false&issueTypeId=${id}`
  }
}

/**
 * @param {{issueType: string, totalItems: number, id: number}[]} apiData
 * @returns {ChartPieProps[]}
 */
export const apiIssuesByTypeToUI = apiData => {
  const data = apiData.map(apiIssueByTypeToUI)
  const hasData = !!data.find(({ value }) => !!value)

  return hasData ? data : [emptyItem]
}

// #endregion
