import React from 'react'
import { throttle } from 'lodash'
import AsyncSelect from 'react-select/async'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { getUrl } from '../../../apiUrls'
import callApi from '../../../api'
import { customStyles } from '../MultiSelect/MultiSelectStyles'

export const UserSearch = ({
  formatUrl = () => {},
  formatResponse = () => {},
  label,
  isMulti = false,
  disabled = false,
  onChange = () => {},
  ...props
}) => {
  const { t } = useTranslation()

  const [field, , helpers] = useField(props)

  const { name, value } = field
  const { setValue, setTouched } = helpers

  const fetchUsers = async (value, cbFunc) => {
    const url = formatUrl(value)

    try {
      await callApi(url, res => cbFunc(formatResponse(res)))
    } catch (err) {
      cbFunc([
        {
          value: 'error',
          label: 'Error',
          isDisabled: true
        }
      ])
    }
  }

  const throttledFetchUsers = throttle(fetchUsers, 500)

  const emptyValue = isMulti ? [] : ''

  const loadOptions = (input, callback) => {
    if (!input) {
      return emptyValue
    }

    throttledFetchUsers(input, callback)
  }

  const handleChange = value => {
    onChange(value)
    setValue(value || emptyValue)
  }

  const handleBlur = () => {
    setTouched(name, true)
  }

  return (
    <div>
      <label htmlFor={name} className='mt-2'>
        {label}
      </label>

      <AsyncSelect
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        isMulti={isMulti}
        loadOptions={loadOptions}
        noOptionsMessage={() => t('common.labels.noResults')}
        loadingMessage={() => t('common.labels.loading')}
        placeholder=''
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null
        }}
        styles={customStyles}
        isDisabled={disabled}
        isClearable
      />
    </div>
  )
}

const MSGraphSearch = ({ ...props }) => {
  const formatUrl = query => getUrl.userSearch(null, { query })

  const formatResponse = users =>
    users.map(user => ({
      value: user.userPrincipalName,
      label: user.displayName,
      firstName: user.givenName,
      lastName: user.surname,
      email: user.mail,
      name: user.userPrincipalName
    }))

  return (
    <UserSearch
      formatUrl={formatUrl}
      formatResponse={formatResponse}
      {...props}
    />
  )
}

export default MSGraphSearch
