// @ts-check
import React from 'react'

import { getTopLineContent, transformBEtoUI } from './helpers'
import { useTranslation } from 'react-i18next'
import { usePagination } from '../../../../hooks'
import Pagination from '../../../Common/Pagination/PaginationWithNumbers'
import { Warning } from '../../../../helpers/apiNotifications'

import './list.scss'

/** @typedef {import('./types').LogItemUIExtended} LogItemUIExtended */

/**
 * @param {Object} props
 * @param {LogItemUIExtended} props.item
 * @returns {JSX.Element}
 */
const Item = ({ item }) => {
  const { dateUi, comment, topLineContent } = item

  return (
    <li className='history__item'>
      <div className='history__item__top'>
        <span className='content'>{topLineContent}</span>
        <span className='date'>{dateUi}</span>
      </div>
      <div className='history__item__bottom'>{comment}</div>
    </li>
  )
}

/**
 * @param {Object} props
 * @param {LogItemUIExtended[]} props.items
 * @returns {JSX.Element}
 */
const List = ({ items }) => {
  return (
    <ul>
      {items.map(item => (
        <Item key={item.id} item={item} />
      ))}
    </ul>
  )
}

const Container = ({ issue }) => {
  const { t } = useTranslation()

  const items = issue.changeLog
    .map(transformBEtoUI(t))
    .map(getTopLineContent(t))

  const {
    visibleItems,
    totalPages,
    currentPage,
    handlePrevClick,
    handleNextClick,
    handlePageNumberClick
  } = usePagination(items, 10)

  return !items?.length ? (
    <Warning>{t('messages.noData')}</Warning>
  ) : (
    <>
      <List items={visibleItems} />
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        isFirst={currentPage === 1}
        isLast={currentPage === totalPages}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
        handlePageNumberClick={handlePageNumberClick}
      />
    </>
  )
}

export default Container
