import { useState, useEffect } from 'react'

export const DOTS = '...'

export const usePaginationRange = (
  totalPages,
  currentPage,
  siblingCount = 1
) => {
  const [paginationRange, setPaginationRange] = useState([])

  const range = (start, end) => {
    let length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
  }

  useEffect(() => {
    const totalPageNumbers = siblingCount + 5

    if (totalPageNumbers >= totalPages) {
      setPaginationRange(range(1, totalPages))
      return
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages)

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPages

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount
      let leftRange = range(1, leftItemCount)

      setPaginationRange([...leftRange, DOTS, totalPages])
      return
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount
      let rightRange = range(totalPages - rightItemCount + 1, totalPages)
      setPaginationRange([firstPageIndex, DOTS, ...rightRange])
      return
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex)
      setPaginationRange([
        firstPageIndex,
        DOTS,
        ...middleRange,
        DOTS,
        lastPageIndex
      ])
      return
    }
  }, [totalPages, currentPage, siblingCount])

  return paginationRange
}
