export const sizes = {
  small: {
    width: 435,
    height: 300,
    innerRadius: 40,
    outerRadius: 80,
    fontSize: '.8em',
    legendBottomPosition: -35
  },
  large: {
    width: 550,
    height: 385,
    innerRadius: 70,
    outerRadius: 120,
    fontSize: '1em',
    legendBottomPosition: -35
  }
}
