/**
 * Checks if the given string is a valid email address.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} `true` if the string is a valid email address, `false` otherwise.
 *
 * @example
 * // returns true
 * const isValid = isValidEmail("test@example.com");
 *
 * @example
 * // returns false
 * const isValid = isValidEmail("not an email");
 */
export function isValidEmail(email) {
  // Email regular expression pattern
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return emailPattern.test(email)
}

/**
 * Checks if the given string is a Vattenfall email address.
 * @param {string} email
 * @returns {boolean} `true` if the string is a Vattenfall email address, `false` otherwise.
 */
export const isVattenfallEmail = email => {
  const vattenfallEmailPattern = /@vattenfall.com$/

  return vattenfallEmailPattern.test(email)
}
