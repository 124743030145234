import { useTranslation } from 'react-i18next'

import { CreateBtn, buttonSizes } from '../../Common/Ctas'
import { useSlidePanelContext } from '../../../hooks'
import CreateForm from '../Form/Create'

const CreateCustomerBtn = ({ ...props }) => {
  const { t } = useTranslation()
  const { openSlidePanel } = useSlidePanelContext()

  return (
    <CreateBtn
      active
      size={buttonSizes.large}
      onClick={() =>
        openSlidePanel({
          title: t('customers.form.titles.create'),
          size: 40,
          content: () => <CreateForm {...props} />
        })
      }
      data-test='create-customer-btn'
    />
  )
}

export default CreateCustomerBtn
