import React from 'react'

import { ReactComponent as SupportIcon } from '../../assets/icons/VF_support_rgb.svg'
import { getEnvVariable } from '../../.env.custom'

import './report-an-issue.scss'

const email = getEnvVariable('REACT_APP_REPORT_AN_ISSUE_EMAIL')

const ReportAnIssue = () => {
  return (
    <div className='report-an-issue'>
      <a
        href={`mailto:${email}`}
        title='Report an issue'
        target='_blank'
        rel='noreferrer'>
        <SupportIcon height={20} />
      </a>
    </div>
  )
}
export default ReportAnIssue
