import { useTranslation } from 'react-i18next'
import { StatusChart, TypeChart, PriorityChart } from './Charts'
import { TotalItems } from './Charts/DataSection'

import './dashboard.scss'

const Dashboard = () => {
  const { t } = useTranslation()
  return (
    <main>
      <p
        style={{ marginBottom: 30 }}
        className='vf-component__paragraph vf-body-copy__small'>
        {t('dashboard.intro')}
      </p>
      <div className='vf-row'>
        <div className='vf-col-7 dashboard-outer-container'>
          <div className='dashboard-container'>
            <TotalItems />
          </div>
          <div className='dashboard-container'>
            <TypeChart />
          </div>
        </div>
        <div className='vf-col-5 dashboard-outer-container'>
          <div className='dashboard-container'>
            <StatusChart />
          </div>
          <div className='dashboard-container'>
            <PriorityChart />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Dashboard
