import React from 'react'

import { TextInput } from '../../CommonForm'
import { CustomerPicker } from '../../FormPickers'

const FormUserExternal = ({ readOnly = false, getLabel }) => (
  <>
    <TextInput
      name='firstName'
      label={getLabel('firstName', true)}
      readOnly={readOnly}
    />

    <TextInput
      name='lastName'
      label={getLabel('lastName', true)}
      readOnly={readOnly}
    />

    <TextInput
      name='email'
      label={getLabel('email', true)}
      readOnly={readOnly}
    />

    <CustomerPicker
      name='customerIds'
      label={getLabel('customerIds', true)}
      readOnly={readOnly}
    />
  </>
)

export default FormUserExternal
