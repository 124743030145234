import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'
import { loginRequest, msalInstance } from '../../auth/authConfig'
import { VfLinkButton } from '../../components/DesignSystem/Buttons'
import { ExtUserForm } from './ExtUserForm'
import { Success } from '../../helpers/apiNotifications'
import logo from './assets/images/vattenfall-logo.png'
import './login.scss'

export const pageStates = {
  init: 'init',
  confirmation: 'confirmation'
}

const Page = () => {
  const { t } = useTranslation()
  const [pageState, setPageState] = useState(pageStates.init)

  const handleLoginAdUser = () => {
    const loginHint = 'user@vattenfall.com'
    const request = { ...loginRequest, loginHint }

    msalInstance.loginRedirect(request)
  }

  // #region Redirect
  // Checking only ad user account as the other will not get in here after sign in.
  // It is because I am not able to automatically redirect to the home page after sign in with msal (with msal settings).

  // Use of useMsal hook reloads this page multiple times (when inProgress flag is being updated).
  const { inProgress } = useMsal()
  // account is being set when 'inProgress' is 'none' (loading is done). Otherwise it is null.
  const account = msalInstance.getActiveAccount()

  useEffect(() => {
    if (!!account) {
      window.location.href = '/'
    }
  }, [account])

  if (!!account || inProgress !== 'none') {
    // prevent page blinking
    return null
  }

  // #endregion

  return (
    <main id='login-page'>
      <section id='login-container'>
        <img className='logo' src={logo} alt='logo' />

        <div className='login-inner-container'>
          {pageState === pageStates.init && (
            <>
              <VfLinkButton onClick={handleLoginAdUser}>
                {t('common.labels.loginAsInternal')}
              </VfLinkButton>
              <div className='mt-2'>or</div>
              <ExtUserForm callback={setPageState} />
            </>
          )}

          {pageState === pageStates.confirmation && (
            <Success className='mt-4'>{t('auth.success')}</Success>
          )}
        </div>
      </section>
    </main>
  )
}

export default Page
