import { useMemo, useState } from 'react'

/**
 * 
 * @param {*} items 
 * @param {number} itemsPerPage 
 * @returns {*}
 * @example
 * const {
    visibleItems,
    totalPages,
    currentPage,
    handlePrevClick,
    handleNextClick,
    handlePageNumberClick
  } = usePagination(items, 5)
 */
const usePagination = (items = [], itemsPerPage = 10) => {
  const [currentPage, setCurrentPage] = useState(1)

  const totalPages = Math.ceil(items.length / itemsPerPage)

  const startIndex = (currentPage - 1) * itemsPerPage

  const visibleItems = useMemo(
    () => items.slice(startIndex, startIndex + itemsPerPage),
    [items, itemsPerPage, startIndex]
  )

  const handlePrevClick = () => setCurrentPage(currentPage => currentPage - 1)
  const handleNextClick = () => setCurrentPage(currentPage => currentPage + 1)
  const handlePageNumberClick = pageNum => setCurrentPage(pageNum)

  return {
    visibleItems,
    totalPages,
    currentPage,
    handlePrevClick,
    handleNextClick,
    handlePageNumberClick
  }
}

export default usePagination
