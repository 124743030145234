import { useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useData } from '../../hooks'
import { getUrl } from '../../apiUrls'
import { defaultSortParam, initialSortConfig } from './helpers'
import getNotification from '../../helpers/apiNotifications'
import Loader from '../Common/Loader'
import List from '../Layout'
import PaginationWithNumbers from '../Common/Pagination/PaginationWithNumbers'

const ListTitle = memo(({ t, filtersActive = false, totalItems = 0 }) => (
  <h4 className='vf-intro-in-caps'>
    {filtersActive ? (
      <>
        {t('issues.titles.matchingIssues', {
          count: totalItems
        })}
      </>
    ) : (
      <>{t('issues.titles.allIssues', { count: totalItems })}</>
    )}
  </h4>
))

const ListWithPagination = ({
  apiParams,
  listConfig,
  ctas: ItemCtas,
  filtersActive = false,
  sortConfig,
  handleSort = () => {},
  handlePrevClick = () => {},
  handleNextClick = () => {},
  setTotalPages = () => {},
  handlePageNumberClick = () => {}
}) => {
  const { t } = useTranslation()

  // defaultSortParam already contains sortby "createdDate",
  // thus if apiParams include sortby "createdDate", we need to remove defaultSortParam
  // but if we want to e.g. sort by status, we want them to be sorted ALSO by createdDate
  const url =
    getUrl.issues(null, apiParams) +
    (apiParams?.sort?.includes(initialSortConfig.sortBy)
      ? ''
      : defaultSortParam)

  const { loading, items: data, error } = useData(url)

  useEffect(() => {
    if (!!data) {
      setTotalPages(data.totalPages)
    }
  }, [data, setTotalPages])

  const Info = getNotification(loading, error, data?.result, Loader, {
    noData: filtersActive ? t('messages.noDataWithFilters') : ''
  })

  const totalPages = data?.totalPages ?? 0
  const currentPage = (data?.currentPage ?? 0) + 1

  return Info ? (
    Info
  ) : (
    <>
      <ListTitle
        t={t}
        filtersActive={filtersActive}
        totalItems={data.totalItems}
      />

      <List
        items={data?.result}
        listConfig={listConfig}
        ctas={ItemCtas}
        sortConfig={sortConfig}
        handleSort={handleSort}
      />

      <PaginationWithNumbers
        totalPages={totalPages}
        currentPage={currentPage}
        isFirst={currentPage === 1}
        isLast={currentPage === totalPages}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
        handlePageNumberClick={handlePageNumberClick}
      />
    </>
  )
}

export default ListWithPagination
