import { createContext, useState } from 'react'

export const NavigationContext = createContext({})

const NavigationResizeProvider = ({ children }) => {
  const [smallerNav, setSmallerNav] = useState(false)

  const resizeNav = () => {
    setSmallerNav(!smallerNav)
  }

  return (
    <NavigationContext.Provider
      value={{
        smallerNav,
        resizeNav
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationResizeProvider
