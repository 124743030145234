// pages
import * as pages from './pages'
import * as IssuesPages from './pages/issues'
import AdminPage from './pages/admin/Admin'
import * as CustomerPages from './pages/customers'
import * as UserPages from './pages/users'
import Login from './pages/Login/Login'
import Invintation from './pages/Invintation'
import CustomerTypesPage from './pages/CustomerTypes'
import IssueTypesPage from './pages/IssueTypes'
import ContractTypesPage from './pages/ContractTypes'
import Dashboard from './pages/dashboard'
import Consent from './pages/Consent'
// icons
import homeIcon from './assets/icons/VF_house_rgb.svg'
import appSettingsIcon from './assets/icons/VF_app_settings_rgb.svg'
import listIcon from './assets/icons/VF_list_view_rgb.svg'

import routesUrls from './routesUrls'
import permissions from './roles/permissions.json'

const routes = [
  {
    path: '/',
    element: Dashboard,
    withTemplate: true,
    nav: {
      isMainNav: true,
      title: 'titles.navDashboard',
      iconSrc: homeIcon,
      activePaths: ['^/$']
    },
    access: '*',
    secure: true
  },

  {
    path: '/consent',
    element: Consent,
    withTemplate: false,
    nav: {
      isMainNav: false,
      title: 'titles.home',
      iconSrc: homeIcon,
      activePaths: ['^/$', routesUrls.issues.list]
    },
    access: '*',
    secure: true
  },

  {
    path: routesUrls.issues.list,
    element: IssuesPages.ListPage,
    withTemplate: true,
    nav: {
      isMainNav: true,
      title: 'titles.issueList',
      iconSrc: listIcon,
      activePaths: [routesUrls.issues.list]
    },
    access: permissions.issues.list,
    secure: true
  },
  {
    path: routesUrls.issues.create,
    element: IssuesPages.CreatePage,
    withTemplate: true,
    nav: {
      isMainNav: false,
      title: 'titles.issueCreate'
    },
    access: permissions.issues.create,
    secure: true
  },
  {
    path: routesUrls.issues.details,
    element: IssuesPages.DetailsPage,
    withTemplate: true,
    nav: {
      isMainNav: false,
      title: 'titles.issueDetails'
    },
    access: permissions.issues.details,
    secure: true
  },
  {
    path: routesUrls.issues.edit,
    element: IssuesPages.EditPage,
    withTemplate: true,
    nav: {
      isMainNav: false,
      title: 'titles.issueEdit'
    },
    access: permissions.issues.edit,
    secure: true
  },
  {
    path: routesUrls.admin.list,
    element: AdminPage,
    withTemplate: true,
    nav: {
      isMainNav: true,
      title: 'titles.admin',
      iconSrc: appSettingsIcon,
      activePaths: [
        routesUrls.admin.list,
        routesUrls.admin.users,
        routesUrls.admin.categoriesManager
      ]
    },
    access: permissions.admin.list,
    secure: true
  },
  {
    path: routesUrls.admin.customers,
    element: CustomerPages.ListPage,
    withTemplate: true,
    nav: {
      isMainNav: false,
      title: 'titles.customers'
    },
    access: permissions.admin.customers,
    secure: true
  },
  {
    path: routesUrls.admin.users,
    element: UserPages.ListPage,
    withTemplate: true,
    nav: {
      isMainNav: false,
      title: 'titles.users'
    },
    access: permissions.admin.users,
    secure: true
  },
  {
    path: routesUrls.admin.customerTypes,
    element: CustomerTypesPage,
    withTemplate: true,
    nav: {
      isMainNav: false,
      title: 'titles.customerTypes'
    },
    access: permissions.admin.customerTypes,
    secure: true
  },
  {
    path: routesUrls.admin.contractTypes,
    element: ContractTypesPage,
    withTemplate: true,
    nav: {
      isMainNav: false,
      title: 'titles.contractTypes'
    },
    access: permissions.admin.contractTypes,
    secure: true
  },
  {
    path: routesUrls.admin.issueTypes,
    element: IssueTypesPage,
    withTemplate: true,
    nav: {
      isMainNav: false,
      title: 'titles.issueTypes'
    },
    access: permissions.admin.issueTypes,
    secure: true
  },
  // ...

  {
    path: '/Login',
    element: Login,
    access: '*',
    secure: false
  },
  {
    path: '/invitation/:magicLink',
    element: Invintation,
    access: '*',
    secure: false
  },
  {
    path: '*',
    element: pages.PageNotFound,
    withTemplate: true,
    access: '*',
    secure: true
  }
]

export default routes

export const getMainNavItems = () => routes.filter(item => item.nav?.isMainNav)

export const filterItemByRole = role => item =>
  item.access === '*' ? item : item.access?.includes(role)

/**
 *
 * @param {string} role
 * @returns {Array}
 */
export const getRoutesByRole = role => routes.filter(filterItemByRole(role))

/**
 *
 * @param {string} role
 * @returns
 */
export const getLandingPageForRole = role => {
  const route = routes.find(item => item.access.includes(role))

  if (!!route) {
    return route.path
  }
  return '/404'
}
