import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './tab-bar.scss'

const TabItem = ({ index, title, handleClick, isActive }) => {
  const className = `vf-tab-bar-item ${
    isActive ? 'vf-tab-bar-item--active' : ''
  }`
  return (
    <li
      id={`vf-tab-${index}`}
      className={className}
      role='tab'
      aria-controls={`vf-panel-${index}`}
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      onClick={handleClick}>
      <div className='vf-tab-bar-link'>{title}</div>
    </li>
  )
}

TabItem.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  handleClick: PropTypes.func,
  isActive: PropTypes.bool
}

const TabContentItem = ({ index, content, isActive }) => {
  const hidden = isActive ? {} : { hidden: true }

  return (
    <div
      id={`vf-panel-${index}`}
      className='vf-panel'
      role='tabpanel'
      tabIndex={index}
      aria-labelledby={`vf-tab-${index}`}
      {...hidden}>
      {content}
    </div>
  )
}

TabContentItem.propTypes = {
  index: PropTypes.number,
  text: PropTypes.string,
  isActive: PropTypes.bool
}

const TabBar = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className='vf-tab-bar-container'>
      <ul className='vf-tab-bar' aria-label='Tab bar' role='tablist'>
        {tabs.map((item, idx) => {
          return (
            <TabItem
              key={idx}
              index={idx}
              title={item.title}
              handleClick={() => setActiveTab(idx)}
              isActive={activeTab === idx}
            />
          )
        })}
      </ul>

      <div className='vf-tab-bar-panels'>
        {tabs.map((item, idx) => {
          return (
            <TabContentItem
              key={idx}
              index={idx}
              content={item.content}
              isActive={activeTab === idx}
            />
          )
        })}
      </div>
    </div>
  )
}

TabBar.defaultProps = {
  tabs: []
}

TabBar.propTypes = {
  // own props
  tabs: PropTypes.array,
  headers: PropTypes.array
}

export default TabBar
