import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react'

import { Toast } from '../components/Common/Toasts'

const ToastContext = createContext()

export default ToastContext

export const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning'
}

export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  useEffect(() => {
    if (toasts.length > 0) {
      const timeDuration = toasts[toasts.length - 1].time || 3000

      if (timeDuration !== 'endless') {
        const timer = setTimeout(
          () => setToasts(toasts => toasts.slice(1)),
          timeDuration
        )

        return () => clearTimeout(timer)
      }
    }
  }, [toasts])

  const addToast = useCallback(
    /**
     * @param {string} text to be displayed in Toast
     * @param {{time: number || string, type: string}} options customize behaviour and look of the Toast component
     */
    (text, options) => {
      const newToast = { text, ...options }
      setToasts(toasts => [...toasts, newToast])
    },
    [setToasts]
  )

  const removeToast = useCallback(
    idx => {
      setToasts(toasts => [...toasts.slice(0, idx), ...toasts.slice(idx + 1)])
    },
    [setToasts]
  )

  const value = useMemo(
    () => ({
      addToast,
      toastTypes
    }),
    [addToast]
  )

  return (
    <ToastContext.Provider value={value}>
      {children}

      <div className='toasts-wrapper'>
        {toasts.map((toast, idx) => (
          <Toast key={idx} toast={toast} handleClose={() => removeToast(idx)} />
        ))}
      </div>
    </ToastContext.Provider>
  )
}
