// @ts-check

import routesUrls, { urlWithParam } from '../../routesUrls'
import { getFormattedDate } from '../../helpers/dates'
import { getUrl } from '../../apiUrls'
import { getCreateDate, getPriority, priorities } from './Details/helpers'

export const issueStatuses = {
  open: 'OPEN',
  received: 'RECEIVED',
  inProgress: 'IN_PROGRESS',
  resolved: 'RESOLVED',
  closed: 'CLOSED',
  cancelled: 'CANCELLED'
  // TODO: uncomment when implemented in the API
  // reopened: 'REOPENED'
}

export const statusConfig = {
  [issueStatuses.open]: { label: 'common.statuses.open', color: '#66b0ff' },
  [issueStatuses.received]: {
    label: 'common.statuses.received',
    color: '#ffc107'
  },
  [issueStatuses.inProgress]: {
    label: 'common.statuses.inProgress',
    color: '#f5a623'
  },
  [issueStatuses.resolved]: {
    label: 'common.statuses.resolved',
    color: '#81e0a8'
  },
  [issueStatuses.closed]: { label: 'common.statuses.closed', color: '#3dc07c' },
  [issueStatuses.cancelled]: {
    label: 'common.statuses.cancelled',
    color: '#d1266b'
  }
  // [issueStatuses.reopened]: {label: 'common.statuses.reopened', color: '#007bff'}
}

export const getStatusLabel = (status, t) => {
  return t(statusConfig[status]?.label || '').toUpperCase()
}

/**
 * @param {string} status - issue status name
 * @returns {string | undefined}  color in hex format or white if not found
 */
export const getStatusColor = status => statusConfig[status]?.color

export const EMPTY_GROUP_BY_VALUE = 'false'

export const groupByProperties = {
  none: EMPTY_GROUP_BY_VALUE,
  status: 'status',
  priority: 'priority',
  issueType: 'issueTypeId'
}

export const getlListConfig = (t, groupBy, filters) => ({
  gridTemplateColumns: '1.5fr 3fr 1fr 1fr 1fr 1.5fr 1fr 1fr',
  attributes: [
    {
      label: t('issues.list.headers.woId'),
      propName: 'woId',
      sortable: false,
      formatFn: (woId, item) => item.importedWorkOrderId || woId
    },
    {
      label: t('issues.list.headers.title'),
      propName: 'title',
      path: id => urlWithParam(routesUrls.issues.details, { id }),
      sortable: true
    },
    {
      label: t('issues.list.headers.status'),
      propName: 'status',
      sortable:
        groupBy !== groupByProperties.status &&
        !filters[groupByProperties.status],
      formatFn: val => getStatusLabel(val, t)
    },
    {
      label: t('issues.list.headers.priority'),
      propName: 'priority',
      formatFn: val => getPriority(val)?.label ?? val,
      sortable:
        groupBy !== groupByProperties.priority &&
        !filters[groupByProperties.priority]
    },
    {
      label: t('issues.list.headers.issueType'),
      propName: 'issueType.name',
      sortable:
        groupBy !== groupByProperties.issueType &&
        !filters[groupByProperties.issueType]
    },
    {
      label: t('issues.list.headers.customer'),
      propName: 'customer.name',
      sortable: true
    },
    {
      label: t('issues.list.headers.createdDate'),
      propName: 'unifiedCreateDate', // unifiedCreateDate is a prop visible only on BE. that is: importedCreateDate || createdDate
      formatFn: (_, item) => {
        const createdDate = getCreateDate(item)
        return getFormattedDate(createdDate, 'sv-SE')
      },
      sortable: true
    },
    {
      label: t('issues.list.headers.modifiedDate'),
      propName: 'modifiedDate',
      formatFn: (_, item) =>
        getFormattedDate(item.audit?.modifiedDate, 'sv-SE'),
      sortable: true
    }
  ]
})

export const getGroupByOptions = (t, filters) => [
  {
    label: t('issues.form.labels.none'),
    value: EMPTY_GROUP_BY_VALUE
  },
  {
    label: t(`issues.form.labels.${groupByProperties.status}`),
    value: groupByProperties.status,
    disabled: !!filters[groupByProperties.status]
  },
  {
    label: t(`issues.form.labels.${groupByProperties.priority}`),
    value: groupByProperties.priority,
    disabled: !!filters[groupByProperties.priority]
  },
  {
    label: t(`issues.form.labels.${groupByProperties.issueType}`),
    value: groupByProperties.issueType,
    disabled: !!filters[groupByProperties.issueType]
  }
]

const statusesOptions = Object.values(issueStatuses).map(status => ({
  name: statusConfig[status]?.label,
  value: status,
  translateName: true
}))

export const groupSourceTypes = {
  local: 'local',
  api: 'api'
}

export const groupByConfig = {
  [groupByProperties.status]: {
    source: groupSourceTypes.local,
    data: statusesOptions
  },
  [groupByProperties.priority]: {
    source: groupSourceTypes.local,
    data: priorities.map(item => ({
      name: item.label,
      value: item.value
    }))
  },
  [groupByProperties.issueType]: {
    source: groupSourceTypes.api,
    url: getUrl.issueTypes(),
    data: null
  }
}

export const getAttachmentsLabel = (numOfFiles = 0, t) =>
  t('issues.form.labels.attachments') + ' (' + numOfFiles + ')'

export const defaultSortParam = '&sort=unifiedCreateDate,DESC'

export const initialSortConfig = {
  sortBy: 'unifiedCreateDate',
  order: 'desc'
}
