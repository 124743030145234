// @ts-check
import React from 'react'
import HeaderTemplate from '../HeaderTemplate'
import ItemTemplate from '../ItemTemplate'

import './list-template.scss'

/**
 * @typedef {import('../types').ListConfig} ListConfig
 */

/**
 * @param {Object} params
 * @param {Object[]} params.items
 * @param {ListConfig} params.listConfig
 * @param {string} params.listTitle
 * @param {JSX.Element} params.headerComponent
 * @param {JSX.Element} params.itemComponent
 * @param {JSX.Element} params.ctas
 * @param {string} params.className
 * @param {Function} params.isItemInactive
 * @param {Function} params.sortConfig
 * @param {Function} params.handleSort
 * @returns {JSX.Element}
 */
const ListTemplate = ({
  items = [],
  listConfig = {},
  listTitle = '',
  headerComponent: HeaderComponent = HeaderTemplate,
  itemComponent: ItemComponent = ItemTemplate,
  className = '',
  isItemInactive = () => {},
  ...props
}) => {
  return (
    <div className={`list-template ${className}`} data-test='list-template'>
      {listTitle && <h4 className='vf-intro-in-caps'>{listTitle}</h4>}

      <div
        className='list-template__grid-container'
        data-test='list-template-container'>
        <HeaderComponent listConfig={listConfig} {...props} />

        {items.map(item => (
          <ItemComponent
            key={item.id}
            item={item}
            listConfig={listConfig}
            isInactive={isItemInactive(item)}
            {...props}
          />
        ))}
      </div>
    </div>
  )
}

export default ListTemplate
