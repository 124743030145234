import { EditBtn, InactivateBtn } from './'
import { buttonSizes } from '../../Common/Ctas'

const CtasGroup = ({ item, refreshList, size = buttonSizes.small }) => (
  <>
    {item.active && (
      <>
        <EditBtn item={item} size={size} refreshList={refreshList} />

        <InactivateBtn item={item} size={size} refreshList={refreshList} />
      </>
    )}
  </>
)

export default CtasGroup
