import { DetailsBtn, EditBtn, DeleteBtn } from './'
import { buttonSizes } from '../../Common/Ctas'

const CtasGroup = ({
  item,
  refreshList,
  hideDetailsBtn = false,
  size = buttonSizes.small
}) => (
  <>
    {!hideDetailsBtn && (
      <DetailsBtn item={item} size={size} refreshList={refreshList} />
    )}

    <EditBtn item={item} size={size} refreshList={refreshList} />

    <DeleteBtn item={item} size={size} refreshList={refreshList} />
  </>
)

export default CtasGroup
