import * as Yup from 'yup'
import callApi from '../../../api'
import { getUrl } from '../../../apiUrls'

const validateCustomerIds = (value, context) => {
  if (context.parent.isAdmin) return true
  if (context.parent.role === userRole.external && typeof value === 'number') {
    return true
  }
  if (
    (context.parent.role === userRole.regular || !context.parent.isAdmin) &&
    value !== undefined
  ) {
    return !!value.length
  }
}

const validateName = (value, context) => {
  if (
    context.parent.role === userRole.regular ||
    context.parent.role === userRole.admin
  ) {
    return value?.length <= 40 && value.length >= 1
  } else {
    return true
  }
}

export const validationSchema = t =>
  Yup.object({
    name: Yup.string().test(
      'validateName',
      t('users.form.validation.name.required'),
      validateName
    ),
    firstName: Yup.string()
      .max(30, t('common.form.validation.maxLength', { count: 30 }))
      .required(t('users.form.validation.firstName.required')),
    lastName: Yup.string()
      .max(30, t('common.form.validation.maxLength', { count: 30 }))
      .required(t('users.form.validation.lastName.required')),
    email: Yup.string()
      .email(t('users.form.validation.email.format'))
      .max(40, t('common.form.validation.maxLength', { count: 40 }))
      .required(t('users.form.validation.email.required')),
    isAdmin: Yup.boolean(),
    customerIds: Yup.mixed().test(
      'validateCustomerIds',
      t('users.form.validation.customerIds.required'),
      validateCustomerIds
    )
  })

export const userRole = {
  regular: 'REGULAR',
  external: 'EXTERNAL',
  admin: 'ADMIN'
}

export const formatValuesForApi = values => {
  let data = { ...values }

  const getCustomerIdsData = values => {
    if (values?.role === userRole.external) {
      return [values['customerIds']]
    } else {
      return values.customerIds.map(item => item.value)
    }
  }

  data.customerIds = getCustomerIdsData(values)
  data.name = values.role === userRole.external ? values.email : values.name
  data.role = values.isAdmin ? userRole.admin : values.role

  delete data.isAdmin
  delete data.user

  return data
}

export const formatItemForForm = (item, customersOptions) => {
  const geCustomerIdsFormatted = (arrayWithIds, customersOptions) => {
    if (item?.role === userRole.external) {
      return item.customerIds[0]
    }

    return customersOptions?.filter(
      el => el.value === arrayWithIds.find(item => item === el.value)
    )
  }

  return {
    ...item,
    customerIds: geCustomerIdsFormatted(item?.customerIds, customersOptions)
  }
}

export const sendInvitation = (email, callback) => {
  const magicLinkUrl = getUrl.magicLink(email)
  callApi(magicLinkUrl, callback)
}
