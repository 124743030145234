/**
 * Converts a File object into a base64-encoded string.
 * @param {File} file - The File object to convert.
 * @returns {Promise<string>} A Promise that resolves with the base64-encoded string.
 */
export const convertFileIntoBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// TO DO replace the below with a package or something
/**
 * This is dirty way around for files like .vsmx that do not have mime type within File object.
 * @param {string} name - file name
 * @returns {string} - mime type
 */
export const getMimeTypeFromFileName = name => {
  const ext = name.split('.').pop()

  switch (ext) {
    case 'vsdx':
      return 'application/vnd.ms-visio.drawing'
    default:
      return 'application/octet-stream'
  }
}
