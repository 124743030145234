import { useTranslation } from 'react-i18next'

import { PageTitle } from '../../components/Template'
import List from '../../components/Users/ListContainer'

const Page = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageTitle>{t('titles.users')}</PageTitle>

      <List />
    </>
  )
}

export default Page
