import React from 'react'

import { Select, getFormattedOptions } from '../CommonForm'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import { sortByParam } from '../../helpers/lists'

const IssueTypePicker = ({
  name = 'issueTypeId',
  label,
  options = [],
  loading = false,
  isClearable = false,
  readOnly = false,
  disabled = false,
  ...props
}) => {
  return (
    <Select
      name={name}
      label={label}
      options={options}
      readOnly={readOnly}
      disabled={disabled}
      isClearable={isClearable}
      loading={loading}
      {...props}
    />
  )
}

const IssueTypePickerContainer = ({ ...props }) => {
  const issueTypesUrl = getUrl.issueTypes()
  const { items: issueTypes, loading } = useData(issueTypesUrl)
  const issueTypesSorted = sortByParam(issueTypes, 'asc', 'name')
  const issueTypesOptions = getFormattedOptions(issueTypesSorted || [])

  return (
    <IssueTypePicker options={issueTypesOptions} loading={loading} {...props} />
  )
}

export default IssueTypePickerContainer
