import { useTranslation } from 'react-i18next'

import { ReactComponent as DeleteIcon } from './icons/VF_trash_alt02_rgb.svg'
import ButtonTemplate from './ButtonTemplate'

const DeleteBtn = ({ ...props }) => {
  const { t } = useTranslation()

  return (
    <ButtonTemplate text={t('buttons.delete')} icon={DeleteIcon} {...props} />
  )
}

export default DeleteBtn
