import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { formModes, getFieldLabelRequired } from '../../CommonForm'

import { useFormPersist, useSlidePanelContext } from '../../../hooks'
import { FormButtons } from '../../Common/Ctas'
import FormUserInternal from './FormUserInternal'
import FormUserExternal from './FormUserExternal'
import RoleSwitchButtons from './RoleSwitchButtons'
import { userRole } from './helpers'
const FormComponent = ({
  initialValues,
  values,
  apiErrors,
  isSubmitting,
  setValues,
  setErrors,
  resetForm,
  readOnly = false,
  mode = formModes.create,
  storeKey,
  setFieldValue,
  setTouched
}) => {
  const { t } = useTranslation()

  const { RecoveredDataMessage } = useFormPersist(
    storeKey,
    values,
    initialValues,
    setValues,
    resetForm,
    {
      disablePersistence: mode !== formModes.create,
      message: t('messages.unsavedData'),
      clearButtonText: t('messages.clearForm')
    }
  )

  const { closeSlidePanel } = useSlidePanelContext()

  const isExternal =
    initialValues.role === userRole.external ||
    values.role === userRole.external

  useEffect(() => {
    if (!!apiErrors) {
      setErrors(apiErrors)
    }
  }, [apiErrors, setErrors])

  const getLabel = (label, required) =>
    getFieldLabelRequired(t(`users.form.labels.${label}`), required, mode)

  const handleUserSelect = user => {
    if (!!user) {
      setFieldValue('firstName', user.firstName || '')
      setFieldValue('lastName', user.lastName || '')
      setFieldValue('email', user.email || '')
      setFieldValue('name', user.name || '')
    }
  }

  const handleUserTypeChange = event => {
    setFieldValue('role', event.target.name)
    setFieldValue('customerIds', [])
    setTouched({}, false)
  }

  const renderButtonTitle = () => {
    if (
      values.role === userRole.external &&
      !isSubmitting &&
      mode === formModes.create
    ) {
      return t('buttons.save&invite')
    } else {
      return t('buttons.save')
    }
  }

  return (
    <>
      <RecoveredDataMessage />
      <Form>
        {mode === formModes.create && (
          <RoleSwitchButtons
            handleUserTypeChange={handleUserTypeChange}
            userSelected={values.role}
          />
        )}

        {isExternal ? (
          <FormUserExternal readOnly={readOnly} getLabel={getLabel} />
        ) : (
          <FormUserInternal
            handleUserSelect={handleUserSelect}
            readOnly={readOnly}
            getLabel={getLabel}
            mode={mode}
          />
        )}

        {!readOnly && (
          <FormButtons
            handleCancel={closeSlidePanel}
            isSubmitting={isSubmitting}
            submitDisabled={isSubmitting}
            userType={values.role}
            mode={mode}
            submitLabel={renderButtonTitle()}
            className='mt-2'
            t={t}
          />
        )}
      </Form>
    </>
  )
}

const FormContainer = ({
  initialValues,
  validationSchema,
  handleSubmit,
  ...props
}) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formikProps => {
        return <FormComponent {...formikProps} {...props} />
      }}
    </Formik>
  )
}

export default FormContainer
