import { useTranslation } from 'react-i18next'

import { BlockBtn, buttonSizes } from '../../Common/Ctas'
import {
  useModalContext,
  useSlidePanelContext,
  useToastContext
} from '../../../hooks'
import { getUrl } from '../../../apiUrls'
import { apiPutWithToken } from '../../../api'
import { callback } from '../../../helpers/forms'

const InactivateCustomerTypeBtn = ({
  item,
  refreshList = () => {},
  size = buttonSizes.small
}) => {
  const { t } = useTranslation()
  const openModal = useModalContext()
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()

  const updateUrl = getUrl.customerTypeUpdate()

  const successCallback = () => {
    refreshList()
    closeSlidePanel()
  }

  const handleBlock = () => {
    const updatedItem = {
      ...item,
      contractTypeIds: item.contractTypes.map(item => item.id),
      active: false
    }

    apiPutWithToken(updateUrl, updatedItem, callback(addToast, successCallback))
  }

  const modalTitle = t('customerTypes.form.titles.inactivate', {
    name: item.name
  })

  return (
    <BlockBtn
      active
      size={size}
      text={t('buttons.inactivate')}
      onClick={() =>
        openModal({
          title: modalTitle,
          onConfirm: handleBlock
        })
      }
    />
  )
}

export default InactivateCustomerTypeBtn
