import { VfButton } from '../../DesignSystem/Buttons'
import { SubmitButton } from '../../CommonForm'

import './form-buttons.scss'

/**
 * FormButtons component
 * @param {Function} handleCancel - callback function to handle cancel button click
 * @param {boolean} isSubmitting - boolean value to determine if the form is currently submitting
 * @param {boolean} submitDisabled - boolean value to disable the submit button
 * @param {boolean} cancelHidden - boolean value to hide the cancel button
 * @param {string} className - class name to add to the component
 * @param {string} inProgressLabel - label for button when form is being submitted
 * @param {string} submitLabel - label for submit button
 * @param {Function} t - translate function
 */

const FormButtons = ({
  handleCancel = () => {},
  isSubmitting = false,
  submitDisabled = false,
  cancelHidden = false,
  className = '',
  t,
  inProgressLabel = t('buttons.saving'),
  submitLabel = t('buttons.save')
}) => {
  return (
    <div className={`form-buttons ${className}`}>
      {!cancelHidden && (
        <VfButton
          color=''
          onClick={handleCancel}
          className='form-buttons__cancel-btn'
          data-test='cancel-btn'>
          {t('buttons.cancel')}
        </VfButton>
      )}

      <SubmitButton disabled={submitDisabled} data-test='submit-btn'>
        {!isSubmitting ? submitLabel : inProgressLabel}
      </SubmitButton>
    </div>
  )
}

export default FormButtons
