//@ts-check
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useData } from '../../../hooks'
import { getUrl } from '../../../apiUrls'
import { ChartPie } from '../../Common/Charts'
import getNotification from '../../../helpers/apiNotifications'
import Loader from '../../Common/Loader'
import { apiIssuesByStatusToUI } from './helpers'

const Chart = () => {
  const { t } = useTranslation()
  const title = t('issues.list.headers.status')
  const url = getUrl.chartStatus()
  const { items, loading, error } = useData(url)
  const Info = getNotification(loading, error, items, Loader)

  if (!!Info) {
    return Info
  }

  const data = apiIssuesByStatusToUI(items, t)

  return <ChartPie data={data} title={title} />
}

export default Chart
