import React from 'react'
import { useTranslation } from 'react-i18next'

import './page-not-found.scss'

const AccessDeniedPage = () => {
  const { t } = useTranslation()

  return (
    <div className='page-not-found__container'>
      <h2>{t('titles.accessDenied')}</h2>

      <h1>403</h1>

      <p>{t('messages.accessDenied')}</p>
    </div>
  )
}

export default AccessDeniedPage
