import './consent.scss'

const ConsentContent = () => {
  return (
    <article>
      <h3>Vattenfall Services Nordic Dataskyddspolicy</h3>
      <p className='vf-gdpr-consent__text'>
        Vattenfall Services Nordic värnar om din personliga integritet. Denna
        dataskyddspolicy förklarar hur Vattenfall Services Nordic samlar in och
        använder dina personuppgifter. Den beskriver också dina rättigheter
        gentemot oss och hur du kan göra dina rättigheter gällande. Du kan
        alltid kontakta oss vid frågor kring integritets‐ och dataskydd genom
        att skicka ett e‐postmeddelande till{' '}
        <a href='mailto:vattenfallservices@vattenfall.com'>
          vattenfallservices@vattenfall.com
        </a>
        .
      </p>
      <h3>Personuppgifter</h3>
      <p className='vf-gdpr-consent__text'>
        Med ”personuppgifter” avses all slags information som direkt eller
        indirekt kan hänföras till en fysisk person som är i livet. Vattenfall
        Services Nordic AB är personuppgiftsansvarig för de uppgifter som du
        tillhandahåller oss eller inhämtas av oss.
      </p>
      <h3>Information vi samlar in</h3>
      <p className='vf-gdpr-consent__text'>
        Vi samlar in information om kunder och leverantörer i samband med
        förfrågningar, beställningar, kontrakt etc. Vi samlar bara in
        personuppgifter som är relevant och har ett syfte, tex namn, faktura‐
        och leveransadress, e‐ postadress, mobiltelefonnummer.
      </p>
      <h3>Vad gör vi med din information</h3>
      <p className='vf-gdpr-consent__text'>
        Informationen används för att tillhandahålla, utföra och förbättra
        Vattenfall Services Nordics tjänster. Vattenfall Services Nordic samlar
        bara in personuppgifter då det finns stöd i gällande
        dataskyddslagstiftning. Den vanligaste rättsliga grunden för våra
        behandlingar är att upprätta ett kontrakt som berörd person har begärt
        för att;
      </p>
      <ul className='listWithBullets'>
        <li>ingå ett avtal med oss/bli kund</li>
        <li>få en offert på ett erbjudande</li>
        <li>köpa en produkt/tjänst</li>
        <li>teckna en prenumeration, på exempelvis ett nyhetsbrev</li>
      </ul>
      <p className='vf-gdpr-consent__text'>
        Med vårt berättigade intresse som grund behandlar vi data för flera
        ändamål, till exempel
      </p>
      <ul className='listWithBullets'>
        <li>
          marknadsföring och profilering, det vill säga för att kommunicera
          relevanta erbjudanden till våra kunder
        </li>
        <li>hantering av kundservice‐ärenden</li>
        <li>för att utveckla och förbättra våra produkter och tjänster</li>
        <li>statistik och analyser</li>
      </ul>
      <p className='vf-gdpr-consent__text'>
        Andra ändamål för vår behandling av personuppgifter kan ha sin legala
        grund i olika lagkrav som vi måste följa, exempelvis bokföringslagen för
        fakturering eller när vi förmedlar information om ändrade avtalsvillkor.
        Du kan även lämna samtycke till behandling i vissa fall då inget av
        ovanstående gäller.
      </p>

      <p className='vf-gdpr-consent__text'>
        Vattenfall Services Nordic behandlar inte personuppgifterna för ändamål
        som är oförenliga med det ursprungliga ändamålet.
      </p>

      <p className='vf-gdpr-consent__text'>
        Åtkomst till informationen är begränsad och inga personuppgifter skickas
        till tredje part eller säljs. Vi sparar uppgifterna enbart så länge dom
        uppfyller ett syfte eller att du önskar ta bort dem. Inga
        personuppgifterna behandlas i ett land utanför EU.
      </p>

      <p className='vf-gdpr-consent__text'>
        Vattenfall Services Nordic kan också komma att använda dina data för att
        kommunicera relevant information gällande använda eller liknande
        tjänster och för att utföra kundnöjdhetsundersökningar gällande våra
        tjänster via elektroniska kommunikationskanaler och per telefon. Om du
        inte vill motta sådan kommunikation, är du välkommen att skicka e‐post
        till{' '}
        <a href='mailto:vattenfallservices@vattenfall.com'>
          vattenfallservices@vattenfall.com
        </a>
        .
      </p>
      <h3>Dina rättigheter</h3>
      <p className='vf-gdpr-consent__text'>
        <strong>Rätt till information</strong> ‐ Du kan få en kopia av dina
        personuppgifter som vi har om dig.{' '}
      </p>

      <p className='vf-gdpr-consent__text'>
        <strong>Rätt till rättelse</strong> ‐ Du har rätten att få felaktiga
        uppgifter uppdaterade och rättade.{' '}
      </p>

      <p className='vf-gdpr-consent__text'>
        <strong>Rätt till att bli glömd</strong> ‐ Du kan begära att vi ska
        radera din information. Vi får inte radera information som lagen kräver
        att vi behåller.{' '}
      </p>

      <p className='vf-gdpr-consent__text'>
        <strong>Rätt att göra invändningar</strong> ‐ Du har alltid rätten att
        göra invändningar om vår hantering av dina personuppgifter. Kontakta oss
        i så fall via{' '}
        <a href='mailto:vattenfallservices@vattenfall.com'>
          vattenfallservices@vattenfall.com
        </a>
        .
      </p>

      <h3>Kontakta oss</h3>
      <p className='vf-gdpr-consent__text'>
        Vattenfall Services Nordic är registrerat hos Bolagsverket med
        organisationsnummer 556417‐0859 och har sitt säte i Solna, Stockholms
        län.
      </p>
      <p className='vf-gdpr-consent__text'>
        Vattenfall Services Nordic är personuppgiftsansvarigt för behandlingen
        av dina personuppgifter enligt ovan. Frågor rörande
        personuppgiftsbehandling kan ställas till något vårt dataskyddsombud.
        Dataskyddsombud för
      </p>
      <p className='vf-gdpr-consent__text'>
        Vattenfall AB kan nås via epost på{' '}
        <a href='mailto:dpo.nordics@vattenfall.com'>
          dpo.nordics@vattenfall.com
        </a>{' '}
        eller telefon 08‐739 50 00.
      </p>
    </article>
  )
}

export default ConsentContent
