import React from 'react'
import { Link } from 'react-router-dom'

import { clearClassNames } from '../../../helpers/strings'
import './action-btn.scss'

export const colors = {
  red: 'red',
  blue: 'blue',
  green: 'green',
  white: 'white',
  gray: 'gray',
  lightGray: 'lightGray'
}

const colorClasses = {
  red: 'action-btn--red',
  blue: 'action-btn--blue',
  green: 'action-btn--green',
  white: 'action-btn--white',
  gray: 'action-btn--gray',
  lightGray: 'action-btn--light-gray'
}

const ActionButton = ({
  type = 'button',
  title,
  onClick = () => {},
  className = '',
  color = '',
  disabled = false,
  children,
  ...props
}) => (
  <button
    type={type}
    title={title}
    className={clearClassNames(
      `action-btn ${className} ${colors[color] ? colorClasses[color] : ''}`
    )}
    onClick={onClick}
    disabled={disabled}
    {...props}>
    {children}
  </button>
)

export const ActionButtonWithLink = ({
  title,
  path,
  className = '',
  color = '',
  children,
  disabled = false,
  ...props
}) => (
  <Link
    to={path}
    className={clearClassNames(
      `action-btn ${className} ${colors[color] ? colorClasses[color] : ''}`
    )}
    title={title}
    disabled={disabled}
    {...props}>
    {children}
  </Link>
)

export default ActionButton
