// @ts-check
import { cloneDeep } from 'lodash'

import { toastTypes } from '../contexts/ToastContext'

/**
 * Handles API errors by setting them in the state.
 * @param {Function} setApiErrors - A callback function that sets the API errors in the state.
 * @returns {Function} - A function that takes in a response object.
 */
export const handleApiErrors = setApiErrors => res => {
  if (!!res.json) {
    setApiErrors(res.json)
  }
}

/**
 * Callback function that handles the response of an asynchronous action.
 * @param {Function} addToast - A function that adds a toast message to the UI.
 * @param {Function} [successCallback] - A callback function that is called when the response is successful.
 * @param {Function} [errorCallback] - A callback function that is called when the response is an error.
 * @returns {Function} - A function that takes in a response object and handles it accordingly.
 */
export const callback =
  (addToast, successCallback = () => {}, errorCallback = () => {}) =>
  (res, values) => {
    if (!!res.error) {
      addToast(res.message, {
        type: toastTypes.error
      })
      errorCallback(res)
    } else {
      addToast('', { type: toastTypes.success })
      successCallback(res, values)
    }
  }

/**
 *
 * @param {string} url - The URL to make the API call to.
 * @param {Function} apiCall - API call function (apiPost, apiPut)
 * @param {Function} [callback] - An optional callback function to be called after the API call.
 * @param {Function} [formatValuesForApi] - An optional function that can be used to format the values before they are passed to the API call.
 * @returns {Function} - Returns an async function that makes an API call.
 */
export const handleSubmitFactory =
  (url, apiCall, callback = () => {}, formatValuesForApi) =>
  async values => {
    const valuesCopy = cloneDeep(values) // to not mutate Formik's values object
    let data

    if (typeof formatValuesForApi === 'function') {
      data = await formatValuesForApi(valuesCopy)
    } else {
      data = { ...valuesCopy }
    }

    // the await below is needed for proper formikProps.isSubmitting flag state.
    await apiCall(url, data, res => callback(res, values))
  }
