/**
 * Truncates a string to a specified length
 * @param {string} str - The string to be truncated.
 * @param {number} maxLength - The maximum length of the truncated string. Default value is 30.
 * @return {string} - The truncated string with an ellipsis at the end if the string is longer than the maximum length, or the original string if it is shorter.
 */
export const truncateStr = (str = '', maxLength = 30) =>
  !!str && str.length > maxLength ? str.slice(0, maxLength) + '...' : str

export const formatJsonToDisplayAsString = json => {
  const codeStrTmp = JSON.stringify(json, null, 2).replaceAll('\\"', '"')

  const result =
    codeStrTmp.startsWith('"') && codeStrTmp.endsWith('"')
      ? codeStrTmp.substring(1, codeStrTmp.length - 1)
      : codeStrTmp

  return result
}

/**
 * Returns the full name of a user
 * @param {object} user - The user object
 * @param {string} user.firstName - The first name of the user
 * @param {string} user.lastName - The last name of the user
 * @returns {string} - The full name of the user
 */
export const getUserFullName = user => user.lastName + ' ' + user.firstName

/**
 * Removes extra whitespaces from class names
 * @param {string} className - The class name string to be cleared
 * @returns {string} - A cleaned up class name string
 */
export const clearClassNames = className =>
  className.replace(/\s+/g, ' ').trim()

/**
 *
 * @param {*} array
 * @param {sting} property
 * @returns {string}
 * @example
 * valuesToString([{ name: 'a' }, { name: 'b' }], 'name') // 'a, b'
 */

export const arrayValuesToString = (array, property) => {
  return array.map(item => item[property]).join(', ')
}
