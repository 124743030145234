import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Form from './Form'

import { validationSchema } from './helpers'
import { formModes } from '../../CommonForm'
import {
  handleSubmitFactory,
  callback,
  handleApiErrors
} from '../../../helpers/forms'
import { getUrl } from '../../../apiUrls'
import { apiPostWithToken } from '../../../api'
import { useSlidePanelContext, useToastContext, useData } from '../../../hooks'
import { clearStoredValues } from '../../../hooks/useFormPersist'

const STORE_KEY = 'contractTypes_create'

const Create = ({ refreshList = () => {} }) => {
  const { t } = useTranslation()
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()
  const contractTypesUrl = getUrl.contractTypes()
  const { items: contractTypes } = useData(contractTypesUrl)

  const [apiErrors, setApiErrors] = useState(null)

  const initialValues = {
    name: '',
    active: true,
    defaultEntity: false
  }
  const successCallback = () => {
    refreshList()
    closeSlidePanel()
    clearStoredValues(STORE_KEY)
  }

  const url = getUrl.contractTypeCreate()

  const handleSubmit = handleSubmitFactory(
    url,
    apiPostWithToken,
    callback(addToast, successCallback, handleApiErrors(setApiErrors))
  )

  return (
    !!contractTypes && (
      <Form
        initialValues={initialValues}
        validationSchema={() => validationSchema(t)}
        handleSubmit={handleSubmit}
        mode={formModes.create}
        storeKey={STORE_KEY}
        apiErrors={apiErrors}
      />
    )
  )
}

export default Create
