// @ts-check
import React from 'react'
import { DeleteBtn } from '../../Common/Ctas'
import DownloadFileBtn from './DownloadFileBtn'
import { formatFileSize, getFileType, getFileItemClassName } from './helpers'

import './attachments.scss'

/**
 * @typedef {import('../../Issues/types').AttachmentInfoUI} AttachmentInfoUI
 * @typedef {import('../../Issues/types').ItemProps} ItemProps
 * @typedef {import('../../Issues/types').ListProps} ListProps
 */

/**
 * @param {ItemProps} props
 * @returns {JSX.Element}
 */
const Item = ({ item, handleRemove, canDownload, canDelete }) => {
  const { id, fileName, size } = item

  return (
    <li className='vf-file-list__item'>
      <div>
        <div className={`vf-file-list__link ${getFileItemClassName(item)}`}>
          {fileName}
        </div>
        <div className='vf-file-list__item-description'>
          ({getFileType(item).toUpperCase()}, {formatFileSize(size)})
        </div>
      </div>

      <div className='vf-file-list__item__ctas'>
        {canDownload && <DownloadFileBtn id={id} />}

        {canDelete && <DeleteBtn active onClick={handleRemove} />}
      </div>
    </li>
  )
}

/**
 * @param {ItemProps} props
 * @returns {JSX.Element}
 */
const ItemContainer = ({ item, handleRemove, canDownload, canDelete }) => {
  const handleRemoveItem = () => handleRemove(item)

  return (
    <Item
      item={item}
      handleRemove={handleRemoveItem}
      canDownload={canDownload}
      canDelete={canDelete}
    />
  )
}

/**
 * @param {ListProps} props
 * @returns {JSX.Element}
 */
const List = ({
  items = [],
  handleRemove = () => {},
  canDownload = false,
  canDelete = false
}) => {
  return (
    <ul className='vf-file-list'>
      {items.map(item => (
        <ItemContainer
          key={item.id}
          item={item}
          handleRemove={handleRemove}
          canDownload={canDownload}
          canDelete={canDelete}
        />
      ))}
    </ul>
  )
}

export default List
