import Select, { components } from 'react-select'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import Message, { messageTypes } from '../../Common/Message'
import { customStyles } from './MultiSelectStyles'

const DefaultOptionWrapper = ({ option }) => <div>{option.label}</div>

const MultiValueRemove = ({ readOnly = false, ...props }) =>
  readOnly ? (
    <div style={{ paddingRight: 3 }}></div>
  ) : (
    <components.MultiValueRemove {...props} />
  )

export const MultiSelect = ({
  value,
  name,
  options = [],
  optionWrapper: OptionWrapper = DefaultOptionWrapper,
  label,
  disabled = false,
  readOnly = false,
  hasError = false,
  loading = false,
  handleChange = () => {},
  handleBlur = () => {}
}) => {
  const { t } = useTranslation()

  return (
    <div data-test='multi-select'>
      <label htmlFor={name} className='mt-2'>
        {label}
      </label>

      <Select
        name={name}
        closeMenuOnSelect={false}
        value={value}
        id={name} // for tests
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        readOnly={readOnly}
        formatOptionLabel={option => <OptionWrapper option={option} />}
        isMulti
        hasError={hasError}
        components={{
          ClearIndicator: props =>
            props.selectProps.menuIsOpen ? null : (
              <div data-test='multi-select-clearAll'>
                <components.ClearIndicator {...props} />
              </div>
            ),
          DropdownIndicator: props =>
            readOnly ? null : (
              <div data-test='multi-select-chevron'>
                <components.DropdownIndicator {...props} />
              </div>
            ),
          MultiValueRemove: props => (
            <MultiValueRemove readOnly={readOnly} {...props} />
          ),
          IndicatorsContainer: props =>
            readOnly ? null : <components.IndicatorsContainer {...props} />
        }}
        styles={customStyles}
        placeholder={
          !options.length && loading
            ? t('common.labels.loading')
            : readOnly
            ? null
            : t('common.labels.selectOptionMulti')
        }
        isDisabled={disabled}
        isSearchable={!readOnly}
        menuIsOpen={readOnly ? false : undefined}
        menuPlacement='auto'
        isClearable={!readOnly}
        isOptionDisabled={option => option.disabled}
      />
    </div>
  )
}

const MultiSelectWithFormik = ({ ...props }) => {
  const [field, meta] = useField(props)
  const { name } = field
  const { error, touched } = meta

  const hasError = touched && error

  const { setFieldValue, validateField } = useFormikContext()

  const handleChange = value => {
    setFieldValue(name, value || [])
  }

  const handleBlur = () => {
    validateField(name)
  }

  return (
    <>
      <MultiSelect
        {...field}
        {...props}
        handleChange={handleChange}
        handleBlur={handleBlur}
        hasError={hasError}
      />

      {hasError && <Message type={messageTypes.error}>{error}</Message>}
    </>
  )
}

export default MultiSelectWithFormik
