import React from 'react'
import PropTypes from 'prop-types'

export const messageTypes = {
  success: 'success',
  error: 'error',
  warning: 'warning'
}

const Message = ({ type = messageTypes.success, children }) => (
  <div className={`vf-utility__${type}`} data-test={`${type}-message`}>
    {children}
  </div>
)

Message.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node
}

export default Message
