import Form from './Form/Form'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import { formatItemForForm } from './Form/helpers'
import { formModes } from '../CommonForm'
import getNotification from '../../helpers/apiNotifications'
import Loader from '../Common/Loader'

const Details = ({ id }) => {
  const url = getUrl.customerTypeById(id)
  const { loading, items: data, error } = useData(url)

  const initialValues = !!data && formatItemForForm(data)

  const Info = getNotification(loading, error, data, Loader)

  return Info ? (
    Info
  ) : (
    <Form initialValues={initialValues} mode={formModes.details} readOnly />
  )
}

export default Details
