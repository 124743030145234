import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { TextInput, TextArea, formModes } from '../../CommonForm'
import {
  CustomerTypePicker,
  ContractTypesPicker,
  UsersPicker
} from '../../FormPickers'
import { useData, useFormPersist, useSlidePanelContext } from '../../../hooks'
import { FormButtons } from '../../Common/Ctas'
import {
  getFieldLabelRequired,
  getFormattedOptions
} from '../../CommonForm/helpers'
import { getUrl } from '../../../apiUrls'
import { isEqual } from 'lodash'
import { ContractTypePicker } from '../../FormPickers/ContractTypePicker'

const FormComponent = ({
  initialValues,
  values,
  apiErrors,
  dirty,
  isSubmitting,
  setValues,
  setErrors,
  resetForm,
  setFieldValue,
  readOnly = false,
  mode = formModes.create,
  storeKey,
  customerTypes
}) => {
  const { t } = useTranslation()

  const { RecoveredDataMessage } = useFormPersist(
    storeKey,
    values,
    initialValues,
    setValues,
    resetForm,
    {
      disablePersistence: mode !== formModes.create,
      message: t('messages.unsavedData'),
      clearButtonText: t('messages.clearForm')
    }
  )

  const { closeSlidePanel } = useSlidePanelContext()

  useEffect(() => {
    if (!!apiErrors) {
      setErrors(apiErrors)
    }
  }, [apiErrors, setErrors])

  const getLabel = (label, required = false) =>
    getFieldLabelRequired(t(`customers.form.labels.${label}`), required, mode)

  const handleCustomerTypeChange = customerTypeId => {
    const selectedCustomerType = customerTypes?.find(
      item => item.id === customerTypeId
    )

    setFieldValue(
      'contractTypeIds',
      getFormattedOptions(selectedCustomerType.contractTypes)
    )
  }

  useEffect(() => {
    if (
      !values.contractTypeIds.some(
        item => item.value === values.defaultContractTypeId
      )
    ) {
      setFieldValue('defaultContractTypeId', '')
    }
  }, [values.contractTypeIds, values.defaultContractTypeId, setFieldValue])

  return (
    <>
      <RecoveredDataMessage />

      <Form>
        <TextInput
          name='name'
          label={getLabel('name', true)}
          readOnly={readOnly}
        />

        <TextInput
          name='abbreviation'
          label={getLabel('abbreviation', true)}
          readOnly={mode !== formModes.create}
          disabled={mode === formModes.edit}
        />

        <TextArea
          name='description'
          label={getLabel('description')}
          rows={4}
          readOnly={readOnly}
          maxLength={255}
        />

        <TextInput
          name='bastId'
          label={getLabel('bastId', true)}
          readOnly={readOnly}
        />

        <CustomerTypePicker
          name='customerTypeId'
          label={getLabel('customerType', true)}
          readOnly={readOnly}
          selectedValue={values.customerTypeId}
          onChange={handleCustomerTypeChange}
        />

        <ContractTypesPicker
          name='contractTypeIds'
          label={getLabel('contractTypeIds', true)}
          readOnly={readOnly}
        />

        <ContractTypePicker
          name='defaultContractTypeId'
          label={getLabel('defaultContractTypeId', true)}
          options={values.contractTypeIds}
          readOnly={readOnly}
          disabled={!values.contractTypeIds.length}
        />

        <UsersPicker
          name='userIds'
          label={getLabel('userIds', false)}
          readOnly={readOnly}
        />

        {!readOnly && (
          <FormButtons
            handleCancel={closeSlidePanel}
            isSubmitting={isSubmitting}
            submitDisabled={isSubmitting || !dirty}
            className='mt-2'
            t={t}
          />
        )}
      </Form>
    </>
  )
}

const FormContainer = ({
  initialValues,
  validationSchema,
  handleSubmit,
  ...props
}) => {
  const customerTypesUrl = getUrl.customerTypes()
  const { items: customerTypes } = useData(customerTypesUrl)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formikProps => (
        <FormComponent
          {...formikProps}
          {...props}
          customerTypes={customerTypes}
        />
      )}
    </Formik>
  )
}

export default FormContainer
