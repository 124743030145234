// @ts-check
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { clearClassNames } from '../../../helpers/strings'
import './button.scss'

const colors = {
  primary: 'primary',
  secondary: 'secondary'
}

const sizes = {
  regular: 'regular',
  large: 'large'
}

/* #region */
const RouterLink = ({ path, children, ...other }) => (
  <Link to={path} {...other}>
    {children}
  </Link>
)

RouterLink.propTypes = {
  text: PropTypes.string
}
/* #endregion */

/* #region */
const Anchor = ({ path, children, ...other }) => (
  <a href={path} {...other}>
    {children}
  </a>
)

Anchor.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string
}
/* #endregion */

const basicCssClasses = {
  color: {
    primary: 'vf-btn__primary',
    secondary: 'vf-btn__secondary'
  }
}

const outlineCssClasses = {
  color: {
    primary: 'vf-btn__dark--outline',
    secondary: 'vf-btn__secondary--outline'
  }
}

const VfLinkButton = ({
  outlined,
  color,
  className,
  path,
  disabled,
  size,
  ...other
}) => {
  const cssClasses = outlined ? outlineCssClasses : basicCssClasses

  const allClassNames = clearClassNames(`vf-btn ${className}
  ${color === colors.primary ? cssClasses.color.primary : ''}
  ${color === colors.secondary ? cssClasses.color.secondary : ''} ${
    disabled && 'disabled'
  } ${size === sizes.large ? 'vf-btn--lg' : ''}`)

  const props = {
    className: allClassNames,
    path,
    role: 'button',
    'aria-pressed': 'true'
  }

  if (path.includes('tel') || path.includes('mailto')) {
    return <Anchor {...other} {...props} />
  }
  return <RouterLink {...other} {...props} />
}

VfLinkButton.propTypes = {
  outlined: PropTypes.bool,
  text: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  path: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string
}

VfLinkButton.defaultProps = {
  text: '',
  color: colors.secondary,
  className: '',
  path: '#',
  disabled: false,
  size: sizes.regular
}

export default VfLinkButton
