import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUserContext } from '../hooks'
import { useConsent } from '../components/Consent/helpers'

const NoConsentTemplate = ({ handleConsent }) => {
  const { t } = useTranslation()

  return (
    <div className='page-not-found__container centered'>
      <h2>{t('titles.accessDenied')}</h2>
      <p>
        {t('consent.accessDeniedMsg')}{' '}
        <span
          className='vf-text--secondary'
          style={{ cursor: 'pointer' }}
          onClick={handleConsent}>
          {t('consent.label')}
        </span>
        .
      </p>
    </div>
  )
}

const Page = () => {
  const { user } = useCurrentUserContext()
  const [accessDenied, setAccessDenied] = useState(true)
  const clearErrorMsg = () => setAccessDenied(false)

  // display Consent modal
  const [refreshPage, setRefreshPage] = useState(false)
  const toggleRefreshPage = () => setRefreshPage(!refreshPage)

  useConsent(user, clearErrorMsg)

  return accessDenied && <NoConsentTemplate handleConsent={toggleRefreshPage} />
}

export default Page
