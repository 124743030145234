// @ts-check
import * as Yup from 'yup'
import { cloneDeep } from 'lodash'

import {
  convertFileIntoBase64,
  getMimeTypeFromFileName
} from '../../../helpers/files'
import { formModes } from '../../CommonForm'
import { issueStatuses } from '../helpers'
import { getUrl } from '../../../apiUrls'
import { apiPostWithToken, apiDeleteWithToken } from '../../../api'
import { priorityEnums } from '../Details/helpers'
import { isItemActive } from '../../ContractTypes/helpers'

/**
 * @typedef {import('../../../types').IssueDto} IssueDto
 * @typedef {import('../../../types').IssueTypeDto} IssueTypeDto
 * @typedef {import('../../../types').AttachmentInfoDto} AttachmentInfoDto
 * @typedef {import('../../../types').CreateAttachmentDto} CreateAttachmentDto
 * @typedef {import('./../types').AttachmentInfoUI} AttachmentInfoUI
 * @typedef {import('../../../types').CreateIssueDto} CreateIssueDto
 * @typedef {import('../../../types').PatchIssueDto} PatchIssueDto
 */

// #region valdiations

// to validate a number with yup and still allow an empty string
const transformEmptyStringToNull = (value, original) => {
  return original === '' ? null : value
}

const createValidator = t =>
  Yup.object({
    title: Yup.string()
      .max(100, t('common.form.validation.maxLength', { count: 100 }))
      .required(t('issues.form.validation.title.required')),
    description: Yup.string()
      .max(1000, t('common.form.validation.maxLength', { count: 1000 }))
      .required(t('issues.form.validation.description.required')),
    customerId: Yup.number()
      .nullable()
      .transform(transformEmptyStringToNull)
      .required(t('issues.form.validation.customerId.required')),
    contractTypeId: Yup.number()
      .nullable()
      .transform(transformEmptyStringToNull)
      .required(t('issues.form.validation.contractTypeId.required')),
    issueTypeId: Yup.number()
      .nullable()
      .transform(transformEmptyStringToNull)
      .required(t('issues.form.validation.issueTypeId.required')),
    priority: Yup.string()
      .oneOf(priorityEnums)
      .required(t('issues.form.validation.priority.required')),
    bastRefNo: Yup.string(),
    status: Yup.string()
      .nullable()
      .oneOf(Object.values(issueStatuses))
      .required(t('issues.form.validation.status.required')),
    street: Yup.string().required(t('issues.form.validation.street.required')),
    number: Yup.string().required(t('issues.form.validation.number.required')),
    city: Yup.string().matches(
      /^[^\d]+$/,
      t('issues.form.validation.city.noNumbers')
    ),
    zip: Yup.string()
      .matches(/^[0-9]+$/, t('issues.form.validation.zip.invalid'))
      .transform(value => value.replace(/\s/g, '')) // ignore spaces
      .min(5, t('issues.form.validation.zip.invalid'))
      .max(5, t('issues.form.validation.zip.invalid')),
    attachments: Yup.array(),
    faultReasonId: Yup.number()
  })

// at the moment only attachments can be edited
const editValidator = () =>
  Yup.object({
    attachments: Yup.array()
  })

/**
 * @param {Function} t - A translation function used to translate messages.
 * @param {string} mode - The form mode.
 */
export const validationSchema = (t, mode = formModes.create) => {
  switch (mode) {
    case formModes.create:
      return createValidator(t)
    case formModes.edit:
      return editValidator()
    default:
      break
  }
}

// #endregion

// #region formatting for UI
/**
 * Formats attachments for UI display.
 * @param {AttachmentInfoDto[]} attachments - The attachments to format.
 * @returns {AttachmentInfoUI[]} - The formatted attachments, with each item containing
 * an `id`, `fileName`, and `size` property.
 */
export const formatAttachmentsForUi = attachments => {
  return (
    attachments?.map(({ id, filename, sizeInBytes, sourceSystem }) => ({
      id,
      fileName: filename,
      size: sizeInBytes,
      sourceSystem
    })) ?? []
  )
}

/**
 * Formats the given value as a Swedish zip code, with the format '999 99'.
 * @param {string} value - The value to format.
 * @returns {string} The formatted zip code.
 */
export const formatZipCodeValue = (value = '') =>
  value
    ?.replace(/[^0-9]/g, '') // to limit input to only numbers and spaces
    ?.replace(/^(\d{3})(\d)/, '$1 $2') // add a space after the third character
    ?.slice(0, 6) ?? ''

/**
 * @param {IssueDto} item
 * @returns {Object}
 */
export const formatItemForForm = item => ({
  id: item?.id,
  city: item?.locationAddress.city,
  customerId: item?.customer?.id,
  contractTypeId: item?.contractType.id,
  description: item?.description,
  number: item?.locationAddress.number,
  priority: item?.priority,
  status: item?.status,
  street: item?.locationAddress.street,
  title: item?.title,
  woId: item?.woId || '',
  issueTypeId: item?.issueType?.id,
  zip: formatZipCodeValue(item?.locationAddress.zip),
  attachments: formatAttachmentsForUi(item?.attachmentsInfo),
  comments: item?.comments,
  bastRefNo: item?.bastRefNo,
  faultReasonId: item?.faultReason?.id || ''
})
// #endregion

// #region formatting for API
const SOURCE_SYSTEM = 'BAST_KUND'

/**
 * Formats a comment for API submission.
 * @param {string} comment - The comment to format.
 * @param {string} issueId - The ID of the issue.
 * @returns {Object} - An object containing the formatted comment, with properties
 * for `content`, `authorId`, and `sourceSystem` (hardcoded to 'BAST_KUND').
 */
export const formatCommentForApi = (comment, issueId) => ({
  issueId,
  content: comment,
  sourceSystem: SOURCE_SYSTEM
})

export const formatIssueForApi = issue => {
  // for adding comments or changing status
  const data = {
    id: issue.id,
    issueTypeId: issue.issueType.id,
    title: issue.title,
    description: issue.description,
    priorityId: issue.priority.id,
    locationAddress: issue.locationAddress,
    contractTypeId: issue.contractType.id,
    status: issue.status
  }

  // comment or status are added/updated in corresponding handleSubmits

  return data
}

/**
 * Formatting formik values (create)
 * @param {*} values
 * @returns {Promise<CreateIssueDto>}
 */
export const formatFormValuesForApiCreate = async values => {
  let data = cloneDeep(values)
  const { street, number, city, zip, attachments = [] } = values

  data.locationAddress = {
    street,
    number,
    city,
    zip: zip.replace(/\s/, '')
  }

  data.attachments = await Promise.all(
    attachments.map(formatIssueAttachmentForApi)
  )

  delete data.street
  delete data.number
  delete data.city
  delete data.zip

  return data
}

/**
 * Formatting formik values (edit)
 * @param {*} values
 * @returns {Promise<PatchIssueDto>}
 */
export const formatFormValuesForApiEdit = async values => {
  const { id, attachments: attachmentsUi } = values
  const attachments = await Promise.all(
    attachmentsUi
      // when adding file to existing Issue, existing attachments are objects, not Files,
      // so we need to filter them out as no need to upload the exsiting files to BE
      ?.filter(item => item instanceof File)
      .map(formatIssueAttachmentForApi)
  )

  return { id, attachments }
}
// #endregion

// #region files upload

/**
 * Formats a File object for API consumption.
 * @param {File} file - The File object to format.
 * @returns {Promise<CreateAttachmentDto>} A Promise that resolves with an object containing the formatted file data.
 */
export const formatIssueAttachmentForApi = async file => {
  const base64 = await convertFileIntoBase64(file)
  const bodyAsBase64 = base64?.split(',')[1]
  const { name: filename, type, size } = file
  // for some files, e.g. vsdx, type is empty, so we need to get it from somewhere else
  const mimeType = !!type ? type : getMimeTypeFromFileName(filename)

  return {
    filename,
    bodyAsBase64,
    mimeType,
    size,
    sourceSystem: SOURCE_SYSTEM
  }
}

/**
 * @typedef {Object} ApiFile
 * @property {number} id
 * @property {string} filename
 * @property {number} sizeInBytes
 */

/**
 * Uploads files and deletes files attached to an issue in the API.
 * @param {number} issueId - The ID of the issue to handle attachments for.
 * @param {File[]} filesToUpload - An array of files to upload.
 * @param {ApiFile[]} filesToDelete - An array of file objects to delete.
 * @param {() => {}} successCallback - A callback function to be called if all files are successfully uploaded and deleted.
 * @param {() => {}} errorCallback - A callback function to be called if any error occurs during the uploading or deleting process.
 * @param {() => {}} finalCallback - A callback function to be called after all promises are settled (either fulfilled or rejected).
 * @param {function} t - A translation function used to translate messages.
 * @returns {Promise<void>} A promise that resolves after all files are uploaded and deleted, or rejects if any error occurs during the uploading or deleting process.
 */
export const handleIssueAttachments = async (
  issueId,
  filesToUpload,
  filesToDelete,
  successCallback,
  errorCallback,
  finalCallback,
  t
) => {
  const createAttachmentUrl = getUrl.attachmentCreate()

  const uploadFilesPromises = filesToUpload.map(async file => {
    const formattedFile = await formatIssueAttachmentForApi(file)

    return new Promise((resolve, reject) => {
      apiPostWithToken(createAttachmentUrl, formattedFile, res => {
        if (!!res.error) {
          reject(t('messages.fileUploadingError', { fileName: file.name }))
        } else {
          resolve(res)
        }
      })
    }).catch(errorCallback) // for separate errors for each file
  })

  const deleteFilesPromises = filesToDelete.map(file => {
    const deleteAttachmentUrl = getUrl.attachmentDelete(file.id)

    return new Promise((resolve, reject) => {
      apiDeleteWithToken(deleteAttachmentUrl, res => {
        if (!!res.error) {
          reject(t('messages.fileDeletingError', { fileName: file.filename }))
        } else {
          resolve(res)
        }
      })
    }).catch(errorCallback) // for separate errors for each file
  })

  const filesCount = filesToUpload.length + filesToDelete.length

  await Promise.all([...uploadFilesPromises, ...deleteFilesPromises])
    .then(results => {
      // an array with the result for each promise, the result is "undefined" if there is an error
      const noErrors = results.filter(Boolean).length === filesCount

      if (noErrors) {
        successCallback() // success callback only if no errors occurred
      }
    })
    .finally(finalCallback)
}
// #endregion

// #region permissions
/** Only possible edit now is to add attachments. This logic explained in #50058 */

export const getCanEditIssue = issue =>
  issue?.status !== issueStatuses.closed &&
  issue?.status !== issueStatuses.cancelled

/** This logic explained in #50058 */
export const getCanEditAttachments = (formMode = formModes.create, issue) =>
  (formMode === formModes.create || formMode === formModes.edit) &&
  getCanEditIssue(issue)

export const getCanAddNewComment = issue =>
  issue.status !== issueStatuses.closed &&
  issue.status !== issueStatuses.cancelled
// #endregion

// #region helpers

/**
 * @param {number} customerTypeId
 * @returns {(item: IssueTypeDto) => boolean}
 */
const filterIssueTypesByCustomerTypeId =
  customerTypeId =>
  ({ customerTypes }) =>
    customerTypes.some(({ id }) => id === customerTypeId)

/**
 * Get IssueTypesByCusomerTypeId
 * @param {IssueTypeDto[]} issueTypes
 * @param {number} customerTypeId
 * @returns {IssueTypeDto[]} List of Issue types filterred by given CustomerType id (inactive CustomerTypes are filterred out at first).
 */
export const getIssueTypesByCustomerTypeId = (issueTypes, customerTypeId) => {
  return (
    issueTypes
      // get rid of inactive CustomerTypes
      .map(issueType => ({
        ...issueType,
        customerTypes: issueType.customerTypes.filter(isItemActive)
      }))
      // get IssueTypes based on active CustomerTypes filterred by CustomerTypeId
      .filter(filterIssueTypesByCustomerTypeId(customerTypeId))
  )
}
// #endregion

// #region attachments

export const systems = {
  bastKund: { value: 'BAST_KUND', label: 'BÄST KUND' },
  bast: { value: 'BAST', label: 'BÄST' }
}

/**
 * @template T
 * @param {string} system
 * @returns {(attachments: T[]) => T[]} List of attachments filterred by given system (inactive CustomerTypes are filterred out at first
 */
const getAttachmentsBySystem =
  system =>
  (attachments = []) =>
    attachments.filter(({ sourceSystem }) => sourceSystem === system)

export const getBastKundAttachments = getAttachmentsBySystem(
  systems.bastKund.value
)
export const getBastAttachments = getAttachmentsBySystem(systems.bast.value)

// #endregion
