import { useTranslation } from 'react-i18next'

import { PageTitle } from '../components/Template'
import List from '../components/IssueTypes/ListContainer'

const Page = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageTitle>{t('titles.issueTypes')}</PageTitle>

      <List />
    </>
  )
}

export default Page
