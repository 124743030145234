import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BasicTextInputWithLabel as TextInput } from '../../../components/CommonForm/TextInput'
import { pageStates } from '../Login'
import { Error } from '../../../helpers/apiNotifications'
import { isValidEmail, isVattenfallEmail } from '../../../helpers/validators'
import { getUrl } from '../../../apiUrls'

const Form = ({ callback }) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [hasError, setHasError] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const handleLoginNoAdUser = email => {
    // validate input
    if (!isValidEmail(email) || isVattenfallEmail(email)) {
      setHasError(true)
      return
    }

    // disable submit button
    setInProgress(true)

    // submit data
    const url = getUrl.authInvitation(null, { email })

    fetch(url).then(res => {
      // handle callback
      setEmail(false)
      callback(pageStates.confirmation)
      // enable submit button
      setInProgress(false)
    })
  }

  const handleOnChange = e => {
    const email = e.target.value
    // remove error message if email is valid
    if (hasError && isValidEmail(email) && !isVattenfallEmail(email)) {
      setHasError(false)
    }
    setEmail(email)
  }

  return (
    <>
      <div className='login-input-container'>
        <TextInput
          labelText={t('auth.emailInputLabel')}
          maxLength={55}
          type='email'
          value={email}
          onChange={handleOnChange}
          hasError={hasError}
          autoComplete='on'
        />
        <button
          className={`${hasError ? 'error' : ''}`}
          onClick={() => handleLoginNoAdUser(email)}
          disabled={inProgress}>
          &rarr;
        </button>
      </div>
      {hasError && (
        <Error style={{ textAlign: 'left' }}>{t('auth.error')}</Error>
      )}
    </>
  )
}

export default Form
