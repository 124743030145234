import { useTranslation } from 'react-i18next'

import { ReactComponent as DownloadIcon } from './icons/VF_download_rgb.svg'
import ButtonTemplate from './ButtonTemplate'

const DownloadBtn = ({ ...props }) => {
  const { t } = useTranslation()

  return (
    <ButtonTemplate
      text={t('buttons.download')}
      icon={DownloadIcon}
      {...props}
    />
  )
}

export default DownloadBtn
