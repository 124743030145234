// @ts-check
import { getFormattedDateWithTime } from '../../../../helpers/dates'
import { getStatusLabel } from '../../helpers'
/**
 * @typedef {import("./types").LogItemUI} LogItemUI
 * @typedef {import("./types").LogItemBE} LogItemBE
 * @typedef {import("./types").LogItemUIExtended} LogItemUIExtended
 */

export const changeLogActions = {
  addComment: 'ADD_COMMENT',
  addAttachment: 'ADD_ATTACHMENT',
  statusChange: 'STATUS_CHANGE'
}

const topLineTextTranslationKeys = {
  [changeLogActions.addComment]: 'issues.history.topLineTextOnAddComment',
  [changeLogActions.addAttachment]: 'issues.history.topLineTextOnAddAttachment',
  [changeLogActions.statusChange]: 'issues.history.topLineTextOnStatusChange'
}

/**
 * @param {function} t
 * @returns {(item: LogItemUI) => LogItemUIExtended } Info to be displayed for user. Create info for create event and Update for other.
 */
export const getTopLineContent =
  t =>
  /**
   * @param {LogItemUI} item
   * @returns {LogItemUIExtended}
   */
  item => {
    const { id, action, author, statusPrevious, statusCurrent } = item
    const translationKey = topLineTextTranslationKeys[action]

    const topLineContent = t(translationKey, {
      id,
      author,
      statusPrevious,
      statusCurrent
    })

    return { ...item, topLineContent }
  }

/**
 * @param {function} t
 * @returns {(item: LogItemBE, idx: number) => LogItemUI} formatted item
 */
export const transformBEtoUI = t => (item, idx) => {
  const {
    action,
    audit: { creatorFullName: author, createdDate: date },
    newValue,
    oldValue
  } = item
  const dateUi = getFormattedDateWithTime(date, 'sv-SE')
  const statusCurrentTranslated = getStatusLabel(newValue, t)
  const statusPreviousTranslated = getStatusLabel(oldValue, t)

  return {
    id: idx + 1,
    action,
    author,
    comment: action === changeLogActions.addComment ? newValue : '',
    date,
    statusCurrent: statusCurrentTranslated,
    statusPrevious: statusPreviousTranslated,
    dateUi
  }
}
