import { useState } from 'react'

import './banner.scss'

export const bannerTypes = {
  error: 'error',
  info: 'info',
  success: 'success'
}

const VfBanner = ({
  type = bannerTypes.info,
  handleClose = () => {},
  children,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(true)

  return isVisible ? (
    <div className={`vf-cookie-banner vf-cookie-banner--${type}`} {...props}>
      {children}
      <button
        className='vf-cookie-banner__close-btn'
        onClick={() => {
          setIsVisible(false)
          handleClose()
        }}>
        <span className='vf-icon-close'></span>
      </button>
    </div>
  ) : null
}

export default VfBanner
