import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAccount } from '@azure/msal-react'

import LanguageSwitch from './LanguageSwitch'
import { getExtAccount, getIsExtUser } from '../../AuthNonAdUsers/helpers'
import vfLogo from '../../assets/icons/vf-logo.svg'
import userIcon from '../../assets/icons/VF_user_rgb.svg'

import './header.scss'

const displayLanguageSwitch =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_CUSTOM_ENV === 'dev'

const Header = () => {
  const { t } = useTranslation()
  const accountInfo = useAccount()

  const { name } = getIsExtUser() ? getExtAccount() : accountInfo

  return (
    <header className='topbar'>
      <p className='topbar__title'>{t('systemName')}</p>

      <Link to='/' className='topbar__logo-container'>
        <img src={vfLogo} width='170' alt='vf-logo' />
      </Link>

      <div className='topbar__user-info'>
        {displayLanguageSwitch && (
          <div style={{ marginRight: 20 }}>
            <LanguageSwitch />
          </div>
        )}

        <div
          className='topbar__user-info__container'
          style={{ marginRight: 10 }}>
          {name}
        </div>
        <img src={userIcon} alt='user' />
      </div>
    </header>
  )
}

export default Header
