import { useEffect, useState } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { Navigate } from 'react-router-dom'
import { getExtAccount } from './AuthNonAdUsers/helpers'

const ProtectedRoute = ({ children }) => {
  const [isMsalUser, setIsMsalUser] = useState(null)
  const [isExternalUser, setIsExternalUser] = useState(null)

  const { accounts, inProgress } = useMsal()

  // check if msal user
  useEffect(() => {
    const isMsalUser =
      inProgress === InteractionStatus.None ? !!accounts.length : null

    setIsMsalUser(isMsalUser)
  }, [inProgress, accounts])

  // check if external user (from our API)
  useEffect(() => {
    const extAccount = getExtAccount()

    setIsExternalUser(!!extAccount)
  }, [])

  // !== null - make sure first run is gone and flags have real values (not initial ones)
  const isInitialState = isMsalUser === null || isExternalUser === null
  const isUser = isMsalUser || isExternalUser

  if (!isInitialState && !isUser) {
    return <Navigate to='/login' />
  } else if (!isInitialState && isUser) {
    return <>{children}</>
  }
  return null
}

export default ProtectedRoute
