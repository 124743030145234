import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import { CurrentUserContextProvider } from './contexts/CurrentUserContext'
import { ConfirmModalContextProvider } from './contexts/ConfirmModalContext'
import './i18n'
import reportWebVitals from './reportWebVitals'
import './index.scss'

import { MsalProvider } from '@azure/msal-react'

// MSAL imports
import { EventType } from '@azure/msal-browser'
import { msalInstance } from './auth/authConfig'

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <CurrentUserContextProvider>
        <ConfirmModalContextProvider>
          <App />
        </ConfirmModalContextProvider>
      </CurrentUserContextProvider>
    </MsalProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
