import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { buttonSizes, ButtonTemplate } from '../../Common/Ctas'
import { ReactComponent as CancelIcon } from '../../../assets/icons/VF_cross_rgb.svg'
import { issueStatuses } from '../helpers'
import { getUrl } from '../../../apiUrls'
import { useToastContext } from '../../../hooks'
import { apiPutWithToken, apiPostWithToken } from '../../../api'
import { colors } from '../../Common/ActionButton'
import { handleSubmitFactory, callback } from '../../../helpers/forms'
import { formatCommentForApi } from '../Form/helpers'
import { toastTypes } from '../../../contexts/ToastContext'
import { ConfirmModal } from '../../Common/ConfirmModal'

const allowedStatusChanges = {
  [issueStatuses.open]: [issueStatuses.cancelled],
  [issueStatuses.received]: [issueStatuses.cancelled]
}

const statusButtons = {
  [issueStatuses.cancelled]: {
    label: 'issues.buttons.cancelIssue',
    color: colors.red,
    modalTitle: 'issues.form.titles.cancelIssue',
    commentRequired: true,
    icon: CancelIcon,
    modalCancelButtonText: 'issues.buttons.cancelIssueModal.cancel',
    modalConfirmButtonText: 'issues.buttons.cancelIssueModal.confirm'
  }
}

const ChangeStatusBtn = ({
  item,
  refreshItem = () => {},
  size = buttonSizes.medium,
  ...props
}) => {
  const { t } = useTranslation()

  const { addToast } = useToastContext()
  const [modalHidden, setModalHidden] = useState(true)
  const toggleModal = () => setModalHidden(!modalHidden)

  const [inProgress, setInProgress] = useState(false)
  const [issueStatusChanged, setIssueStatusChanged] = useState(false)

  const successCallback = () => {
    setInProgress(false)
    toggleModal()
    refreshItem()
  }

  const handleComment = comment => {
    if (!!comment) {
      const createCommentUrl = getUrl.commentCreate()

      const updatedComment = {
        ...formatCommentForApi(comment, item.id)
      }

      setInProgress(true)

      handleSubmitFactory(
        createCommentUrl,
        apiPostWithToken,
        callback(addToast, successCallback, () => setInProgress(false))
      )(updatedComment)
    } else {
      successCallback()
    }
  }

  const handleStatusChange = (newStatus, comment = '') => {
    const updateUrl = getUrl.issueUpdate()

    setInProgress(true)

    const updatedItem = {
      id: item.id,
      status: newStatus
    }

    handleSubmitFactory(updateUrl, apiPutWithToken, res => {
      if (!!res.error) {
        setInProgress(false)

        addToast(res.message, {
          type: toastTypes.error
        })
      } else {
        setIssueStatusChanged(true)
        handleComment(comment)
      }
    })(updatedItem)
  }

  const allowedChanges = allowedStatusChanges[item.status]

  return allowedStatusChanges.hasOwnProperty(item.status) ? (
    <>
      {allowedChanges.map(newStatus => {
        const {
          icon,
          label,
          color,
          modalTitle,
          modalCancelButtonText,
          modalConfirmButtonText,
          commentRequired
        } = statusButtons[newStatus]

        return (
          <React.Fragment key={newStatus}>
            <ButtonTemplate
              active={!inProgress}
              size={size}
              icon={icon}
              text={t(label)}
              color={color}
              onClick={toggleModal}
              {...props}
            />
            <ConfirmModal
              title={t(modalTitle)}
              modalHidden={modalHidden}
              toggleModal={toggleModal}
              commentRequired={commentRequired}
              cancelButtonText={t(modalCancelButtonText)}
              confirmButtonText={t(modalConfirmButtonText)}
              inProgress={inProgress}
              handleConfirm={comment => {
                // there can be two API calls: changing issue status and adding a comment (if any)
                // if the status changed without API errors, but there were when adding a comment, confirm button should only handle that comment and not try to `change the status again
                if (!issueStatusChanged) {
                  handleStatusChange(newStatus, comment)
                } else {
                  handleComment(comment)
                }
              }}
            />
          </React.Fragment>
        )
      })}
    </>
  ) : null
}

export default ChangeStatusBtn
