import { useTranslation } from 'react-i18next'

import { ReactComponent as MoreIcon } from './icons/VF_more_rgb.svg'
import ButtonTemplate from './ButtonTemplate'

const CreateBtn = ({ ...props }) => {
  const { t } = useTranslation()

  return (
    <ButtonTemplate text={t('buttons.create')} icon={MoreIcon} {...props} />
  )
}

export default CreateBtn
