import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import FiltersForm from '../../Common/Filters'
import { DatePicker } from '../../CommonForm'
import {
  CustomerPicker,
  IssuePriorityPicker,
  IssueStatusPicker,
  IssueTypePicker
} from '../../FormPickers'
import CreatorPicker from './CreatorPicker'
import { updateFilterFieldsWithQueryParams } from '../../../helpers/searchParams'

import './filters.scss'

const FormFields = ({
  initialValues,
  queryFilters,
  setFieldValue,
  values,
  handleSubmit
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    updateFilterFieldsWithQueryParams(
      initialValues,
      queryFilters,
      setFieldValue
    )
  }, [initialValues, queryFilters, setFieldValue])

  const handleFilterChange = () => {
    // apply filters on every field change
    handleSubmit(values)
  }

  return (
    <div className='issue-filters' data-test='issues-filters'>
      <IssueStatusPicker
        name='status'
        label={t('issues.form.labels.status')}
        onChange={handleFilterChange}
        isClearable
      />

      <CreatorPicker
        name='creator'
        label={t('issues.form.labels.creator')}
        onChange={handleFilterChange}
        isClearable
      />

      <CustomerPicker
        name='customerId'
        label={t('issues.form.labels.customerId')}
        onChange={handleFilterChange}
        isClearable
      />

      <fieldset className='my-2'>
        <legend>{t('common.labels.createDate')}</legend>
        <div className='issue-filters__fields-group'>
          <DatePicker
            name='createDateFrom'
            label={t('common.labels.from')}
            onChange={handleFilterChange}
          />
          <DatePicker
            name='createDateTo'
            label={t('common.labels.to')}
            onChange={handleFilterChange}
          />
        </div>
      </fieldset>

      <fieldset className='my-2'>
        <legend>{t('common.labels.lastUpdateDate')}</legend>
        <div className='issue-filters__fields-group'>
          <DatePicker
            name='lastUpdateDateFrom'
            label={t('common.labels.from')}
            onChange={handleFilterChange}
          />
          <DatePicker
            name='lastUpdateDateTo'
            label={t('common.labels.to')}
            onChange={handleFilterChange}
          />
        </div>
      </fieldset>

      <IssueTypePicker
        name='issueTypeId'
        label={t('issues.form.labels.issueTypeId')}
        onChange={handleFilterChange}
        isClearable
      />

      <IssuePriorityPicker
        name='priority' // TODO: check API integration after changes
        label={t('issues.form.labels.priority')}
        onChange={handleFilterChange}
        isClearable
      />
    </div>
  )
}

const Filters = ({ queryFilters, handleSubmit }) => {
  const initialValues = {
    // TODO: searchText - not implemented in the API yet
    status: '',
    creator: '',
    customerId: '',
    issueTypeId: '',
    createDateFrom: '',
    createDateTo: '',
    lastUpdateDateFrom: '',
    lastUpdateDateTo: '',
    priority: ''
  }

  return (
    <FiltersForm
      toggleFilters
      initialValues={initialValues}
      fields={FormFields}
      handleSubmit={handleSubmit}
      submitButtonHidden
      // FormFields props
      queryFilters={queryFilters}
    />
  )
}

export default Filters
