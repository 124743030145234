import { createContext } from 'react'

import { getUrl } from '../apiUrls'
import { useData } from '../hooks'

const CurrentUserContext = createContext()

export default CurrentUserContext

export const CurrentUserContextProvider = ({ children }) => {
  const url = getUrl.userCurrent()

  const { items: user, errorStatus } = useData(url)

  const isAccessDenied = errorStatus === 403

  return (
    <CurrentUserContext.Provider
      value={{
        user,
        accessDenied: isAccessDenied
      }}>
      {children}
    </CurrentUserContext.Provider>
  )
}
