import { useTranslation } from 'react-i18next'

import { DetailsBtn, buttonSizes } from '../../Common/Ctas'
import { useSlidePanelContext } from '../../../hooks'
import Details from '../Details'
import { CtasGroup } from '.'

const CustomerDetailsBtn = ({
  item,
  size = buttonSizes.small,
  refreshList
}) => {
  const { t } = useTranslation()
  const { openSlidePanel } = useSlidePanelContext()

  return (
    <DetailsBtn
      active
      size={size}
      onClick={() =>
        openSlidePanel({
          title: t('customers.form.titles.details'),
          ctas: (
            <CtasGroup
              item={item}
              size={buttonSizes.medium}
              refreshList={refreshList}
              hideDetailsBtn
            />
          ),
          size: 40,
          content: () => <Details id={item.id} />
        })
      }
    />
  )
}

export default CustomerDetailsBtn
