// @ts-check
import React from 'react'

import i18n from '../i18n'

export const Error = ({ children, className, ...props }) => (
  <div className={`vf-utility__error ${className}`} {...props}>
    {children}
  </div>
)

export const Success = ({ children, className, ...props }) => (
  <div className={`vf-utility__success ${className}`} {...props}>
    {children}
  </div>
)

export const Warning = ({ children, className, ...props }) => (
  <div className={`vf-utility__warning ${className}`} {...props}>
    {children}
  </div>
)

export const types = {
  loading: 'loading',
  error: 'error',
  noData: 'noData'
}

const defaultMessage = {
  [types.error]: 'messages.error',
  [types.noData]: 'messages.noData',
  [types.loading]: 'messages.loading'
}

/**
 * Gets the notification component to display based on the loading, error, and data states.
 * @param {Boolean} loading - Indicates if the data is currently being loaded.
 * @param {Boolean} error - Indicates if an error has occurred while loading the data.
 * @param {any} data - The data being loaded.
 * @param {React.FunctionComponent} [LoaderComponent] - The component to be displayed while the data is loading.
 * @param {Object} [message={}] - The messages to display for loading, error, and no data states.
 * @param {String[]} [disabledTypes=[]] - The types of messages to disable.
 * @returns The notification component to display, or `null` if there is no message to display.
 */
const getNotification = (
  loading,
  error,
  data,
  LoaderComponent,
  message = {},
  disabledTypes = []
) => {
  const { t } = i18n

  if (loading) {
    return LoaderComponent ? (
      <LoaderComponent />
    ) : (
      <Warning>{message.loading || t(defaultMessage.loading)}</Warning>
    )
  } else if (error) {
    return <Error>{message.error || t(defaultMessage.error)}</Error>
  } else if (!data || (Array.isArray(data) && data.length === 0)) {
    if (disabledTypes.includes(types.noData)) {
      return null
    }

    return <Warning>{message.noData || t(defaultMessage.noData)}</Warning>
  }

  return null
}

export default getNotification

export const notifications = {
  Error,
  Success,
  Warning
}
