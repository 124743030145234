import React, { useEffect, useState } from 'react'
import './toasts.scss'

export const toastTypes = {
  error: 'error',
  success: 'success'
}

const Toast = ({ type = '', text = '', timespan = 3000, refresh }) => {
  const message = {
    error: text || 'Error. Data not saved.',
    success: text || 'Success'
  }
  const [localType, setLocalType] = useState(null)
  const [styles, setStyles] = useState(null)

  useEffect(() => {
    if (!!type) {
      setLocalType(type)
    }
    const timer = setTimeout(() => {
      setLocalType('')
    }, timespan)

    const stylesTimer = setTimeout(() => {
      setStyles({ opacity: 0 })
    }, timespan - 1000)

    return () => {
      setStyles({ opacity: 1 })
      clearTimeout(timer)
      clearTimeout(stylesTimer)
    }
  }, [type, text, timespan, refresh])

  if (localType === toastTypes.error) {
    return (
      <p style={styles} className='toast toast-error'>
        {message.error}
      </p>
    )
  } else if (localType === toastTypes.success) {
    return (
      <p style={styles} className='toast toast-success'>
        {message.success}
      </p>
    )
  }

  return null
}

Toast.defaultProps = {
  visible: false
}

export default Toast
