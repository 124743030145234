import { EditBtn, buttonSizes } from '../../Common/Ctas'
import routesUrls, { urlWithParam } from '../../../routesUrls'

const EditIssueBtn = ({ id, size = buttonSizes.small }) => (
  <EditBtn
    active
    size={size}
    path={urlWithParam(routesUrls.issues.edit, { id })}
  />
)

export default EditIssueBtn
