import { useTranslation } from 'react-i18next'

import { CreateBtn, buttonSizes } from '../../Common/Ctas'
import { useSlidePanelContext } from '../../../hooks'
import CreateForm from '../Form/Create'

const CreateCustomerTypeBtn = ({ ...props }) => {
  const { t } = useTranslation()
  const { openSlidePanel } = useSlidePanelContext()

  return (
    <CreateBtn
      active
      size={buttonSizes.large}
      onClick={() =>
        openSlidePanel({
          title: t('customerTypes.form.titles.create'),
          size: 40,
          content: () => <CreateForm {...props} />
        })
      }
    />
  )
}

export default CreateCustomerTypeBtn
