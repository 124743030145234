import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import List from '../Layout'
import { truncateStr, arrayValuesToString } from '../../helpers/strings'
import { CreateBtn, CtasGroup } from './Ctas'
import { getUrl } from '../../apiUrls'
import { useData, useSortableData } from '../../hooks'
import getNotification from '../../helpers/apiNotifications'
import Loader from '../Common/Loader'

const getListConfig = t => ({
  gridTemplateColumns: '2fr 1fr 1fr 2fr 1fr 2fr 1.5fr',
  attributes: [
    {
      label: t('customers.list.headers.name'),
      propName: 'name',
      sortable: true
    },
    {
      label: t('customers.list.headers.abbreviation'),
      propName: 'abbreviation',
      sortable: false
    },
    {
      label: t('customers.list.headers.bastId'),
      propName: 'bastId',
      sortable: false
    },
    {
      label: t('customers.list.headers.description'),
      propName: 'description',
      sortable: false,
      formatFn: val => truncateStr(val, 50),
      style: {
        lineHeight: 1.5
      }
    },
    {
      label: t('customers.list.headers.customerType'),
      propName: 'customerTypeDto.name',
      sortable: false
    },
    {
      label: t('customers.list.headers.contractTypes'),
      propName: 'contractTypeDtos',
      formatFn: val => {
        const contractTypesStr = arrayValuesToString(val, 'name')

        return truncateStr(contractTypesStr, 50)
      },
      sortable: false,
      style: {
        lineHeight: 1.5
      }
    },
    {
      label: t('customers.list.headers.defaultContractType'),
      propName: 'defaultContractTypeId',
      formatFn: (id, item) => {
        const searchedContractTypeItem = item.contractTypeDtos?.filter(
          item => item.id === id
        )
        return searchedContractTypeItem[0]?.name || '-'
      },
      sortable: false
    }
  ]
})

const initialSortConfig = {
  sortBy: 'name',
  order: 'asc'
}

const ListContainer = () => {
  const { t } = useTranslation()
  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => setRefresh(!refresh)

  const url = getUrl.customers()
  const { loading, items, error } = useData(url, refresh)

  const formatProperty = (item, sortBy) => item[sortBy].toUpperCase()
  const {
    items: sortedItems,
    handleSort,
    sortConfig
  } = useSortableData(items || [], initialSortConfig, formatProperty)

  const Info = getNotification(loading, error, items, Loader)

  return (
    <>
      <div className='mb-2 vf-text--right'>
        <CreateBtn refreshList={toggleRefresh} />
      </div>

      {!!Info ? (
        Info
      ) : (
        <List
          items={sortedItems}
          listConfig={getListConfig(t)}
          ctas={CtasGroup}
          refreshList={toggleRefresh}
          handleSort={handleSort}
          sortConfig={sortConfig}
        />
      )}
    </>
  )
}

export default ListContainer
