import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getUrl } from '../../../apiUrls'
import { useData, useToastContext, useSlidePanelContext } from '../../../hooks'
import Form from './Form'
import { formModes, getFormattedOptions } from '../../CommonForm'
import {
  handleSubmitFactory,
  callback,
  handleApiErrors
} from '../../../helpers/forms'
import {
  validationSchema,
  formatValuesForApi,
  formatItemForForm
} from './helpers'
import getNotification from '../../../helpers/apiNotifications'
import Loader from '../../Common/Loader'
import { apiPutWithToken } from '../../../api'

const Edit = ({ id, refreshList = () => {} }) => {
  const { t } = useTranslation()

  const url = getUrl.userById(id)
  const { loading, items: data, error } = useData(url)

  const customersUrl = getUrl.customers()
  const { items: customers } = useData(customersUrl)
  const customersOptions = getFormattedOptions(customers || [])

  const initialValues = formatItemForForm(data, customersOptions)

  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()

  const [apiErrors, setApiErrors] = useState(null)

  const successCallback = () => {
    refreshList()
    closeSlidePanel()
  }

  const updateUrl = getUrl.userUpdate()

  const handleSubmit = handleSubmitFactory(
    updateUrl,
    apiPutWithToken,
    callback(addToast, successCallback, handleApiErrors(setApiErrors)),
    formatValuesForApi
  )

  const Info = getNotification(loading, error, data, Loader)

  return Info ? (
    Info
  ) : (
    <Form
      initialValues={initialValues}
      validationSchema={() => validationSchema(t)}
      handleSubmit={handleSubmit}
      mode={formModes.edit}
      apiErrors={apiErrors}
    />
  )
}

export default Edit
