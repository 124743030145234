import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { TextInput, Checkbox, MSGraphSearch, formModes } from '../../CommonForm'
import { CustomerPicker } from '../../FormPickers'

const FormUserInternal = ({
  handleUserSelect,
  readOnly = false,
  mode = formModes.create,
  getLabel
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext()

  return (
    <>
      {!readOnly && (
        <>
          <MSGraphSearch
            name='user'
            label={t(`common.labels.search`)}
            onChange={handleUserSelect}
          />

          <hr />
        </>
      )}

      <TextInput
        name='name'
        label={getLabel('name', true)}
        readOnly={readOnly}
      />

      <TextInput
        name='firstName'
        label={getLabel('firstName', true)}
        readOnly={readOnly}
      />

      <TextInput
        name='lastName'
        label={getLabel('lastName', true)}
        readOnly={readOnly}
      />

      <TextInput
        name='email'
        label={getLabel('email', true)}
        readOnly={readOnly}
      />

      <CustomerPicker
        name='customerIds'
        isMultiSelect
        label={getLabel('customerIds', values?.isAdmin ? false : true)}
        readOnly={readOnly}
      />

      {mode !== formModes.details && (
        <Checkbox
          name='isAdmin'
          label={t('users.form.labels.isAdmin')}
          readOnly={readOnly}
        />
      )}
    </>
  )
}

export default FormUserInternal
