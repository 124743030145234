import React from 'react'

import './badge.scss'

export const badgeColors = {
  red: 'red',
  blue: 'blue',
  green: 'green',
  grey: 'grey'
}

const Badge = ({ children, color = badgeColors.grey, className, ...props }) => {
  return (
    <span className={`badge badge--${color} ${className}`} {...props}>
      {children}
    </span>
  )
}

export default Badge
