import { useTranslation } from 'react-i18next'

import { BlockBtn, buttonSizes } from '../../Common/Ctas'
import {
  useModalContext,
  useToastContext,
  useSlidePanelContext
} from '../../../hooks'
import { getUrl } from '../../../apiUrls'
import { apiPutWithToken } from '../../../api'
import { callback, handleSubmitFactory } from '../../../helpers/forms'
import { formatValuesToInactivate } from '../Form/helpers'

const InactivateContractTypeBtn = ({
  item,
  refreshList = () => {},
  size = buttonSizes.small
}) => {
  const { t } = useTranslation()
  const openModal = useModalContext()
  const { addToast } = useToastContext()
  const { closeSlidePanel } = useSlidePanelContext()

  const inactivateUrl = getUrl.contractTypeInactivate()

  const successCallback = () => {
    refreshList()
    closeSlidePanel() // the delete button can be accessed in the details panel
  }

  const handleBlock = item =>
    handleSubmitFactory(
      inactivateUrl,
      apiPutWithToken,
      callback(addToast, successCallback),
      formatValuesToInactivate
    )(item)

  return (
    <BlockBtn
      active
      size={size}
      onClick={() =>
        openModal({
          title: t('contractTypes.form.titles.block', {
            name: item.name
          }),
          onConfirm: () => handleBlock(item)
        })
      }
    />
  )
}

export default InactivateContractTypeBtn
