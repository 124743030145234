// @ts-check

const typesConfig = [
  {
    id: 1,
    name: 'Felanmälan',
    customerType: { name: 'Standard', id: 1 },
    fill: '#005c63'
  },
  {
    id: 2,
    name: 'Projekt',
    customerType: { name: 'Standard', id: 1 },
    fill: '#4c8d91'
  },
  {
    id: 3,
    name: 'Kabelfel',
    customerType: { name: 'Standard', id: 1 },
    fill: '#7fadb1'
  },
  {
    id: 4,
    name: 'Rondering',
    customerType: { name: 'Standard', id: 1 },
    fill: '#b2ced0'
  },
  {
    id: 5,
    name: 'Seriebyte',
    customerType: { name: 'Standard', id: 1 },
    fill: '#b2ced0'
  },
  {
    id: 6,
    name: 'Trafikskada',
    customerType: { name: 'Standard', id: 1 },
    fill: '#1e324f'
  },
  {
    id: 25,
    name: 'Kabelutsättning',
    customerType: { name: 'Standard', id: 1 },
    fill: '#616f83'
  },
  {
    id: 7,
    name: 'Övrigt',
    customerType: { name: 'MSB', id: 2 },
    fill: '#f93b18'
  },
  {
    id: 8,
    name: 'Felanmälan FA',
    customerType: { name: 'MSB', id: 2 },
    fill: '#fb755d'
  },
  {
    id: 9,
    name: 'Felanmälan VMA',
    customerType: { name: 'MSB', id: 2 },
    fill: '#fc9d8b'
  },
  {
    id: 10,
    name: 'Tilläggsarbeten',
    customerType: { name: 'MSB', id: 2 },
    fill: '#fdc4b9'
  },
  {
    id: 11,
    name: 'FU Vindkraftanläggning',
    customerType: { name: 'Vattenfall Vindkraft AB Generation', id: 3 },
    fill: '#81e0a8'
  },
  {
    id: 12,
    name: 'Beredning hos kund',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#005c63'
  },
  {
    id: 13,
    name: 'Beställning laddbox ej installation',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#4c8d91'
  },
  {
    id: 14,
    name: 'Felavhjälpning Laddbox',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#7fadb1'
  },
  {
    id: 15,
    name: 'Garanti och reklamation',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#b2ced0'
  },
  {
    id: 16,
    name: 'Standardoffert Kundservice',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#e5eeef'
  },
  {
    id: 17,
    name: 'Installationsärende Laddbox',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#1e324f'
  },
  {
    id: 18,
    name: 'Installationsärende Laddbox Online',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#616f83'
  },
  {
    id: 19,
    name: 'Service åtgärder',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#8e98a7'
  },
  {
    id: 20,
    name: 'Volvo Privatkunder',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#bbc1ca'
  },
  {
    id: 21,
    name: 'Beställning Health Check',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#e8eaed'
  },
  {
    id: 22,
    name: 'Offert till Kund',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#b2b5da'
  },
  {
    id: 23,
    name: 'Installation hos kund',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#7f84c1'
  },
  {
    id: 24,
    name: 'Uppkoppling mot InCharge',
    customerType: {
      name: 'Vattenfall Sales Nordic Products & Solutions',
      id: 14
    },
    fill: '#4c52a8'
  }
]

/**
 * @param {number} id - type id
 * @returns {string | undefined}  hex color
 */
export const getTypeColorById = id => {
  const type = typesConfig.find(type => type.id === id)

  return type?.fill
}
