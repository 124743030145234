import { matchPath } from 'react-router-dom'

const getCrumbs = (routes, location) => {
  const ignoredPaths = ['/', '*']
  const crumbs = []

  routes.forEach((route, idx) => {
    const match = matchPath({ path: route.path, end: false }, location.pathname)

    // to avoid breadcrumb duplication
    // in routes.js config '/create' route needs to be defined before '/:id' route
    const canBeAdded = match =>
      !crumbs.find(crumb => crumb.path === match.pathname)

    if (match && canBeAdded(match) && !ignoredPaths.includes(route.path)) {
      crumbs.push({
        ...route,
        path: Object.keys(match.params).length
          ? Object.keys(match.params).reduce(
              (path, param) => path.replace(`:${param}`, match.params[param]),
              route.path
            )
          : route.path
      })
    }
  })

  return crumbs
}

export default getCrumbs
