import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getUrl } from '../../apiUrls'
import getNotification from '../../helpers/apiNotifications'
import Loader from '../Common/Loader'
import List from '../Layout'
import { useData } from '../../hooks'
import { CreateBtn, CtasGroup } from './Ctas'
import { ReactComponent as CheckIcon } from '../../assets/icons/VF_check_rgb.svg'
import { compareNames, getActiveItems, getInactiveItems } from './helpers'

const listConfig = t => ({
  gridTemplateColumns: '1fr 1fr',
  attributes: [
    { label: t('contractTypes.list.headers.name'), propName: 'name' },
    {
      label: t('contractTypes.list.headers.defaultEntity'),
      propName: 'defaultEntity',
      formatFn: val => (!!val ? <CheckIcon width={14} /> : <span>-</span>)
    }
  ]
})

const ListContainer = () => {
  const { t } = useTranslation()

  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => setRefresh(!refresh)

  const url = getUrl.contractTypes()
  const { loading, items, error } = useData(url, refresh)

  const Info = getNotification(loading, error, items, Loader)

  const sortedItems = items?.slice().sort(compareNames)

  const activeContractTypes = getActiveItems(sortedItems)

  const inactiveContractTypes = getInactiveItems(sortedItems)

  return (
    <>
      <div className='mb-2 vf-text--right'>
        <CreateBtn refreshList={toggleRefresh} />
      </div>

      {Info ? (
        Info
      ) : (
        <>
          <List
            items={activeContractTypes}
            listConfig={listConfig(t)}
            listTitle={`${t('common.labels.active')} (${
              activeContractTypes.length
            })`}
            ctas={CtasGroup}
            refreshList={toggleRefresh}
          />

          {!!inactiveContractTypes.length && (
            <div className='mt-2'>
              <List
                items={inactiveContractTypes}
                listConfig={listConfig(t)}
                listTitle={`${t('common.labels.inactive')} (${
                  inactiveContractTypes.length
                })`}
                ctas={CtasGroup}
                refreshList={toggleRefresh}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ListContainer
