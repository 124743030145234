import { useTranslation } from 'react-i18next'

import { ReactComponent as DetailsIcon } from './icons/VF_my_documents_rgb.svg'
import ButtonTemplate from './ButtonTemplate'

const DetailsBtn = ({ ...props }) => {
  const { t } = useTranslation()

  return (
    <ButtonTemplate text={t('buttons.details')} icon={DetailsIcon} {...props} />
  )
}

export default DetailsBtn
