import React from 'react'

import { Select, getFormattedOptions } from '../../CommonForm'
import { getUserFullName } from '../../../helpers/strings'
import { getUrl } from '../../../apiUrls'
import { useData } from '../../../hooks'
import { sortByParam } from '../../../helpers/lists'

const UsersPicker = ({
  name = 'userIds',
  label,
  isClearable = false,
  ...props
}) => {
  const usersUrl = getUrl.users()
  const { items: users, loading } = useData(usersUrl)
  const usersSorted = sortByParam(users, 'asc', 'lastName')

  const usersOptions = getFormattedOptions(usersSorted || [], {
    valueProp: 'name',
    formatLabelFn: getUserFullName,
    additionalPropsFn: item => ({
      email: item.email
    })
  })

  return (
    <Select
      name={name}
      label={label}
      options={usersOptions}
      isClearable={isClearable}
      loading={loading}
      {...props}
    />
  )
}

export default UsersPicker
