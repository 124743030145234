import { useTranslation } from 'react-i18next'

import { EditBtn, buttonSizes } from '../../Common/Ctas'
import { useSlidePanelContext } from '../../../hooks'
import EditForm from '../Form/Edit'

const EditCustomerBtn = ({ item, size = buttonSizes.small, ...props }) => {
  const { t } = useTranslation()
  const { openSlidePanel } = useSlidePanelContext()

  return (
    <EditBtn
      active
      size={size}
      onClick={() =>
        openSlidePanel({
          title: t('customers.form.titles.edit'),
          size: 40,
          content: () => <EditForm id={item.id} {...props} />
        })
      }
    />
  )
}

export default EditCustomerBtn
