import { useTranslation } from 'react-i18next'
import { userRole } from './helpers'

import './form.scss'

const RoleSwitchButtons = ({ handleUserTypeChange, userSelected }) => {
  const { t } = useTranslation()
  return (
    <div className='user__create__options'>
      <div className='user__create__options__item'>
        <input
          type='radio'
          name={userRole.regular}
          value={userRole.regular}
          id='Regular'
          checked={userSelected === userRole.regular}
          onChange={handleUserTypeChange}
        />
        <label htmlFor='Regular'>{t('buttons.internal')}</label>
      </div>
      <div className='user__create__options__item'>
        <input
          type='radio'
          value={userRole.external}
          name={userRole.external}
          id='External'
          checked={userSelected === userRole.external}
          onChange={handleUserTypeChange}
        />
        <label htmlFor='External'>{t('buttons.external')}</label>
      </div>
    </div>
  )
}

export default RoleSwitchButtons
