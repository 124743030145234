// @ts-check
import { get } from 'lodash'

export const formModes = {
  create: 'create',
  edit: 'edit',
  details: 'details'
}

/**
 * Transforms an array of items into an array of formatted options.
 * @param {Array} items - An array of items to be transformed.
 * @param {Object} options An object with options that can change the behavior of the function.
 * @param {string} [options.labelProp='name'] - The property of the item to use as the label for the option.
 * @param {string} [options.valueProp='id'] - The property of the item to use as the value for the option.
 * @param {Function} [options.formatLabelFn=undefined] - A function that takes an item as an argument and returns a string to use as the label for the option.
 * @param {Function} [options.additionalPropsFn=undefined] - A function that takes an item as an argument and returns its key:value pair
 * @returns {{ label: string, value: any }[]} - An array of objects with the shape { label: string, value: any }.
 */
export const getFormattedOptions = (
  items = [],
  {
    labelProp = 'name',
    valueProp = 'id',
    formatLabelFn,
    additionalPropsFn
  } = {}
) => {
  const getLabel = item => {
    if (typeof formatLabelFn === 'function') {
      return formatLabelFn(item)
    }

    return get(item, labelProp)
  }

  return items?.map(
    item =>
      ({
        label: getLabel(item),
        value: get(item, valueProp),
        ...(typeof additionalPropsFn === 'function'
          ? additionalPropsFn(item)
          : {})
      } ?? [])
  )
}

/**
 * Returns the field label with an asterisk if it is required.
 * @param {string} label - The field label
 * @param {boolean} required - Indicates if the field is required
 * @param {string} mode - The form mode ("create"|"edit"|"details")
 * @return {string} The field label with an asterisk if required
 */
export const getFieldLabelRequired = (label, required, mode) => {
  if (required && mode !== formModes.details) {
    label += ' *'
  }

  return label
}
