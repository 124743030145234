export const customStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: '52px',
    borderColor: state.selectProps.hasError ? '#ffb2a4' : '#e8e8e9',
    padding: '0.5rem 0',
    cursor: state.selectProps.readOnly ? 'default' : 'pointer',
    ':hover': {
      borderColor: state.selectProps.hasError ? '#ffb2a4' : '#e8e8e9'
    },
    boxShadow: 'none'
  }),

  multiValueRemove: styles => ({
    ...styles,
    ':hover': {
      backgroundColor: 'rgba(25, 100, 163, 0.3)'
    }
  }),

  singleValue: (styles, state) => ({
    ...styles,
    color: state.selectProps.menuIsOpen ? '#ccc' : '#333'
  }),

  option: styles => ({
    ...styles,
    cursor: 'pointer'
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      opacity: data.disabled ? '0.5' : '1',
      textDecoration: data.disabled ? 'line-through' : 'none'
    }
  }
}
