import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getUrl } from '../../apiUrls'
import getNotification from '../../helpers/apiNotifications'
import Loader from '../Common/Loader'
import List from '../Layout'
import { CreateBtn, CtasGroup } from './Ctas'
import { useData, useSortableData } from '../../hooks'
import { getFormattedDateWithTime } from '../../helpers/dates'

const listConfig = t => ({
  gridTemplateColumns: '.5fr 1fr 1fr 2fr 2fr 1.5fr .6fr',
  attributes: [
    { label: t('users.list.headers.id'), propName: 'id' },
    {
      label: t('users.list.headers.firstName'),
      propName: 'firstName',
      sortable: true
    },
    {
      label: t('users.list.headers.lastName'),
      propName: 'lastName',
      sortable: true
    },
    { label: t('users.list.headers.email'), propName: 'email' },
    { label: t('users.list.headers.name'), propName: 'name' },
    {
      label: t('users.list.headers.lastLogin'),
      propName: 'lastLogin',
      formatFn: date => getFormattedDateWithTime(date, 'sv-SE')
    },
    { label: t('users.list.headers.role'), propName: 'role' }
  ]
})
const initialSortConfig = {
  sortBy: 'lastName',
  order: 'asc'
}

const ListContainer = () => {
  const { t } = useTranslation()

  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => setRefresh(!refresh)

  const url = getUrl.users()
  const { loading, items, error } = useData(url, refresh)

  const Info = getNotification(loading, error, items, Loader)

  const formatProperty = (item, sortBy) => item[sortBy].toUpperCase()

  const {
    items: sortedItems,
    handleSort,
    sortConfig
  } = useSortableData(items || [], initialSortConfig, formatProperty)

  return (
    <>
      <div className='mb-2 vf-text--right'>
        <CreateBtn refreshList={toggleRefresh} />
      </div>

      {Info ? (
        Info
      ) : (
        <List
          items={sortedItems}
          listConfig={listConfig(t)}
          ctas={CtasGroup}
          isItemInactive={item => item.accountLocked}
          refreshList={toggleRefresh}
          handleSort={handleSort}
          sortConfig={sortConfig}
        />
      )}
    </>
  )
}

export default ListContainer
