import React from 'react'

import { Select, getFormattedOptions } from '../CommonForm'
import { getUrl } from '../../apiUrls'
import { useData } from '../../hooks'
import { getActiveItems } from '../ContractTypes/helpers'
import { sortByParam } from '../../helpers/lists'

export const ContractTypePicker = ({
  name = 'contractTypeId',
  label,
  options = [],
  readOnly = false,
  disabled = false,
  loading = false
}) => (
  <Select
    name={name}
    label={label}
    options={options}
    readOnly={readOnly}
    disabled={disabled}
    loading={loading}
  />
)

const ContractTypePickerContainer = ({ ...props }) => {
  const contractTypesUrl = getUrl.contractTypes()
  const { items: contractTypes, loading } = useData(contractTypesUrl)
  const contractTypesSorted = sortByParam(contractTypes, 'asc', 'name')
  const contractTypesOptions = getFormattedOptions(contractTypesSorted, {
    additionalPropsFn: item => ({
      active: item.active
    })
  })

  const activeContractTypesOptions = getActiveItems(contractTypesOptions)

  return (
    <ContractTypePicker
      options={activeContractTypesOptions}
      loading={loading}
      {...props}
    />
  )
}

export default ContractTypePickerContainer
