import { EditBtn, BlockBtn } from './'
import { buttonSizes } from '../../Common/Ctas'

const CtasGroup = ({ item, refreshList, size = buttonSizes.small }) => {
  const btnProps = {
    item,
    refreshList,
    size
  }

  return (
    <>
      {item.active && (
        <>
          <EditBtn {...btnProps} />
          <BlockBtn {...btnProps} />
        </>
      )}
    </>
  )
}

export default CtasGroup
