// @ts-check
import React, { useCallback, useState } from 'react'
import { useField } from 'formik'
import { ErrorCode, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import {
  fileTypes,
  formatAcceptedFileTypes,
  getAllAcceptedExtensions
} from './helpers'
import AttachmentsList from './AttachmentsList'
import { Error } from '../../../helpers/apiNotifications'

import './attachments.scss'

/** @typedef {import('../../Issues/types').AttachmentInfoUI} AttachmentInfoUI */

const MAX_FILE_SIZE_IN_BYTES = 10 * 1000000 // 10 MB
const MAX_NUMBER_OF_FILES = 3

const errorMessages = {
  [ErrorCode.FileInvalidType]: 'common.form.validation.fileInvalidType',
  [ErrorCode.FileTooLarge]: 'common.form.validation.fileTooLarge',
  [ErrorCode.TooManyFiles]: 'common.form.validation.tooManyFiles',
  filesDuplicated: 'common.form.validation.filesDuplicated'
}

const hasErrorType = (rejectedFiles, errorType) =>
  rejectedFiles.some(({ errors }) =>
    errors.some(error => error.code === errorType)
  )

const filesListPositions = {
  top: 'top',
  bottom: 'bottom'
}

/**
 * Sometimes we need to calculate current files number with sub category items or in any other custom way.
 * @param {AttachmentInfoUI[]} acceptedFiles
 * @param {File[]} value
 * @returns {number} total number of files
 */
const defaultCalculateCurrentFilesNumber = (acceptedFiles, value) => {
  return acceptedFiles.length + value.length
}

const AttachmentsInput = ({
  value = [],
  name,
  label,
  handleSelect = f => {},
  handleRemove = () => {},
  canUpload = false,
  canDownload = false,
  canDelete = false,
  maxFileSizeBytes = MAX_FILE_SIZE_IN_BYTES,
  maxNumberOfFiles = MAX_NUMBER_OF_FILES,
  acceptedFileTypes = Object.keys(fileTypes),
  filesListPosition = filesListPositions.top,
  calculateCurrentFilesNumber = defaultCalculateCurrentFilesNumber,
  withIcon = false
}) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setMessage('')

      if (
        hasErrorType(rejectedFiles, ErrorCode.TooManyFiles) ||
        calculateCurrentFilesNumber(acceptedFiles, value) > maxNumberOfFiles
      ) {
        setMessage(t(errorMessages[ErrorCode.TooManyFiles]))
        return
      }

      if (hasErrorType(rejectedFiles, ErrorCode.FileInvalidType)) {
        setMessage(t(errorMessages[ErrorCode.FileInvalidType]))
        return
      }

      if (hasErrorType(rejectedFiles, ErrorCode.FileTooLarge)) {
        setMessage(t(errorMessages[ErrorCode.FileTooLarge]))
        return
      }

      acceptedFiles.forEach(file => {
        file.id = file.name
        file.fileName = file.name
        file.sourceSystem = 'BAST_KUND'
      })

      const allFiles = [...value, ...acceptedFiles]

      const uniqueFileNames = new Set(allFiles.map(file => file.fileName))

      const filteredFiles = [...uniqueFileNames].map(fileName =>
        allFiles.find(file => file.fileName === fileName)
      )

      if (allFiles.length !== filteredFiles.length) {
        setMessage(t(errorMessages.filesDuplicated))
      }

      handleSelect(filteredFiles)
    },
    [handleSelect, maxNumberOfFiles, t, value]
  )

  const isDisabled = calculateCurrentFilesNumber([], value) >= maxNumberOfFiles

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: maxFileSizeBytes,
    maxFiles: maxNumberOfFiles,
    accept: formatAcceptedFileTypes(acceptedFileTypes),
    disabled: isDisabled
  })

  return (
    <>
      {label && (
        <label htmlFor={name} className='mt-2'>
          {label}
        </label>
      )}

      {filesListPosition === filesListPositions.top && (
        <AttachmentsList
          items={value}
          handleRemove={handleRemove}
          canDownload={canDownload}
          canDelete={canDelete}
        />
      )}

      {canUpload && (
        <div
          {...getRootProps({
            className: `vf-file-upload--dragndrop ${
              isDisabled ? 'vf-file-upload--dragndrop--disabled' : ''
            }`
          })}>
          {withIcon && (
            <span className='vf-file-upload__icon vf-icon-my-documents'></span>
          )}

          <span className='vf-upload__text'>
            {!isDisabled
              ? isDragActive
                ? t('common.labels.dragAndDropActive')
                : t('common.labels.dragAndDropInactive')
              : t('common.labels.noMoreFiles')}
          </span>

          {!isDisabled && (
            <span className='vf-upload__size'>
              {getAllAcceptedExtensions(acceptedFileTypes)
                .map(item => item.toUpperCase())
                .join(', ')}{' '}
              <br />
              {t('common.labels.maxFilesNumber', { count: maxNumberOfFiles })}
              <br />
              {t('common.labels.maxFileSize', {
                size: maxFileSizeBytes / 1000000 + ' MB'
              })}
            </span>
          )}

          <input {...getInputProps()} />
        </div>
      )}

      {!!message && <Error>{message}</Error>}

      {filesListPosition === filesListPositions.bottom && (
        <AttachmentsList
          items={value}
          handleRemove={handleRemove}
          canDownload={canDownload}
          canDelete={canDelete}
        />
      )}
    </>
  )
}

const AttachmentsInputWithFormik = ({ label, multi = false, ...props }) => {
  const [field, meta, helpers] = useField(props)

  const handleSelect = value => {
    helpers.setValue(value)
  }

  const handleRemove = file => {
    helpers.setValue(field.value.filter(el => el.id !== file.id))
  }

  const hasError = meta.touched && meta.error

  return (
    <>
      <AttachmentsInput
        label={label}
        value={field.value}
        handleSelect={handleSelect}
        handleRemove={handleRemove}
        {...props}
      />

      {hasError ? <Error>{meta.error}</Error> : null}
    </>
  )
}

export default AttachmentsInputWithFormik
