import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { NavigationContext } from '../../Template/NavigationContext'
import { ReactComponent as CloseIcon } from '../../../assets/icons/VF_close_rgb.svg'
import './slidePanel.scss'

export const directions = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom'
}

const SlidePanel = ({
  children,
  size = 25,
  direction = directions.left,
  title = '',
  intro = '',
  ctas: Ctas,
  show,
  handleClose = () => {},
  parentRef,
  zIndex = 9990,
  className: customClassName = '',
  closeButtonTitle = 'Close [Esc]',
  ...props
}) => {
  const { smallerNav } = useContext(NavigationContext)

  let style = {}
  let hiddenStyle = {}
  let common = {}
  const mainNavWidthPx = smallerNav ? 60 : 100
  const headerHeightPx = 57

  useEffect(() => {
    // TODO: or always
    if (show && size === 100) {
      // ab - disable background scroll
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [show, size])

  useEffect(() => {
    const closeOnEsc = e => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', closeOnEsc)

    return () => {
      document.removeEventListener('keydown', closeOnEsc)
    }
  }, [handleClose])

  switch (direction) {
    case directions.left:
      common = {
        top: `${headerHeightPx}px`,
        height: `calc(100% - ${headerHeightPx}px)`,
        width: `${size}%`,
        zIndex
      }
      style = {
        right: 0,
        maxWidth: `calc(100% - ${mainNavWidthPx}px)`,
        borderLeft: '1px solid #ebebeb',
        ...common
      }
      hiddenStyle = {
        right: `-${size}%`,
        ...common
      }
      break
    case directions.bottom:
      common = {
        height: `${size}%`,
        maxHeight: `calc(100% - ${headerHeightPx}px)`,
        width: `calc(100% - ${mainNavWidthPx}px)`,
        left: mainNavWidthPx,
        zIndex
      }
      style = {
        right: 0,
        bottom: 0,
        overflowY: 'auto',
        ...common
      }
      hiddenStyle = {
        bottom: `-${size}%`,
        ...common
      }
      break
    default:
      style = {}
  }

  const styles = show ? style : hiddenStyle

  const getClassName = () => {
    let className = 'slide-panel'

    if (show) {
      className += ' slide-panel--opened'
    }

    if (customClassName) {
      className += ` ${customClassName}`
    }

    return className
  }

  return (
    <div
      className={getClassName()}
      style={styles}
      ref={parentRef}
      data-test='slide-panel'
      {...props}>
      <div className='slide-panel__close-btn'>
        <button
          type='button'
          onClick={handleClose}
          data-test='slide-panel-close'
          title={closeButtonTitle}>
          <CloseIcon width={20} />
        </button>
      </div>

      {!!title && <h4>{title}</h4>}
      {!!intro && <p className='vf-typo-p-secondary'>{intro}</p>}
      {!!Ctas && <div className='slide-panel__ctas'>{Ctas}</div>}
      {children}
    </div>
  )
}

SlidePanel.propTypes = {
  children: PropTypes.any,
  // size in percents
  size: PropTypes.number,
  direction: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  intro: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func
}

export default React.forwardRef((props, ref) => (
  <SlidePanel {...props} parentRef={ref} />
))
