import React from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import ActionButton, { colors } from '../ActionButton'

import './filters.scss'

const FiltersForm = ({
  initialValues,
  handleSubmit,
  fields: FormFields,
  submitButtonHidden = false,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div className='list-filters'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <div className='list-filters__buttons'>
              {!submitButtonHidden && (
                <ActionButton type='submit' color={colors.blue}>
                  {t('buttons.applyFilters')}
                </ActionButton>
              )}

              <ActionButton
                disabled={!formikProps.dirty}
                onClick={() => {
                  formikProps.resetForm()
                  formikProps.handleSubmit()
                }}
                data-test='filters-reset'>
                {t('buttons.clearFilters')}
              </ActionButton>
            </div>

            <div className='list-filters__fields'>
              <FormFields {...formikProps} {...props} />
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default FiltersForm
