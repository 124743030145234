import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PieChart, Pie, Sector, Legend } from 'recharts'
import { sizes } from './helpers'
import { emptyItem } from '../../../Dashboard/Charts/helpers'

/**
 * @typedef {import("../types").ChartPieProps} ChartPieProps
 * @typedef {import("../types").ChartPieSize} ChartPieSize
 */

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props

  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  const maxTextLengthInGlyph = 12
  const text =
    payload.name.length > maxTextLengthInGlyph
      ? `${payload.name.substring(0, maxTextLengthInGlyph)}...`
      : payload.name

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill} width={50}>
        {text}
      </text>
      {/* chart */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        style={{
          cursor: 'pointer',
          filter: `drop-shadow(${cx / 100}px ${
            cy / 100
          }px 5px rgb(0 0 0 / 0.4))`
        }}
      />
      {/* outer line */}
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {/* TO-DO handle this outside the component */}
      {text !== emptyItem.name && (
        <>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill='none'
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill='#333'>{`${value}`}</text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill='#999'>
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </>
      )}
    </g>
  )
}

/**
 * @param {*} props
 * @param {ChartPieProps} props.data Data to be shown in the chart
 * @param {ChartPieSize} props.size Size of the chart
 * @param {string} props.title Title of the chart
 * @returns {JSX.Element} Donut / Pie Chart with Title
 */
const Chart = ({ data, size = sizes.small, title = '' }) => {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0)

  const onPieEnter = (_, index, e) => {
    setActiveIndex(index)
  }

  const {
    width,
    height,
    innerRadius,
    outerRadius,
    fontSize,
    legendBottomPosition
  } = size

  return (
    <>
      {!!title && <h4>{title}</h4>}
      <PieChart
        width={width}
        height={height}
        style={{ fontSize, margin: 'auto' }}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          active
          data={data}
          cx='50%'
          cy='50%'
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          isAnimationActive
          dataKey='value'
          onMouseEnter={onPieEnter}
          onClick={({ listUrl }) => navigate(listUrl)}
        />
        <Legend
          verticalAlign='bottom'
          height={60}
          wrapperStyle={{
            bottom: legendBottomPosition,
            cursor: 'pointer',
            overflowY: 'auto'
          }}
          activeIndex={activeIndex}
          onClick={onPieEnter}
        />
      </PieChart>
    </>
  )
}

export default Chart
