import { useTranslation } from 'react-i18next'

import { ReactComponent as EditIcon } from './icons/VF_edit_rgb.svg'
import ButtonTemplate from './ButtonTemplate'

const EditBtn = ({ ...props }) => {
  const { t } = useTranslation()

  return <ButtonTemplate text={t('buttons.edit')} icon={EditIcon} {...props} />
}

export default EditBtn
